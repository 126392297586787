import React, { useState } from 'react';
import { useMount } from 'react-use';
import { auth } from '../../../utils/Firebase';
import Export from './shared-components/Export';
import Access from './shared-components/Access';

function Administrator() {
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);

  useMount(() => {
    auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        setUser(userAuth);
      }

      setLoading(false);
    });
  });

  if (loading) {
    return (
      <div className="flex flex-col items-center c-container font-sans font-normal p-4">
        <div className="text-sm text-gray-500">Verificando Credenciales</div>
      </div>
    );
  }

  if (user) {
    return (
      <div className="flex flex-1 flex-col w-full c-container font-sans font-normal p-4 bg-white space-y-4">
        <div className="text-sm text-gray-500 mb-4 border-b pb-4">
          <div className="text-gray-900 font-bold text-2xl">Administrador</div>
          <div>
            Sesión iniciada como:{' '}
            <span className="text-blue-400">{user.email}</span>
          </div>
          <button
            type="button"
            className="border py-2 px-3 mt-4 rounded-full uppercase text-xs font-semibold text-gray-900 hover:bg-black hover:text-white transition duration-500 ease-in-out outline-none focus:outline-none"
            onClick={() => {
              auth
                .signOut()
                .then(() => {
                  setUser(null);
                })
                .catch((error) => {
                  console.log(error);
                });
            }}
          >
            logout
          </button>
        </div>
        <div className="text-gray-900 font-semibold text-sm">Herramientas</div>
        <div className="grid grid-cols-2 gap-4">
          <Export />
          <Access />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center mt-5">
      <div className="flex flex-col bg-white shadow-md px-4 sm:px-6 md:px-8 lg:px-10 py-8 rounded-md w-full max-w-md">
        <div className="font-medium self-center text-xl sm:text-2xl uppercase text-gray-800">
          Login To Your Account
        </div>
        <div className="relative mt-10 h-px bg-gray-300">
          <div className="absolute left-0 top-0 flex justify-center w-full -mt-2">
            <span className="bg-white px-4 text-xs text-gray-500 uppercase">
              Login With Email
            </span>
          </div>
        </div>
        <div className="mt-10">
          <form noValidate>
            <div className="flex flex-col mb-6">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label
                htmlFor="email"
                className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
              >
                E-Mail Address:
              </label>
              <div className="relative">
                <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                  <svg
                    className="h-6 w-6"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                  </svg>
                </div>

                <input
                  id="email"
                  type="email"
                  name="email"
                  className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                  placeholder="E-Mail Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className="flex flex-col mb-6">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label
                htmlFor="password"
                className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
              >
                Password:
              </label>
              <div className="relative">
                <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                  <span>
                    <svg
                      className="h-6 w-6"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                    </svg>
                  </span>
                </div>

                <input
                  id="password"
                  type="password"
                  name="password"
                  className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
            {error && (
              <div className="flex items-center justify-center text-center mb-6 -mt-4">
                <div className="text-xs sm:text-sm text-red-500">{error}</div>
              </div>
            )}

            <div className="flex w-full">
              <button
                type="button"
                className="flex items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-blue-600 hover:bg-blue-700 rounded py-2 w-full transition duration-150 ease-in"
                onClick={(e) => {
                  e.preventDefault();
                  auth
                    .signInWithEmailAndPassword(email, password)
                    .then((response) => {
                      setEmail('');
                      setPassword('');
                      setUser(response.user);
                    })
                    .catch((error) => {
                      setError(error.message);
                    });
                }}
              >
                <span className="mr-2 uppercase">Login</span>
                <span>
                  <svg
                    className="h-6 w-6"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Administrator;
