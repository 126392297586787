import { selector } from 'recoil';
import _ from 'lodash';
import is from 'is_js';
import { pageState, pagesState, formState } from './Atoms';

export const progressSelector = selector({
  key: 'progress',
  get: ({ get }) => {
    const page = get(pageState) + 1;
    const pages = get(pagesState);
    return Math.ceil((page / pages.length) * 100);
  },
});

export const validationSelector = selector({
  key: 'validation',
  get: ({ get }) => {
    const page = get(pageState);
    const pages = get(pagesState);
    const form = get(formState);
    let object = {};

    _.map(pages[page].questions, (q) => {
      if (q.type === 'radio') {
        if (is.empty(form[q.errors])) {
          object = {
            ...object,
            [q.errors]: 'Favor de seleccionar una opción en esta pregunta.',
          };
        }
      } else if (q.type === 'checkbox') {
        if (is.all.falsy(_.map(q.errors, (e) => form[e]))) {
          object = {
            ...object,
            [q.id]:
              'Favor de seleccionar al menos una opción en esta pregunta.',
          };
        }
      } else if (q.type === 'table') {
        if (is.any.falsy(_.map(q.errors, (e) => form[e]))) {
          object = {
            ...object,
            [q.id]:
              'Favor de seleccionar una opción en cada pregunta de la tabla.',
          };
        }
      }
    });

    return object;
  },
});
