import React from 'react';
import { Transition } from '@headlessui/react';

function Input({
  name,
  label,
  placeholder,
  value,
  onChange,
  show,
  alwaysShow,
}) {
  return (
    <div className="col-span-3">
      <div className="w-full">
        <div className="mb-3 pt-0">
          <Transition
            show={alwaysShow ? true : show}
            enter="transition-all ease-out duration-500"
            enterFrom="opacity-0 transform translate-y-1 scale-0"
            enterTo="opacity-100 transform translate-y-0 scale-100"
            leave="transition-all ease-in duration-500"
            leaveFrom="opacity-100 transform translate-y-0 scale-100"
            leaveTo="opacity-0 transform translate-y-1 scale-0"
          >
            <div className="font-medium mb-3">{label}</div>
            <input
              type="text"
              name={name}
              placeholder={placeholder}
              value={value}
              onChange={onChange}
              className="p-3 placeholder-gray-400 text-gray-700 bg-white rounded border outline-none focus:outline-none w-full"
            />
          </Transition>
        </div>
      </div>
    </div>
  );
}

export default Input;
