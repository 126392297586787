import React from 'react';
import axios from 'axios';
import { useRecoilState } from 'recoil';
import { accessAtom } from '../../../../atoms/Atoms';

function Access() {
  const [access, setAccess] = useRecoilState(accessAtom);

  const submit = () => {
    axios
      .post('/api/set-access', {
        access: !access,
      })
      .then(() => {
        setAccess(!access);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <span className="flex rounded-md shadow-sm">
      <button
        type="button"
        className="flex items-center justify-center w-full px-4 py-2 uppercase font-light text-white bg-black hover:bg-gray-800 transition ease-in-out duration-500 cursor-pointer outline-none focus:outline-none"
        onClick={() => submit()}
      >
        {access ? 'Cerrar Acceso' : 'Abrir Accesso'}
      </button>
    </span>
  );
}

export default Access;
