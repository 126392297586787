import React from 'react';

function Checkbox(props) {
  const { value, name, checked, onChange, label } = props;
  return (
    <label
      htmlFor={value}
      className="col-span-3 sm:col-span-1 flex items-center relative font-sans cursor-pointer"
    >
      <input
        id={value}
        name={name}
        type="checkbox"
        value={value}
        checked={checked}
        className=" appearance-none flex-shrink-0 transition-colors duration-500 ease-in-out w-5 h-5 mr-2 rounded bg-gray-300 checked:bg-yellow-400 cursor-pointer text-gray-900 outline-none focus:outline-none"
        onChange={onChange}
      />
      {checked && (
        <span className="absolute transition-all duration-500 ease-in-out">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="h-5 w-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M5 13l4 4L19 7"
            />
          </svg>
        </span>
      )}
      <span className="font-normal text-gray-800">{label}</span>
    </label>
  );
}

export default Checkbox;
