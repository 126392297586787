import React from 'react';

function TableHeader({ header }) {
  return (
    <thead className="rounded-t-lg">
      <tr>
        {header.map((e) => (
          <th
            key={e.id}
            className="rounded-t-lg px-5 py-3 border-b border-gray-200 bg-gray-100 text-center text-xs font-bold text-gray-900 tracking-wider"
          >
            {e.title}
          </th>
        ))}
      </tr>
    </thead>
  );
}

export default TableHeader;
