import React from 'react';

function Radio(props) {
  const { value, name, checked, onChange, label, onBlur } = props;
  return (
    <label
      htmlFor={value}
      className="col-span-3 sm:col-span-1 flex items-center font-sans cursor-pointer"
    >
      <div className="flex items-center justify-center">
        <input
          id={value}
          name={name}
          type="radio"
          value={value}
          checked={checked}
          className="appearance-none transition-colors flex-shrink-0 duration-500 ease-in-out w-5 h-5 rounded-full bg-gray-300 checked:bg-yellow-400 cursor-pointer text-gray-900 outline-none focus:outline-none"
          onChange={onChange}
          onBlur={onBlur}
        />
        {checked && (
          <span className="absolute transition-all duration-500 ease-in-out h-2 w-2 bg-gray-900 rounded-full" />
        )}
      </div>
      {label && (
        <span className="font-normal text-sm ml-2 text-gray-800">{label}</span>
      )}
    </label>
  );
}

export default Radio;
