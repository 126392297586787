import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useMount } from 'react-use';
import axios from 'axios';
import { useSetRecoilState } from 'recoil';
import Header from './shared-components/Header';
import Form from './shared-components/form/Form';
import Toast from './shared-components/Toast';
import Home from './pages/Home';
import Administrator from './pages/administrator/Administrator';
import { accessAtom } from '../atoms/Atoms';

function App() {
  const setAccess = useSetRecoilState(accessAtom);

  useMount(() => {
    axios
      .get('/api/get-access')
      .then((response) => {
        setAccess(response.data[0].access);
      })
      .catch((error) => console.log(error));
  });

  return (
    <div className="bg-gray-200 min-h-screen flex flex-col">
      <Toast />
      <Router>
        <Header />
        <Switch>
          <Route exact path="/:slug?">
            <Home />
          </Route>
          <Route path="/administrator/dashboard">
            <Administrator />
          </Route>
          <Route exact path="/:slug/form/">
            <Form />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
