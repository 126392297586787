import React from 'react';
import { Link } from 'react-router-dom';

import logo from '../../assets/images/logo.png';

function Header() {
  return (
    <div className="flex flex-row items-center justify-between bg-yellow-400 h-24 px-4 text-gray-800 font-sans font-semibold">
      <img alt="logo" src={logo} className="h-10" />
      <Link
        to="/administrator/dashboard"
        className="uppercase text-xs hover:bg-yellow-500 text-yellow-700 font-medium py-2 px-4 rounded-full transition duration-500 ease-in-out "
      >
        Administrador
      </Link>
    </div>
  );
}

export default Header;
