import React, { useState } from 'react';
import axios from 'axios';

function Export() {
  const [loading, setLoading] = useState(false);

  const submit = () => {
    setLoading(true);
    axios
      .get('/api/export')
      .then((response) => {
        const items = response.data;
        const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
        const header = Object.keys(items[0]);
        const csv = [
          header.join(','), // header row first
          ...items.map((row) =>
            header
              .map((fieldName) => {
                let str = row[fieldName];
                if (typeof str === 'string') {
                  str = str.replace(/"/g, "''");
                }

                return JSON.stringify(str, replacer);
              })
              .join(',')
          ),
        ].join('\r\n');

        const downloadLink = document.createElement('a');
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = 'datos.csv';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <span className="flex rounded-md shadow-sm">
      <button
        type="button"
        className="flex items-center justify-center w-full px-4 py-2 uppercase font-light text-white bg-black hover:bg-gray-800 transition ease-in-out duration-500 cursor-pointer outline-none focus:outline-none"
        onClick={() => submit()}
      >
        {loading ? (
          <svg
            className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        ) : (
          'Descargar Datos'
        )}
      </button>
    </span>
  );
}

export default Export;
