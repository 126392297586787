import React from 'react';
import { useRecoilState } from 'recoil';
import { Transition } from '@headlessui/react';
import { pageState, toastState } from '../../atoms/Atoms';

function Toast() {
  const [page, setPage] = useRecoilState(pageState);
  const [toast, setToast] = useRecoilState(toastState);

  return (
    <Transition
      show={toast}
      enter="transition ease-in-out duration-100 transform"
      enterFrom="translate-x-full"
      enterTo="translate-x-0"
      leave="transition ease-in-out duration-700 transform"
      leaveFrom="translate-x-0"
      leaveTo="translate-x-full"
    >
      <div className="z-50 fixed top-0 right-0 m-4 w-5/6 md:w-full max-w-sm">
        <div className="divide-x divide-gray-700 flex flex-row items-start w-full bg-gray-900 h-24 rounded shadow-lg text-white text-xs">
          <div className="flex flex-col flex-grow p-2 h-full">
            <div className="font-bold text-red-500 text-base">Atención</div>
            <div className="text-base">
              Faltan preguntas por contestar en esta página.
            </div>
          </div>
          <div className="flex flex-col divide-y divide-gray-700 h-full">
            <button
              type="button"
              className="font-medium text-blue-500 hover:text-blue-700 transition duration-500 ease-in-out flex flex-1 items-center justify-center py-2 px-4 outline-none focus:outline-none"
              onClick={() => {
                setToast(false);
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
            >
              Continuar en esta página
            </button>
            <button
              type="button"
              className="font-medium text-gray-600 hover:text-gray-800 transition duration-500 ease-in-out flex flex-1 items-center justify-center py-2 px-4 focus:outline-none"
              onClick={() => {
                setToast(false);
                setPage(page + 1);
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
            >
              Ir a la próxima
            </button>
          </div>
        </div>
      </div>
    </Transition>
  );
}

export default Toast;
