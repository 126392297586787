import React from 'react';

function TableRadio(props) {
  const { value, name, checked, onChange } = props;
  return (
    <label
      htmlFor={value}
      className="flex justify-center items-center cursor-pointer"
    >
      <div className="flex items-center justify-center">
        <input
          id={value}
          name={name}
          type="radio"
          value={value}
          checked={checked}
          className="appearance-none transition-colors duration-500 ease-in-out w-5 h-5 rounded-full bg-gray-300 checked:bg-yellow-400 cursor-pointer text-gray-900 outline-none focus:outline-none"
          onChange={onChange}
        />
        {checked && (
          <span className="absolute transition-all duration-500 ease-in-out h-2 w-2 bg-gray-900 rounded-full" />
        )}
      </div>
    </label>
  );
}

export default TableRadio;
