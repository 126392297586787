import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { Transition } from '@headlessui/react';
import { confirmState, pageState, timeState } from '../../atoms/Atoms';

function Modal() {
  const { slug } = useParams();
  const history = useHistory();
  const [state, setState] = useRecoilState(confirmState);
  const setPage = useSetRecoilState(pageState);
  const setTime = useSetRecoilState(timeState);

  return (
    <Transition
      show={state}
      enter="transition-opacity ease-linear duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity ease-linear duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        tabIndex={-1}
        role="button"
        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
        onKeyDown={() => {
          setState(false);
          setPage(0);
          setTime(0);
          history.push(`/${slug}`);
        }}
        onClick={() => {
          setState(false);
          setPage(0);
          setTime(0);
          history.push(`/${slug}`);
        }}
      >
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/* content */}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/* header */}
            <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
              <h3 className="text-3xl font-semibold">Felicidades</h3>
              <button
                type="button"
                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => {
                  setState(false);
                  setPage(0);
                  setTime(0);
                  history.push(`/${slug}`);
                }}
              >
                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            {/* body */}
            <div className="relative p-6 flex-auto">
              <p className="my-4 text-gray-600 text-lg leading-relaxed">
                Has completado la entrevista. Tus opiniones son muy importantes
                para nosotros. Gracias por tu colaboración.
              </p>
            </div>
            {/* footer */}
            <div className="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b">
              <button
                className="bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                type="button"
                style={{ transition: 'all .15s ease' }}
                onClick={() => {
                  setState(false);
                  setPage(0);
                  setTime(0);
                  history.push(`/${slug || 'no-class'}`);
                }}
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black" />
    </Transition>
  );
}

export default Modal;
