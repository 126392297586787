import firebase from 'firebase/app';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyBS2V32ololCNv2fsjPZ6L8YLuoAbkyQ_w',
  authDomain: 'consulta-juvenil.firebaseapp.com',
  projectId: 'consulta-juvenil',
  storageBucket: 'consulta-juvenil.appspot.com',
  messagingSenderId: '149352680125',
  appId: '1:149352680125:web:643987d282928303a0c5d4',
  measurementId: 'G-2HDD0FHNZ8',
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
