import React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import is from 'is_js';
import axios from 'axios';
import {
  confirmState,
  loadingState,
  errorsState,
  pageState,
  pagesState,
  toastState,
  formState,
} from '../../atoms/Atoms';
import { validationSelector } from '../../atoms/Selectors';

function Pagination() {
  const [loading, setLoading] = useRecoilState(loadingState);
  const [toast, setToast] = useRecoilState(toastState);
  const setConfirm = useSetRecoilState(confirmState);
  const setErrors = useSetRecoilState(errorsState);
  const [page, setPage] = useRecoilState(pageState);
  const pages = useRecoilValue(pagesState);
  const validation = useRecoilValue(validationSelector);
  const values = useRecoilValue(formState);
  const setForm = useSetRecoilState(formState);

  const handleNext = () => {
    if (is.empty(validation)) {
      setPage(page + 1);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else if (is.not.empty(validation) && toast) {
      setToast(false);
      setPage(page + 1);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      setErrors(validation);
      setToast(true);
    }
  };

  const submitForm = () => {
    setLoading(true);

    console.log(values);

    axios
      .put('/api/insert', values)
      .then(() => {
        setConfirm(true);
        setLoading(false);
        setForm({
          ip: '',
          slug: '',
          group: '',
          date: new Date(),
          section_1_1: '',
          section_1_2: '',
          section_1_3: '',
          section_1_4_1: false,
          section_1_4_2: false,
          section_1_4_3: false,
          section_1_4_4: false,
          section_1_4_5: false,
          section_1_4_6: false,
          section_1_4_7: false,
          section_1_4_8: false,
          section_1_4_9: false,
          section_1_5: '',
          section_2_1: '',
          section_2_2: '',
          section_2_3: '',
          section_2_4: '',
          section_2_5: '',
          section_2_6_1: '',
          section_2_6_2: '',
          section_2_6_3: '',
          section_2_7: '',
          section_2_8: '',
          section_2_9_1: false,
          section_2_9_2: false,
          section_2_9_3: false,
          section_2_9_4: false,
          section_2_9_5: false,
          section_2_9_6: false,
          section_2_9_7: false,
          section_2_9_8: false,
          section_2_10: '',
          section_2_11_1: false,
          section_2_11_2: false,
          section_2_11_3: false,
          section_2_11_4: false,
          section_2_12: '',
          section_3_1: '',
          section_3_2: '',
          section_3_3_1: '',
          section_3_3_2: '',
          section_3_3_3: '',
          section_3_3_4: '',
          section_3_4_1: '',
          section_3_4_2: '',
          section_3_4_3: '',
          section_3_4_4: '',
          section_3_4_5: '',
          section_3_5_1: '',
          section_3_5_2: '',
          section_3_5_3: '',
          section_3_5_4: '',
          section_3_5_5: '',
          section_3_6_1: '',
          section_3_6_2: '',
          section_3_6_3: '',
          section_3_7_1: '',
          section_3_7_2: '',
          section_3_7_3: '',
          section_3_7_4: '',
          section_3_8: '',
          section_4_1_1: '',
          section_4_1_2: '',
          section_4_1_3: '',
          section_4_1_4: '',
          section_4_1_5: '',
          section_4_1_6: '',
          section_4_1_7: '',
          section_4_1_8: '',
          section_4_1_9: '',
          section_4_1_10: '',
          section_4_1_11: '',
          section_4_1_12: '',
          section_4_1_13: '',
          section_4_2_1: '',
          section_4_2_2: '',
          section_4_2_3: '',
          section_4_2_4: '',
          section_4_2_5: '',
          section_4_2_6: '',
          section_4_2_7: '',
          section_4_2_8: '',
          section_4_2_9: '',
          section_4_2_10: '',
          section_4_2_11: '',
          section_4_2_12: '',
          section_4_2_13: '',
          section_4_3_1: '',
          section_4_3_2: '',
          section_4_3_3: '',
          section_4_3_4: '',
          section_4_3_5: '',
          section_4_3_6: '',
          section_4_3_7: '',
          section_4_3_8: '',
          section_4_3_9: '',
          section_4_3_10: '',
          section_4_3_11: '',
          section_4_3_12: '',
          section_4_3_13: '',
          section_4_4_1: '',
          section_4_4_2: '',
          section_4_4_3: '',
          section_4_4_4: '',
          section_4_4_5: '',
          section_4_4_6: '',
          section_4_4_7: '',
          section_4_4_8: '',
          section_4_4_9: '',
          section_4_4_10: '',
          section_4_4_11: '',
          section_4_5_1: false,
          section_4_5_2: false,
          section_4_5_3: false,
          section_4_5_4: false,
          section_4_5_5: false,
          section_4_5_6: false,
          section_4_6: '',
          section_4_7: '',
          section_4_8: '',
          section_4_9: '',
          section_4_10: '',
          section_4_11: '',
          section_4_12: '',
          section_4_13: '',
          section_4_14: '',
          section_4_15_1: false,
          section_4_15_2: false,
          section_4_15_3: false,
          section_4_15_4: false,
          section_4_15_5: false,
          section_4_15_6: false,
          section_4_15_7: false,
          section_4_15_8: '',
          section_4_16_1: false,
          section_4_16_2: false,
          section_4_16_3: false,
          section_4_16_4: false,
          section_4_16_5: false,
          section_4_16_6: false,
          section_4_16_7: false,
          section_4_16_8: false,
          section_4_16_9: false,
          section_4_16_10: false,
          section_4_16_11: false,
          section_4_16_12: false,
          section_4_16_13: false,
          section_4_16_14: false,
          section_4_16_15: false,
          section_4_16_16: '',
          section_4_17_1: false,
          section_4_17_2: false,
          section_4_17_3: false,
          section_4_17_4: false,
          section_4_17_5: false,
          section_4_17_6: false,
          section_4_17_7: false,
          section_4_18_1: false,
          section_4_18_2: false,
          section_4_18_3: false,
          section_4_18_4: false,
          section_4_18_5: false,
          section_4_18_6: false,
          section_4_18_7: false,
          section_4_18_8: false,
          section_4_18_9: false,
          section_4_18_10: false,
          section_4_18_11: '',
          section_4_19: '',
          section_5_1_1: '',
          section_5_1_2: '',
          section_5_1_3: '',
          section_5_1_4: '',
          section_5_1_5: '',
          section_5_1_6: '',
          section_5_1_7: '',
          section_5_2_1: '',
          section_5_2_2: '',
          section_5_2_3: '',
          section_5_2_4: '',
          section_5_2_5: '',
          section_5_2_6: '',
          section_5_2_7: '',
          section_5_2_8: '',
          section_5_2_9: '',
          section_5_2_10: '',
          section_5_2_11: '',
          section_5_2_12: '',
          section_5_2_13: '',
          section_5_3_1: '',
          section_5_3_2: '',
          section_5_3_3: '',
          section_5_3_4: '',
          section_5_3_5: '',
          section_5_4_1: '',
          section_5_4_2: '',
          section_5_4_3: '',
          section_5_4_4: '',
          section_5_4_5: '',
          section_5_5_1: '',
          section_5_5_2: '',
          section_5_5_3: '',
          section_5_5_4: '',
          section_5_5_5: '',
          section_5_5_6: '',
          section_5_6_1: '',
          section_5_6_2: '',
          section_5_6_3: '',
          section_5_6_4: '',
          section_5_6_5: '',
          section_5_6_6: '',
          section_5_6_7: '',
          section_5_6_8: '',
          section_5_6_9: '',
          section_5_6_10: '',
          section_6_1_1: '',
          section_6_1_2: '',
          section_6_1_3: '',
          section_6_1_4: '',
          section_7_1_1: '',
          section_7_1_2: '',
          section_7_1_3: '',
          section_7_1_4: '',
          section_7_2_1: '',
          section_7_2_2: '',
          section_7_2_3: '',
          section_7_2_4: '',
          section_7_2_5: '',
          section_7_2_6: '',
          section_7_2_7: '',
          section_7_3_1: '',
          section_7_3_2: '',
          section_7_3_3: '',
          section_7_3_4: '',
          section_8_1_1: '',
          section_8_1_2: '',
          section_8_1_3: '',
          section_8_1_4: '',
          section_8_1_5: '',
          section_8_1_6: '',
          section_8_1_7: '',
          section_8_1_8: '',
          section_8_1_9: '',
          section_8_2_1: '',
          section_8_2_2: '',
          section_8_2_3: '',
          section_8_2_4: '',
          section_8_2_5: '',
          section_8_2_6: '',
          section_8_2_7: '',
          section_8_2_8: '',
          section_8_2_9: '',
          section_9_1_1: '',
          section_9_1_2: '',
          section_9_1_3: '',
          section_10_1: '',
          section_10_2: '',
          section_10_3_1: false,
          section_10_3_2: false,
          section_10_3_3: false,
          section_10_3_4: false,
          section_10_3_5: false,
          section_10_3_6: false,
          section_10_3_7: false,
          section_10_3_8: false,
          section_10_3_9: false,
          section_10_3_10: false,
          section_10_3_11: false,
          section_10_3_12: false,
          section_10_4: '',
          section_10_5_1: false,
          section_10_5_2: false,
          section_10_5_3: false,
          section_10_5_4: false,
          section_10_5_5: false,
          section_10_6_1: false,
          section_10_6_2: false,
          section_10_6_3: false,
          section_10_6_4: false,
          section_10_6_5: false,
          section_10_6_6: false,
          section_10_7: '',
          section_10_8: '',
          section_11_1: '',
          section_11_2: '',
          section_11_3: '',
          section_11_4: '',
          section_11_5: '',
          section_11_6: '',
          section_11_7: '',
          section_11_8_1: '',
          section_11_8_2: '',
          section_11_8_3: '',
          section_11_8_4: '',
          section_11_8_5: '',
          section_11_8_6: '',
          section_11_8_7: '',
          section_11_9_1: '',
          section_11_9_2: '',
          section_11_9_3: '',
          section_11_9_4: '',
          section_11_9_5: '',
          section_11_9_6: '',
          section_11_9_7: '',
          section_12_1_1: false,
          section_12_1_2: false,
          section_12_1_3: false,
          section_12_1_4: false,
          section_12_1_5: false,
          section_12_1_6: false,
          section_12_1_7: false,
          section_12_1_8: false,
          section_12_1_9: false,
          section_12_1_10: false,
          section_12_1_11: false,
          section_12_1_12: false,
          section_12_1_13: false,
          section_12_1_14: false,
          section_12_1_15: false,
          section_12_1_16: false,
          section_12_1_17: false,
          section_12_1_18: false,
          section_13_1_1: '',
          section_13_1_2: '',
          section_13_1_3: '',
          section_13_1_4: '',
          section_13_1_5: '',
          section_13_1_6: '',
          section_13_1_7: '',
          section_13_2_1: '',
          section_13_2_2: '',
          section_13_2_3: '',
          section_13_2_4: '',
          section_13_2_5: '',
          section_13_2_6: '',
          section_13_2_7: '',
          section_13_3_1: '',
          section_13_3_2: '',
          section_13_3_3: '',
          section_13_3_4: '',
          section_13_3_5: '',
          section_13_3_6: '',
          section_13_3_7: '',
          section_13_4_1: '',
          section_13_4_2: '',
          section_13_4_3: '',
          section_13_4_4: '',
          section_13_4_5: '',
          section_13_4_6: '',
          section_13_4_7: '',
          section_14_1: '',
          section_14_2_1: false,
          section_14_2_2: false,
          section_14_2_3: false,
          section_14_2_4: false,
          section_14_2_5: false,
          section_14_3_1: false,
          section_14_3_2: false,
          section_14_3_3: false,
          section_14_4_1: false,
          section_14_4_2: false,
          section_14_4_3: false,
          section_14_4_4: false,
          section_14_4_5: false,
          section_14_4_6: false,
          section_14_4_7: false,
          section_14_4_8: false,
          section_14_4_9: false,
          section_14_4_10: false,
          section_14_4_11: false,
          section_14_5_1: false,
          section_14_5_2: false,
          section_14_5_3: false,
          section_14_5_4: false,
          section_14_5_5: false,
          section_14_5_6: false,
          section_14_5_7: false,
          section_14_5_8: false,
          section_14_5_9: false,
          section_14_5_10: false,
          section_14_5_11: false,
          section_14_5_12: false,
          section_14_5_13: false,
          section_14_5_14: false,
          section_14_5_15: false,
          section_15_1: '',
          section_15_2: '',
          section_15_3_1: false,
          section_15_3_2: false,
          section_15_3_3: false,
          section_15_3_4: false,
          section_15_3_5: false,
          section_15_3_6: false,
          section_15_3_7: false,
          section_15_3_8: false,
          section_15_3_9: false,
        });
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <div className="flex flex-row divide-x divide-gray-900 bg-gray-800 rounded-b-xl text-gray-100 h-20">
      <button
        type="button"
        className="flex flex-1 items-center justify-center text-sm font-medium rounded-bl-xl w-full py-4 duration-500 hover:text-gray-300 hover:bg-gray-900 transition-all ease-in-out focus:outline-none outline-none disabled:opacity-50 disabled:cursor-not-allowed"
        onClick={() => setPage(page - 1)}
        disabled={page === 0}
      >
        Anterior
      </button>
      {page < pages.length - 1 && (
        <button
          type="button"
          className="flex flex-1 items-center justify-center text-sm font-medium rounded-br-xl w-full py-4 duration-500 hover:text-gray-300 hover:bg-gray-900 transition-all ease-in-out outline-none focus:outline-none"
          onClick={() => handleNext()}
        >
          Siguiente
        </button>
      )}
      {page === pages.length - 1 && (
        <button
          type="button"
          className="flex flex-1 text-pink-400 hover:text-pink-500 text-center flex flex-row justify-center content-center font-medium items-center rounded-br-xl w-full py-4 duration-500 hover:text-gray-300 hover:bg-gray-900 transition-all ease-in-out outline-none focus:outline-none"
          onClick={() => submitForm()}
          disabled={loading}
        >
          {loading ? (
            <div>
              <svg
                className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                />
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
              </svg>
            </div>
          ) : (
            <span>Finalizar</span>
          )}
        </button>
      )}
    </div>
  );
}

export default Pagination;
