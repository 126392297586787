import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useMount } from 'react-use';
import logo from '../../assets/images/logo.png';
import Checkbox from '../shared-components/form/Steps/shared-components/Checkbox';
import Radio from '../shared-components/form/Steps/shared-components/Radio';
import Input from '../shared-components/form/Steps/shared-components/Input';
import { accessAtom, formState } from '../../atoms/Atoms';

function Home() {
  const { slug } = useParams();
  const [radio, setRadio] = useState('');
  const [checkbox1, setCheckbox1] = useState(false);
  const [checkbox2, setCheckbox2] = useState(false);
  const [checkbox3, setCheckbox3] = useState(false);
  const [form, setForm] = useRecoilState(formState);
  const access = useRecoilValue(accessAtom);

  useMount(() => {
    if (slug) {
      setForm({
        ...form,
        slug,
      });
    }
  });

  if (!access) {
    return (
      <div className="flex flex-col flex-1 items-center justify-center font-sans text-sm font-normal py-8 bg-gray-900 text-white">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="h-8 w-8 mb-2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
          />
        </svg>
        <span>¡Lo sentimos, no hay sesión abierta en estos momentos!</span>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center c-container font-sans font-normal p-4 bg-white">
      <div className="mb-8">
        <img src={logo} alt="logo" className="h-32" />
      </div>
      <div className="font-semibold text-2xl text-center mb-10">
        GRACIAS por aceptar participar de Consulta Juvenil.
      </div>
      <div>
        <p className="mb-3">
          Las siguientes páginas contienen preguntas diseñadas para conocer tu
          opinión sobre una serie de cosas que son de interés para ti. Es
          importante que respondas a cada pregunta de la manera más precisa y
          honesta.
        </p>
        <p className="mb-3">
          Esta encuesta es completamente voluntaria y puedes dejar sin contestar
          cualquier pregunta que no desees responder.
        </p>
        <p className="mb-3">
          Todas tus respuestas son confidenciales y nunca serán vistas por nadie
          en tu escuela. Tus respuestas serán guardadas automáticamente en una
          base de datos.
        </p>
        <p className="mb-3 font-bold">Gracias por tu participación.</p>
        <div className="flex justify-center items-center text-center py-6 font-bold bg-gray-200 mb-4">
          AL ENTRAR AL CUESTIONARIO, ESTÁS ACEPTANDO PARTICIPAR DEL ESTUDIO. SI
          NO DESEAS CONTESTAR EL CUESTIONARIO, NO CONTINUES. INDÍCASELO A TU
          MAESTRO.
        </div>
      </div>
      <div className="w-full text-xl font-semibold mb-4">Instrucciones</div>
      <div className="w-full mb-4">
        <ul className="list-disc ml-6">
          <li>
            Esto no es un examen, no hay respuestas correctas o incorrectas.
            Sigue las instrucciones provistas.
          </li>
          <li>
            En cada pregunta, marca la respuesta que más se acerca a tu manera
            de pensar. Si no encuentras una respuesta selecciona la más que se
            acerque a tu forma de pensar.
          </li>
          <li>
            Todas las preguntas deben responderse marcando uno de los espacios
            de respuesta.
          </li>
          <li>Sigue las instrucciones provistas.</li>
          <li>
            Nos gustaría que trabajaras rápidamente, para que puedas terminar en
            el tiempo provisto.
          </li>
        </ul>
      </div>
      <div className="w-full bg-gray-200 p-4 rounded mb-4">
        <Input
          label="Escribe el nombre de tu grupo"
          placeholder="Escribir grupo...."
          value={form.group}
          onChange={(e) => {
            setForm({
              ...form,
              group: e.target.value,
            });
          }}
          alwaysShow
        />
      </div>
      <div className="w-full text-xl font-semibold mb-4">Area de Práctica</div>
      <div className="w-full">
        <p className="mb-2">
          1. Esta es una pregunta de selección sencilla. Marca SI en esta
          pregunta
        </p>
        <div className="flex mb-4">
          <div className="mr-4">
            <Radio
              label="Sí"
              name="yes_or_no"
              value="Sí"
              checked={radio === 'Sí'}
              onChange={(e) => {
                setRadio(e.target.value);
              }}
            />
          </div>
          <Radio
            label="No"
            name="yes_or_no"
            value="No"
            checked={radio === 'No'}
            onChange={(e) => {
              setRadio(e.target.value);
            }}
          />
        </div>
        <div className="mb-10">
          <p className="mb-2">
            2. Esta pregunta es de selección múltiple. Marca todas las
            respuestas.
          </p>
          <div className="flex">
            <Checkbox
              label="1ra respuesta"
              name="checkbox1"
              checked={checkbox1}
              onChange={() => setCheckbox1(!checkbox1)}
            />
            <div className="mr-4" />
            <Checkbox
              label="2da respuesta"
              name="checkbox2"
              checked={checkbox2}
              onChange={() => setCheckbox2(!checkbox2)}
            />
            <div className="mr-4" />
            <Checkbox
              label="3ra respuesta"
              name="checkbox3"
              checked={checkbox3}
              onChange={() => setCheckbox3(!checkbox3)}
            />
          </div>
        </div>
      </div>
      <Link disabled to={slug ? `/${slug}/form` : '/no-class/form'}>
        <button
          type="button"
          className="transition delay-150 duration-300 ease-in-out bg-blue-600 hover:bg-blue-700 disabled:opacity-25 disabled:cursor-not-allowed text-white font-semibold px-6 py-3 rounded-md outline-none focus:outline-none"
        >
          Siguiente
        </button>
      </Link>
    </div>
  );
}

export default Home;
