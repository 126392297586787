import React from 'react';
import { useRecoilValue } from 'recoil';
import { pagesState, pageState } from '../../atoms/Atoms';
import Progress from './Progress';

function Title() {
  const pages = useRecoilValue(pagesState);
  const page = useRecoilValue(pageState);

  return (
    <div className="px-4 py-10 border-b bg-gray-900 rounded-t-xl text-gray-100">
      <h3 className="text-2xl font-semibold tracking-tight mb-4">
        {pages[page].section}
      </h3>
      <Progress />
    </div>
  );
}

export default Title;
