import React from 'react';
import { BadgeCheckOutline } from 'heroicons-react';

function Item(props) {
  const {
    header,
    subheader,
    valid,
    errors,
    columns,
    children,
    description,
    sectionTitle,
  } = props;

  return (
    <div className="font-sans p-3">
      <div className="">
        {subheader && (
          <div className="font-light uppercase text-gray-600 text-center mb-3">
            {subheader}
          </div>
        )}
        {sectionTitle && (
          <div className="font-bold text-2xl mb-3 text-center">
            {sectionTitle}
          </div>
        )}
        {description && (
          <div className="mb-3 font-semibold text-lg">{description}</div>
        )}
        <div className="text-gray-900 flex mb-2">
          <div className="mr-1 transition-all">
            <BadgeCheckOutline
              className={`transition-all ${
                valid ? 'text-green-500' : 'text-gray-300'
              }`}
            />
          </div>
          <div>
            <div
              className={
                errors ? 'text-red-500 leading-normal' : 'leading-normal'
              }
            >
              <div className="text-base font-medium">{header}</div>
            </div>
            {errors && (
              <div className="text-red-500 text-xs mb-3">* {errors}</div>
            )}
          </div>
        </div>
        <div
          className={`mx-5 grid grid-cols-1 gap-x-6 gap-y-2 ${
            columns && `grid-cols-${columns}`
          }`}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

export default Item;
