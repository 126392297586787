import React from 'react';
import { useParams } from 'react-router-dom';
import publicIp from 'public-ip';
import { useMount } from 'react-use';
import { useRecoilState, useRecoilValue } from 'recoil';
import Modal from '../Modal';
import Pagination from '../Pagination';
import Title from '../Title';
import Steps from './Steps/Steps';
import { accessAtom, formState } from '../../../atoms/Atoms';

function Form() {
  const { slug } = useParams();
  const [form, setForm] = useRecoilState(formState);
  const access = useRecoilValue(accessAtom);

  const getIP = () => {
    publicIp
      .v4({
        fallbackUrls: ['https://ifconfig.co/ip'],
      })
      .then((response) => {
        setForm({
          ...form,
          ip: response,
        });
      });
  };

  useMount(() => {
    if (slug) {
      setForm({
        ...form,
        slug,
      });
    }
    getIP();
    setForm({
      ...form,
      date: new Date(),
    });
  });

  if (!access) {
    return (
      <div className="flex flex-col flex-1 items-center justify-center font-sans text-sm font-normal py-8 bg-gray-900 text-white">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="h-8 w-8 mb-2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
          />
        </svg>
        <span>¡Lo sentimos, no hay sesión abierta en estos momentos!</span>
      </div>
    );
  }

  return (
    <>
      <Modal />
      <div className="c-container font-sans text-sm font-normal p-4 pb-10">
        <Title />
        <Steps />
        <Pagination />
      </div>
    </>
  );
}

export default Form;
