import React from 'react';
import { useRecoilValue } from 'recoil';
import { progressSelector } from '../../atoms/Selectors';
import { pagesState, pageState } from '../../atoms/Atoms';

function Progress() {
  const page = useRecoilValue(pageState);
  const pages = useRecoilValue(pagesState);
  const progressState = useRecoilValue(progressSelector);

  return (
    <div>
      <div className="block bg-gray-700 h-2 rounded-full relative">
        <div
          className="block bg-yellow-400 h-2 rounded-full transition-all delay-150 duration-1000 ease-in"
          style={{ width: `${progressState}%` }}
        />
      </div>
      <div className="flex mb-2 items-center justify-between">
        <div className="inline-block text-gray-200 text-xs text-center rounded-full border border-1 border-gray-700 px-2 mt-2">
          Página {page + 1} de {pages.length}
        </div>
        <div className="text-right">
          <span className="text-xs font-semibold inline-block text-gray-200">
            {`${progressState}%`}
          </span>
        </div>
      </div>
    </div>
  );
}

export default Progress;
