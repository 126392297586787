import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import is from 'is_js';
import _ from 'lodash';
import {
  errorsState,
  formState,
  pagesState,
  pageState,
} from '../../../../atoms/Atoms';
import Radio from './shared-components/Radio';
import Checkbox from './shared-components/Checkbox';
import List from '../../list/List';
import Item from '../../list/Item';
import Input from './shared-components/Input';
import Table from '../../table/Table';

function Steps() {
  const [form, setForm] = useRecoilState(formState);
  const errors = useRecoilValue(errorsState);
  const pages = useRecoilValue(pagesState);
  const page = useRecoilValue(pageState);

  return (
    <List>
      <div className="divide-y divide-gray-400">
        {_.map(pages[page].questions, (q) => {
          if (q.type === 'radio') {
            return (
              <Item
                key={q.id}
                header={q.header}
                subheader={q.subheader}
                description={q.description}
                sectionTitle={q.sectionTitle}
                columns={q.columns}
                valid={is.not.empty(form[q.valid])}
                errors={is.empty(form[q.errors]) && errors[q.errors]}
              >
                {_.map(q.items, (i) => {
                  if (i.type === 'radio') {
                    return (
                      <Radio
                        key={i.label}
                        label={i.label}
                        name={i.name}
                        value={i.value}
                        checked={form[i.id] === i.value}
                        onChange={(e) => {
                          setForm({ ...form, [i.id]: e.target.value });
                        }}
                      />
                    );
                  }
                  return (
                    <Input
                      key={i.name}
                      show={form[i.visible]}
                      label={i.label}
                      name={i.name}
                      placeholder={i.placeholder}
                      value={form[i.name]}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          [i.name]: e.target.value,
                        });
                      }}
                      alwaysShow={i.alwaysShow}
                    />
                  );
                })}
              </Item>
            );
          }
          if (q.type === 'checkbox') {
            return (
              <Item
                key={q.id}
                header={q.header}
                subheader={q.subheader}
                description={q.description}
                sectionTitle={q.sectionTitle}
                valid={is.any.truthy(_.map(q.errors, (v) => form[v]))}
                columns={q.columns}
                errors={
                  is.all.falsy(_.map(q.errors, (e) => form[e])) && errors[q.id]
                }
              >
                {_.map(q.items, (i) => {
                  if (i.type === 'checkbox') {
                    return (
                      <Checkbox
                        key={i.id}
                        label={i.label}
                        name={i.id}
                        checked={form[i.name]}
                        onChange={() => {
                          setForm({
                            ...form,
                            [i.name]: !form[i.id],
                          });
                        }}
                      />
                    );
                  }
                  return (
                    <Input
                      key={i.name}
                      show={form[i.visible]}
                      label={i.label}
                      name={i.name}
                      placeholder={i.placeholder}
                      value={form[i.name]}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          [i.name]: e.target.value,
                        });
                      }}
                      alwaysShow={i.alwaysShow}
                    />
                  );
                })}
              </Item>
            );
          }
          return (
            <Item
              key={q.id}
              header={q.header}
              subheader={q.subheader}
              description={q.description}
              sectionTitle={q.sectionTitle}
              columns={q.columns}
              valid={is.all.truthy(_.map(q.valid, (v) => form[v]))}
              errors={
                is.any.falsy(_.map(q.errors, (e) => form[e])) && errors[q.id]
              }
            >
              <Table header={q.tblHeader} rows={q.tblRows} />
            </Item>
          );
        })}
      </div>
    </List>
  );
}

export default Steps;
