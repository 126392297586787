import { atom } from 'recoil';

export const accessAtom = atom({
  key: 'access',
  default: null,
});

export const toastState = atom({
  key: 'toast',
  default: false,
});

export const pageState = atom({
  key: 'page',
  default: 0,
});

export const pagesState = atom({
  key: 'pages',
  default: [
    {
      id: 1,
      section: 'PAGINA 1',
      questions: [
        {
          id: 'section_1_1',
          type: 'radio',
          header: '1. ¿Cuál es tu sexo?',
          subheader: null,
          valid: 'section_1_1',
          errors: 'section_1_1',
          columns: 2,
          items: [
            {
              id: 'section_1_1',
              type: 'radio',
              label: 'Femenino',
              name: 'section_1_1',
              value: 'Femenino',
            },
            {
              id: 'section_1_1',
              type: 'radio',
              label: 'Masculino',
              name: 'section_1_1',
              value: 'Masculino',
            },
          ],
        },
        {
          id: 'section_1_2',
          type: 'radio',
          header: '2. ¿En qué grado estás?',
          subheader: null,
          valid: 'section_1_2',
          errors: 'section_1_2',
          columns: 3,
          items: [
            {
              id: 'section_1_2',
              type: 'radio',
              label: 'Séptimo (7)',
              name: 'section_1_2',
              value: 'Séptimo (7)',
            },
            {
              id: 'section_1_2',
              type: 'radio',
              label: 'Noveno (9)',
              name: 'section_1_2',
              value: 'Noveno (9)',
            },
            {
              id: 'section_1_2',
              type: 'radio',
              label: 'Undécimo (11)',
              name: 'section_1_2',
              value: 'Undécimo (11)',
            },
            {
              id: 'section_1_2',
              type: 'radio',
              label: 'Octavo (8)',
              name: 'section_1_2',
              value: 'Octavo (8)',
            },
            {
              id: 'section_1_2',
              type: 'radio',
              label: 'Décimo (10)',
              name: 'section_1_2',
              value: 'Décimo (10)',
            },
            {
              id: 'section_1_2',
              type: 'radio',
              label: 'Duodécimo (12)',
              name: 'section_1_2',
              value: 'Duodécimo (12)',
            },
          ],
        },
        {
          id: 'section_1_3',
          type: 'radio',
          header: '3. ¿Cuántos años tienes hoy?',
          subheader: null,
          valid: 'section_1_3',
          errors: 'section_1_3',
          columns: 3,
          items: [
            {
              id: 'section_1_3',
              type: 'radio',
              label: '10 años o menos',
              name: 'section_1_3',
              value: '10 años o menos',
            },
            {
              id: 'section_1_3',
              type: 'radio',
              label: '14 años',
              name: 'section_1_3',
              value: '14 años',
            },
            {
              id: 'section_1_3',
              type: 'radio',
              label: '17 años',
              name: 'section_1_3',
              value: '17 años',
            },
            {
              id: 'section_1_3',
              type: 'radio',
              label: '11 años',
              name: 'section_1_3',
              value: '11 años',
            },
            {
              id: 'section_1_3',
              type: 'radio',
              label: '15 años',
              name: 'section_1_3',
              value: '15 años',
            },
            {
              id: 'section_1_3',
              type: 'radio',
              label: '18 años',
              name: 'section_1_3',
              value: '18 años',
            },
            {
              id: 'section_1_3',
              type: 'radio',
              label: '12 años',
              name: 'section_1_3',
              value: '12 años',
            },
            {
              id: 'section_1_3',
              type: 'radio',
              label: '16 años',
              name: 'section_1_3',
              value: '16 años',
            },
            {
              id: 'section_1_3',
              type: 'radio',
              label: '19 años o más',
              name: 'section_1_3',
              value: '19 años o más',
            },
            {
              id: 'section_1_3',
              type: 'radio',
              label: '13 años',
              name: 'section_1_3',
              value: '13 años',
            },
          ],
        },
        {
          id: 'section_1_4',
          type: 'checkbox',
          header: '4. ¿Con quién vives?',
          subheader: 'Puedes marcar más de una respuesta',
          valid: [
            'section_1_4_1',
            'section_1_4_2',
            'section_1_4_3',
            'section_1_4_4',
            'section_1_4_5',
            'section_1_4_6',
            'section_1_4_7',
            'section_1_4_8',
            'section_1_4_9',
          ],
          errors: [
            'section_1_4_1',
            'section_1_4_2',
            'section_1_4_3',
            'section_1_4_4',
            'section_1_4_5',
            'section_1_4_6',
            'section_1_4_7',
            'section_1_4_8',
            'section_1_4_9',
          ],
          columns: 3,
          items: [
            {
              id: 'section_1_4_1',
              type: 'checkbox',
              label: 'Madre',
              name: 'section_1_4_1',
              value: 'Madre',
            },
            {
              id: 'section_1_4_2',
              type: 'checkbox',
              label: 'Padrastro',
              name: 'section_1_4_2',
              value: 'Padrastro',
            },
            {
              id: 'section_1_4_3',
              type: 'checkbox',
              label: 'Esposo(a)',
              name: 'section_1_4_3',
              value: 'Esposo(a)',
            },
            {
              id: 'section_1_4_4',
              type: 'checkbox',
              label: 'Madrastra',
              name: 'section_1_4_4',
              value: 'Madrastra',
            },
            {
              id: 'section_1_4_5',
              type: 'checkbox',
              label: 'Hermanos(as)',
              name: 'section_1_4_5',
              value: 'Hermanos(as)',
            },
            {
              id: 'section_1_4_6',
              type: 'checkbox',
              label: 'Hijos(as)',
              name: 'section_1_4_6',
              value: 'Hijos(as)',
            },
            {
              id: 'section_1_4_7',
              type: 'checkbox',
              label: 'Padre',
              name: 'section_1_4_7',
              value: 'Padre',
            },
            {
              id: 'section_1_4_8',
              type: 'checkbox',
              label: 'Abuelo(a)',
              name: 'section_1_4_8',
              value: 'Abuelo(a)',
            },
            {
              id: 'section_1_4_9',
              type: 'checkbox',
              label: 'Otra persona',
              name: 'section_1_4_9',
              value: 'Otra persona',
            },
          ],
        },
        {
          id: 'section_1_5',
          type: 'radio',
          header: '5. ¿Dónde naciste?',
          subheader: null,
          valid: 'section_1_5',
          errors: 'section_1_5',
          columns: 2,
          items: [
            {
              id: 'section_1_5',
              type: 'radio',
              label: 'Puerto Rico',
              name: 'section_1_5',
              value: 'Puerto Rico',
            },
            {
              id: 'section_1_5',
              type: 'radio',
              label: 'República Dominicana',
              name: 'section_1_5',
              value: 'República Dominicana',
            },
            {
              id: 'section_1_5',
              type: 'radio',
              label: 'Estados Unidos',
              name: 'section_1_5',
              value: 'Estados Unidos',
            },
            {
              id: 'section_1_5',
              type: 'radio',
              label: 'Otro país',
              name: 'section_1_5',
              value: 'Otro país',
            },
          ],
        },
        {
          id: 'section_2_1',
          type: 'radio',
          sectionTitle: 'TU ESCUELA',
          header: '1. En general, ¿cuáles fueron tus notas el año pasado?',
          subheader: null,
          valid: 'section_2_1',
          errors: 'section_2_1',
          columns: 3,
          items: [
            {
              id: 'section_2_1',
              type: 'radio',
              label: 'Todas o casi todas A',
              name: 'section_2_1',
              value: 'Todas o casi todas A',
            },
            {
              id: 'section_2_1',
              type: 'radio',
              label: 'B y C',
              name: 'section_2_1',
              value: 'B y C',
            },
            {
              id: 'section_2_1',
              type: 'radio',
              label: 'Todas o casi todas D',
              name: 'section_2_1',
              value: 'Todas o casi todas D',
            },
            {
              id: 'section_2_1',
              type: 'radio',
              label: 'A y B',
              name: 'section_2_1',
              value: 'A y B',
            },
            {
              id: 'section_2_1',
              type: 'radio',
              label: 'Todas o casi todas C',
              name: 'section_2_1',
              value: 'Todas o casi todas C',
            },
            {
              id: 'section_2_1',
              type: 'radio',
              label: 'D y F',
              name: 'section_2_1',
              value: 'D y F',
            },
            {
              id: 'section_2_1',
              type: 'radio',
              label: 'Todas o casi todas B',
              name: 'section_2_1',
              value: 'Todas o casi todas B',
            },
            {
              id: 'section_2_1',
              type: 'radio',
              label: 'C y D',
              name: 'section_2_1',
              value: 'C y D',
            },
          ],
        },
        {
          id: 'section_2_2',
          type: 'radio',
          header:
            '2. ¿Cómo son tus notas comparadas a las de la mayoría de los estudiantes de tu salón?',
          subheader: null,
          valid: 'section_2_2',
          errors: 'section_2_2',
          columns: 3,
          items: [
            {
              id: 'section_2_2',
              type: 'radio',
              label: 'Mejores',
              name: 'section_2_2',
              value: 'Mejores',
            },
            {
              id: 'section_2_2',
              type: 'radio',
              label: 'Más o menos iguales',
              name: 'section_2_2',
              value: 'Más o menos iguales',
            },
            {
              id: 'section_2_2',
              type: 'radio',
              label: 'Peores',
              name: 'section_2_2',
              value: 'Peores',
            },
          ],
        },
      ],
    },
    {
      id: 2,
      section: 'PAGINA 2',
      questions: [
        {
          id: 'section_2_3',
          type: 'radio',
          header:
            '3. ¿Cuán a menudo las cosas que haces en la escuela son importantes y de valor para ti?',
          subheader: null,
          valid: 'section_2_3',
          errors: 'section_2_3',
          columns: 2,
          items: [
            {
              id: 'section_2_3',
              type: 'radio',
              label: 'Nunca',
              name: 'section_2_3',
              value: 'Nunca',
            },
            {
              id: 'section_2_3',
              type: 'radio',
              label: 'Casi siempre',
              name: 'section_2_3',
              value: 'Casi siempre',
            },
            {
              id: 'section_2_3',
              type: 'radio',
              label: 'Casi nunca',
              name: 'section_2_3',
              value: 'Casi nunca',
            },
            {
              id: 'section_2_3',
              type: 'radio',
              label: 'Siempre',
              name: 'section_2_3',
              value: 'Siempre',
            },
          ],
        },
        {
          id: 'section_2_4',
          type: 'radio',
          header:
            '4. ¿Cuán importante son para tu futuro las cosas que estás aprendiendo en la escuela?',
          subheader: null,
          valid: 'section_2_4',
          errors: 'section_2_4',
          columns: 2,
          items: [
            {
              id: 'section_2_4',
              type: 'radio',
              label: 'Nada importantes',
              name: 'section_2_4',
              value: 'Nada importantes',
            },
            {
              id: 'section_2_4',
              type: 'radio',
              label: 'Importantes',
              name: 'section_2_4',
              value: 'Importantes',
            },
            {
              id: 'section_2_4',
              type: 'radio',
              label: 'Poco importantes',
              name: 'section_2_4',
              value: 'Poco importantes',
            },
            {
              id: 'section_2_4',
              type: 'radio',
              label: 'Muy importantes',
              name: 'section_2_4',
              value: 'Muy importantes',
            },
          ],
        },
        {
          id: 'section_2_5',
          type: 'radio',
          header: '5. ¿Cuán interesantes son la mayoría de tus clases?',
          subheader: null,
          valid: 'section_2_5',
          errors: 'section_2_5',
          columns: 2,
          items: [
            {
              id: 'section_2_5',
              type: 'radio',
              label: 'Nada interesantes',
              name: 'section_2_5',
              value: 'Nada interesantes',
            },
            {
              id: 'section_2_5',
              type: 'radio',
              label: 'Interesantes',
              name: 'section_2_5',
              value: 'Interesantes',
            },
            {
              id: 'section_2_5',
              type: 'radio',
              label: 'Poco interesantes',
              name: 'section_2_5',
              value: 'Poco interesantes',
            },
            {
              id: 'section_2_5',
              type: 'radio',
              label: 'Muy interesantes',
              name: 'section_2_5',
              value: 'Muy interesantes',
            },
          ],
        },
        {
          id: 'section_2_6',
          type: 'table',
          header: '6. El año pasado…',
          subheader: null,
          valid: ['section_2_6_1', 'section_2_6_2', 'section_2_6_3'],
          errors: ['section_2_6_1', 'section_2_6_2', 'section_2_6_3'],
          columns: 1,
          tblHeader: [
            { id: 0, title: '' },
            { id: 1, title: 'Nunca' },
            { id: 2, title: 'Casi nunca' },
            { id: 3, title: 'Casi siempre' },
            { id: 4, title: 'Siempre' },
          ],
          tblRows: [
            {
              id: 0,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'a. ¿odiaste la escuela?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_2_6_1',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_2_6_1',
                  value: 'Casi nunca',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_2_6_1',
                  value: 'Casi siempre',
                },
                {
                  id: 4,
                  type: 'radio',
                  name: 'section_2_6_1',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 1,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'b. ¿disfrutaste de la escuela?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_2_6_2',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_2_6_2',
                  value: 'Casi nunca',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_2_6_2',
                  value: 'Casi siempre',
                },
                {
                  id: 4,
                  type: 'radio',
                  name: 'section_2_6_2',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 2,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'c. ¿trataste de hacer tu mejor trabajo en la escuela?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_2_6_3',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_2_6_3',
                  value: 'Casi nunca',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_2_6_3',
                  value: 'Casi siempre',
                },
                {
                  id: 4,
                  type: 'radio',
                  name: 'section_2_6_3',
                  value: 'Siempre',
                },
              ],
            },
          ],
        },
        {
          id: 'section_2_7',
          type: 'radio',
          header:
            '7. En las últimas 4 semanas, ¿cuántos días has faltado a la escuela por “cortar clases” o sin permiso de tus padres? Incluye las clases por internet.',
          subheader: null,
          valid: 'section_2_7',
          errors: 'section_2_7',
          columns: 3,
          items: [
            {
              id: 'section_2_7',
              type: 'radio',
              label: 'Nunca',
              name: 'section_2_7',
              value: 'Nunca',
            },
            {
              id: 'section_2_7',
              type: 'radio',
              label: '3 días',
              name: 'section_2_7',
              value: '3 días',
            },
            {
              id: 'section_2_7',
              type: 'radio',
              label: '10-12 días',
              name: 'section_2_7',
              value: '10-12 días',
            },
            {
              id: 'section_2_7',
              type: 'radio',
              label: '1 día',
              name: 'section_2_7',
              value: '1 día',
            },
            {
              id: 'section_2_7',
              type: 'radio',
              label: '4-6 días',
              name: 'section_2_7',
              value: '4-6 días',
            },
            {
              id: 'section_2_7',
              type: 'radio',
              label: '13-15 días',
              name: 'section_2_7',
              value: '13-15 días',
            },
            {
              id: 'section_2_7',
              type: 'radio',
              label: '2 días',
              name: 'section_2_7',
              value: '2 días',
            },
            {
              id: 'section_2_7',
              type: 'radio',
              label: '7-9 días',
              name: 'section_2_7',
              value: '7-9 días',
            },
            {
              id: 'section_2_7',
              type: 'radio',
              label: '16 días o más',
              name: 'section_2_7',
              value: '16 días o más',
            },
          ],
        },
        {
          id: 'section_2_8',
          type: 'radio',
          header: '8. ¿Cuán a menudo asistes a tus clases por internet?',
          subheader: null,
          valid: 'section_2_8',
          errors: 'section_2_8',
          columns: 3,
          items: [
            {
              id: 'section_2_8',
              type: 'radio',
              label: 'Siempre',
              name: 'section_2_8',
              value: 'Siempre',
            },
            {
              id: 'section_2_8',
              type: 'radio',
              label: 'A veces',
              name: 'section_2_8',
              value: 'A veces',
            },
            {
              id: 'section_2_8',
              type: 'radio',
              label: 'Nunca',
              name: 'section_2_8',
              value: 'Nunca',
            },
            {
              id: 'section_2_8',
              type: 'radio',
              label: 'Casi siempre',
              name: 'section_2_8',
              value: 'Casi siempre',
            },
            {
              id: 'section_2_8',
              type: 'radio',
              label: 'Casi nunca',
              name: 'section_2_8',
              value: 'Casi nunca',
            },
          ],
        },
        {
          id: 'section_2_9',
          type: 'checkbox',
          header: '9. ¿Por qué razones has faltado a tus clases por internet?',
          subheader: 'Puedes marcar más de una respuesta',
          valid: [
            'section_2_9_1',
            'section_2_9_2',
            'section_2_9_3',
            'section_2_9_4',
            'section_2_9_5',
            'section_2_9_6',
            'section_2_9_7',
            'section_2_9_8',
            'section_2_9_9',
          ],
          errors: [
            'section_2_9_1',
            'section_2_9_2',
            'section_2_9_3',
            'section_2_9_4',
            'section_2_9_5',
            'section_2_9_6',
            'section_2_9_7',
            'section_2_9_8',
            'section_2_9_9',
          ],
          columns: 2,
          items: [
            {
              id: 'section_2_9_1',
              type: 'checkbox',
              label: 'No tengo clases por internet',
              name: 'section_2_9_1',
              value: 'No tengo clases por internet',
            },
            {
              id: 'section_2_9_2',
              type: 'checkbox',
              label: 'Se me cayó la señal de internet',
              name: 'section_2_9_2',
              value: 'Se me cayó la señal de internet',
            },
            {
              id: 'section_2_9_3',
              type: 'checkbox',
              label: 'No quise asistir',
              name: 'section_2_9_3',
              value: 'No quise asistir',
            },
            {
              id: 'section_2_9_4',
              type: 'checkbox',
              label: 'No tenía computadora',
              name: 'section_2_9_4',
              value: 'No tenía computadora',
            },
            {
              id: 'section_2_9_5',
              type: 'checkbox',
              label: 'Se fue la luz',
              name: 'section_2_9_5',
              value: 'Se fue la luz',
            },
            {
              id: 'section_2_9_6',
              type: 'checkbox',
              label: 'Por otra razón',
              name: 'section_2_9_6',
              value: 'Por otra razón',
            },
            {
              id: 'section_2_9_7',
              type: 'checkbox',
              label: 'No tenía internet',
              name: 'section_2_9_7',
              value: 'No tenía internet',
            },
            {
              id: 'section_2_9_8',
              type: 'checkbox',
              label: 'Las clases son aburridas',
              name: 'section_2_9_8',
              value: 'Las clases son aburridas',
            },
            {
              id: 'section_2_9_9',
              type: 'checkbox',
              label: 'Nunca he faltado, siempre he asistido',
              name: 'section_2_9_9',
              value: 'Nunca he faltado, siempre he asistido',
            },
          ],
        },
        {
          id: 'section_2_10',
          type: 'radio',
          header:
            '10. En tu escuela, ¿cuántas oportunidades hay para que los estudiantes participen en deportes, clubes y otras actividades fuera de clase?',
          subheader: null,
          valid: 'section_2_10',
          errors: 'section_2_10',
          columns: 2,
          items: [
            {
              id: 'section_2_10',
              type: 'radio',
              label: 'Ninguna',
              name: 'section_2_10',
              value: 'Ninguna',
            },
            {
              id: 'section_2_10',
              type: 'radio',
              label: 'Bastantes',
              name: 'section_2_10',
              value: 'Bastantes',
            },
            {
              id: 'section_2_10',
              type: 'radio',
              label: 'Pocas',
              name: 'section_2_10',
              value: 'Pocas',
            },
            {
              id: 'section_2_10',
              type: 'radio',
              label: 'Muchas',
              name: 'section_2_10',
              value: 'Muchas',
            },
          ],
        },
      ],
    },
    {
      id: 3,
      section: 'PAGINA 3',
      questions: [
        {
          id: 'section_2_11',
          type: 'checkbox',
          header: '11. ¿Has repetido algún grado?',
          subheader: 'Puedes marcar más de una respuesta',
          valid: [
            'section_2_11_1',
            'section_2_11_2',
            'section_2_11_3',
            'section_2_11_4',
          ],
          errors: [
            'section_2_11_1',
            'section_2_11_2',
            'section_2_11_3',
            'section_2_11_4',
          ],
          columns: 2,
          items: [
            {
              id: 'section_2_11_1',
              type: 'checkbox',
              label: 'Nunca',
              name: 'section_2_11_1',
              value: 'Nunca',
            },
            {
              id: 'section_2_11_2',
              type: 'checkbox',
              label: 'Sí, en la intermedia (7mo-9no)',
              name: 'section_2_11_2',
              value: 'Sí, en la intermedia (7mo-9no)',
            },
            {
              id: 'section_2_11_3',
              type: 'checkbox',
              label: 'Sí, en la elemental (1ro-6to)',
              name: 'section_2_11_3',
              value: 'Sí, en la elemental (1ro-6to)',
            },
            {
              id: 'section_2_11_4',
              type: 'checkbox',
              label: 'Sí, en la superior (10mo-12mo)',
              name: 'section_2_11_4',
              value: 'Sí, en la superior (10mo-12mo)',
            },
          ],
        },
        {
          id: 'section_2_12',
          type: 'radio',
          header: '12. ¿Estás pensando dejar la escuela?',
          subheader: null,
          valid: 'section_2_12',
          errors: 'section_2_12',
          columns: 1,
          items: [
            {
              id: 'section_2_12',
              type: 'radio',
              label: 'No estoy pensando dejar la escuela',
              name: 'section_2_12',
              value: 'No estoy pensando dejar la escuela',
            },
            {
              id: 'section_2_12',
              type: 'radio',
              label: 'Quizás deje la escuela',
              name: 'section_2_12',
              value: 'Quizás deje la escuela',
            },
            {
              id: 'section_2_12',
              type: 'radio',
              label: 'Estoy casi seguro de que voy a dejar la escuela',
              name: 'section_2_12',
              value: 'Estoy casi seguro de que voy a dejar la escuela',
            },
          ],
        },
        {
          id: 'section_3_1',
          type: 'radio',
          sectionTitle: 'TUS PADRES Y FAMILIARES',
          header: '1. Eres bien apegado(a) a tu mamá o encargada.',
          subheader: null,
          valid: 'section_3_1',
          errors: 'section_3_1',
          columns: 3,
          items: [
            {
              id: 'section_3_1',
              type: 'radio',
              label: 'Nada',
              name: 'section_3_1',
              value: 'Nada',
            },
            {
              id: 'section_3_1',
              type: 'radio',
              label: 'Algo',
              name: 'section_3_1',
              value: 'Algo',
            },
            {
              id: 'section_3_1',
              type: 'radio',
              label: 'No tengo mamá.',
              name: 'section_3_1',
              value: 'No tengo mamá.',
            },
            {
              id: 'section_3_1',
              type: 'radio',
              label: 'Muy poco',
              name: 'section_3_1',
              value: 'Muy poco',
            },
            {
              id: 'section_3_1',
              type: 'radio',
              label: 'Mucho',
              name: 'section_3_1',
              value: 'Mucho',
            },
          ],
        },
        {
          id: 'section_3_2',
          type: 'radio',
          header: '2. Eres bien apegado(a) a tu papá o encargado.',
          subheader: null,
          valid: 'section_3_2',
          errors: 'section_3_2',
          columns: 3,
          items: [
            {
              id: 'section_3_2',
              type: 'radio',
              label: 'Nada',
              name: 'section_3_2',
              value: 'Nada',
            },
            {
              id: 'section_3_2',
              type: 'radio',
              label: 'Algo',
              name: 'section_3_2',
              value: 'Algo',
            },
            {
              id: 'section_3_2',
              type: 'radio',
              label: 'No tengo papá.',
              name: 'section_3_2',
              value: 'No tengo papá.',
            },
            {
              id: 'section_3_2',
              type: 'radio',
              label: 'Muy poco',
              name: 'section_3_2',
              value: 'Muy poco',
            },
            {
              id: 'section_3_2',
              type: 'radio',
              label: 'Mucho',
              name: 'section_3_2',
              value: 'Mucho',
            },
          ],
        },
        {
          id: 'section_3_3',
          type: 'table',
          header: '3. Sobre tus padres o encargados…',
          subheader: null,
          valid: [
            'section_3_3_1',
            'section_3_3_2',
            'section_3_3_3',
            'section_3_3_4',
          ],
          errors: [
            'section_3_3_1',
            'section_3_3_2',
            'section_3_3_3',
            'section_3_3_4',
          ],
          columns: 1,
          tblHeader: [
            { id: 0, title: '' },
            { id: 1, title: 'Nunca' },
            { id: 2, title: 'Casi nunca' },
            { id: 3, title: 'Casi siempre' },
            { id: 4, title: 'Siempre' },
          ],
          tblRows: [
            {
              id: 0,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'a. le cuentas tus cosas a tu mamá.',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_3_3_1',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_3_3_1',
                  value: 'Casi nunca',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_3_3_1',
                  value: 'Casi siempre',
                },
                {
                  id: 4,
                  type: 'radio',
                  name: 'section_3_3_1',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 1,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'b. te gusta pasar tiempo con tu mamá.',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_3_3_2',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_3_3_2',
                  value: 'Casi nunca',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_3_3_2',
                  value: 'Casi siempre',
                },
                {
                  id: 4,
                  type: 'radio',
                  name: 'section_3_3_2',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 2,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'c. le cuentas tus cosas a tu papá.',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_3_3_3',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_3_3_3',
                  value: 'Casi nunca',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_3_3_3',
                  value: 'Casi siempre',
                },
                {
                  id: 4,
                  type: 'radio',
                  name: 'section_3_3_3',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 3,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'd. te gusta pasar tiempo con tu papá.',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_3_3_4',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_3_3_4',
                  value: 'Casi nunca',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_3_3_4',
                  value: 'Casi siempre',
                },
                {
                  id: 4,
                  type: 'radio',
                  name: 'section_3_3_4',
                  value: 'Siempre',
                },
              ],
            },
          ],
        },
        {
          id: 'section_3_4',
          type: 'table',
          header: '4. En tu casa…',
          subheader: null,
          valid: [
            'section_3_4_1',
            'section_3_4_2',
            'section_3_4_3',
            'section_3_4_4',
            'section_3_4_5',
          ],
          errors: [
            'section_3_4_1',
            'section_3_4_2',
            'section_3_4_3',
            'section_3_4_4',
            'section_3_4_5',
          ],
          columns: 1,
          tblHeader: [
            { id: 0, title: '' },
            { id: 1, title: 'Nunca' },
            { id: 2, title: 'Casi nunca' },
            { id: 3, title: 'Casi siempre' },
            { id: 4, title: 'Siempre' },
          ],
          tblRows: [
            {
              id: 0,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'a. hay reglas claras.',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_3_4_1',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_3_4_1',
                  value: 'Casi nunca',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_3_4_1',
                  value: 'Casi siempre',
                },
                {
                  id: 4,
                  type: 'radio',
                  name: 'section_3_4_1',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 1,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'b. hay discusiones fuertes.',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_3_4_2',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_3_4_2',
                  value: 'Casi nunca',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_3_4_2',
                  value: 'Casi siempre',
                },
                {
                  id: 4,
                  type: 'radio',
                  name: 'section_3_4_2',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 2,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'c. se insultan o gritan con frecuencia.',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_3_4_3',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_3_4_3',
                  value: 'Casi nunca',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_3_4_3',
                  value: 'Casi siempre',
                },
                {
                  id: 4,
                  type: 'radio',
                  name: 'section_3_4_3',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 3,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'd. discuten siempre por las mismas cosas.',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_3_4_4',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_3_4_4',
                  value: 'Casi nunca',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_3_4_4',
                  value: 'Casi siempre',
                },
                {
                  id: 4,
                  type: 'radio',
                  name: 'section_3_4_4',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 4,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text:
                    'e. hay reglas claras sobre el uso de alcohol y drogas.',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_3_4_5',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_3_4_5',
                  value: 'Casi nunca',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_3_4_5',
                  value: 'Casi siempre',
                },
                {
                  id: 4,
                  type: 'radio',
                  name: 'section_3_4_5',
                  value: 'Siempre',
                },
              ],
            },
          ],
        },
        {
          id: 'section_3_5',
          type: 'table',
          header: '5. ¿Cuán a menudo tus papás…',
          subheader: null,
          valid: [
            'section_3_5_1',
            'section_3_5_2',
            'section_3_5_3',
            'section_3_5_4',
            'section_3_5_5',
          ],
          errors: [
            'section_3_5_1',
            'section_3_5_2',
            'section_3_5_3',
            'section_3_5_4',
            'section_3_5_5',
          ],
          columns: 1,
          tblHeader: [
            { id: 0, title: '' },
            { id: 1, title: 'Nunca' },
            { id: 2, title: 'Casi nunca' },
            { id: 3, title: 'Casi siempre' },
            { id: 4, title: 'Siempre' },
          ],
          tblRows: [
            {
              id: 0,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'a. se darían cuenta si faltas a la escuela?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_3_5_1',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_3_5_1',
                  value: 'Casi nunca',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_3_5_1',
                  value: 'Casi siempre',
                },
                {
                  id: 4,
                  type: 'radio',
                  name: 'section_3_5_1',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 1,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'b. te dicen si estás haciendo las cosas bien?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_3_5_2',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_3_5_2',
                  value: 'Casi nunca',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_3_5_2',
                  value: 'Casi siempre',
                },
                {
                  id: 4,
                  type: 'radio',
                  name: 'section_3_5_2',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 2,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'c. te preguntan si has hecho tus asignaciones?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_3_5_3',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_3_5_3',
                  value: 'Casi nunca',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_3_5_3',
                  value: 'Casi siempre',
                },
                {
                  id: 4,
                  type: 'radio',
                  name: 'section_3_5_3',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 3,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text:
                    'd. te dicen que están orgullosos de ti por algo que has hecho?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_3_5_4',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_3_5_4',
                  value: 'Casi nunca',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_3_5_4',
                  value: 'Casi siempre',
                },
                {
                  id: 4,
                  type: 'radio',
                  name: 'section_3_5_4',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 4,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text:
                    'e. saben dónde estás y con quién andas cuando no estás en la casa?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_3_5_5',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_3_5_5',
                  value: 'Casi nunca',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_3_5_5',
                  value: 'Casi siempre',
                },
                {
                  id: 4,
                  type: 'radio',
                  name: 'section_3_5_5',
                  value: 'Siempre',
                },
              ],
            },
          ],
        },
        {
          id: 'section_3_6',
          type: 'table',
          header: '6. ¿Se darían cuenta tus padres si tú…',
          subheader: null,
          valid: ['section_3_6_1', 'section_3_6_2', 'section_3_6_3'],
          errors: ['section_3_6_1', 'section_3_6_2', 'section_3_6_3'],
          columns: 1,
          tblHeader: [
            { id: 0, title: '' },
            { id: 1, title: 'Nunca' },
            { id: 2, title: 'Casi nunca' },
            { id: 3, title: 'Casi siempre' },
            { id: 4, title: 'Siempre' },
          ],
          tblRows: [
            {
              id: 0,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'a. no llegas a tiempo a casa?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_3_6_1',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_3_6_1',
                  value: 'Casi nunca',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_3_6_1',
                  value: 'Casi siempre',
                },
                {
                  id: 4,
                  type: 'radio',
                  name: 'section_3_6_1',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 1,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'b. tomas bebidas alcohólicas sin su permiso?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_3_6_2',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_3_6_2',
                  value: 'Casi nunca',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_3_6_2',
                  value: 'Casi siempre',
                },
                {
                  id: 4,
                  type: 'radio',
                  name: 'section_3_6_2',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 2,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text:
                    'c. andas con una pistola o arma de fuego sin su permiso?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_3_6_3',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_3_6_3',
                  value: 'Casi nunca',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_3_6_3',
                  value: 'Casi siempre',
                },
                {
                  id: 4,
                  type: 'radio',
                  name: 'section_3_6_3',
                  value: 'Siempre',
                },
              ],
            },
          ],
        },
        {
          id: 'section_3_7',
          type: 'table',
          header:
            '7. Para tus padres o encargados, ¿cuán malo sería el que tú…',
          subheader: null,
          valid: [
            'section_3_7_1',
            'section_3_7_2',
            'section_3_7_3',
            'section_3_7_4',
          ],
          errors: [
            'section_3_7_1',
            'section_3_7_2',
            'section_3_7_3',
            'section_3_7_4',
          ],
          columns: 1,
          tblHeader: [
            { id: 0, title: '' },
            { id: 1, title: 'Muy malo' },
            { id: 2, title: 'Malo' },
            { id: 3, title: 'Un poquito malo' },
            { id: 4, title: 'Nada malo' },
          ],
          tblRows: [
            {
              id: 0,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text:
                    'a. tomaras una o dos bebidas alcohólicas casi todos los días?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_3_7_1',
                  value: 'Muy malo',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_3_7_1',
                  value: 'Malo',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_3_7_1',
                  value: 'Un poquito malo',
                },
                {
                  id: 4,
                  type: 'radio',
                  name: 'section_3_7_1',
                  value: 'Nada malo',
                },
              ],
            },
            {
              id: 1,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'b. fumaras cigarrillos?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_3_7_2',
                  value: 'Muy malo',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_3_7_2',
                  value: 'Malo',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_3_7_2',
                  value: 'Un poquito malo',
                },
                {
                  id: 4,
                  type: 'radio',
                  name: 'section_3_7_2',
                  value: 'Nada malo',
                },
              ],
            },
            {
              id: 2,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'c. fumaras marihuana?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_3_7_3',
                  value: 'Muy malo',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_3_7_3',
                  value: 'Malo',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_3_7_3',
                  value: 'Un poquito malo',
                },
                {
                  id: 4,
                  type: 'radio',
                  name: 'section_3_7_3',
                  value: 'Nada malo',
                },
              ],
            },
            {
              id: 3,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'd. usaras pastillas que no te han recetado?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_3_7_4',
                  value: 'Muy malo',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_3_7_4',
                  value: 'Malo',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_3_7_4',
                  value: 'Un poquito malo',
                },
                {
                  id: 4,
                  type: 'radio',
                  name: 'section_3_7_4',
                  value: 'Nada malo',
                },
              ],
            },
          ],
        },
        {
          id: 'section_3_8',
          type: 'radio',
          header: '8. Mientras contestabas este cuestionario…',
          subheader: null,
          valid: 'section_3_8',
          errors: 'section_3_8',
          columns: 1,
          items: [
            {
              id: 'section_3_8',
              type: 'radio',
              label: 'Había alguien mirando lo que contestaba',
              name: 'section_3_8',
              value: 'Había alguien mirando lo que contestaba',
            },
            {
              id: 'section_3_8',
              type: 'radio',
              label: 'Había alguien cerca de mi mientras contestaba',
              name: 'section_3_8',
              value: 'Había alguien cerca de mi mientras contestaba',
            },
            {
              id: 'section_3_8',
              type: 'radio',
              label: 'Nadie vio lo que contestaba',
              name: 'section_3_8',
              value: 'Nadie vio lo que contestaba',
            },
          ],
        },
      ],
    },
    {
      id: 4,
      section: 'PAGINA 4',
      questions: [
        {
          id: 'section_4_1',
          type: 'table',
          sectionTitle: 'USO DE SUBSTANCIAS',
          header: '1. Alguna vez en la vida, ¿has usado...',
          subheader: null,
          valid: [
            'section_4_1_1',
            'section_4_1_2',
            'section_4_1_3',
            'section_4_1_4',
            'section_4_1_5',
            'section_4_1_6',
            'section_4_1_7',
            'section_4_1_8',
            'section_4_1_9',
            'section_4_1_10',
            'section_4_1_11',
            'section_4_1_12',
            'section_4_1_13',
          ],
          errors: [
            'section_4_1_1',
            'section_4_1_2',
            'section_4_1_3',
            'section_4_1_4',
            'section_4_1_5',
            'section_4_1_6',
            'section_4_1_7',
            'section_4_1_8',
            'section_4_1_9',
            'section_4_1_10',
            'section_4_1_11',
            'section_4_1_12',
            'section_4_1_13',
          ],
          columns: 1,
          tblHeader: [
            { id: 0, title: '' },
            { id: 1, title: 'Sí' },
            { id: 2, title: 'No' },
          ],
          tblRows: [
            {
              id: 0,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'a. Cigarrillos',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_4_1_1',
                  value: 'Sí',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_4_1_1',
                  value: 'No',
                },
              ],
            },
            {
              id: 1,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'b. Cigarrillo electrónico o vaporizador ("vaper")',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_4_1_2',
                  value: 'Sí',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_4_1_2',
                  value: 'No',
                },
              ],
            },
            {
              id: 2,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'c. Otros productos de tabaco',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_4_1_3',
                  value: 'Sí',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_4_1_3',
                  value: 'No',
                },
              ],
            },
            {
              id: 3,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'd. Bebidas alcohólicas más allá de un sorbo',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_4_1_4',
                  value: 'Sí',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_4_1_4',
                  value: 'No',
                },
              ],
            },
            {
              id: 4,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'e. Inhalantes para coger una nota o endrogarte',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_4_1_5',
                  value: 'Sí',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_4_1_5',
                  value: 'No',
                },
              ],
            },
            {
              id: 5,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text:
                    'f. Pastillas para coger una nota (Incluye sedantes, tranquilizantes, xanax, palitroque, percocet, etc.)',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_4_1_6',
                  value: 'Sí',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_4_1_6',
                  value: 'No',
                },
              ],
            },
            {
              id: 6,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'g. Marihuana o hashish fumada',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_4_1_7',
                  value: 'Sí',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_4_1_7',
                  value: 'No',
                },
              ],
            },
            {
              id: 7,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'h. Marihuana sintética',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_4_1_8',
                  value: 'Sí',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_4_1_8',
                  value: 'No',
                },
              ],
            },
            {
              id: 8,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'i. Productos de cannabis medicinal',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_4_1_9',
                  value: 'Sí',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_4_1_9',
                  value: 'No',
                },
              ],
            },
            {
              id: 9,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'j. Cocaína en polvo inhalada',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_4_1_10',
                  value: 'Sí',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_4_1_10',
                  value: 'No',
                },
              ],
            },
            {
              id: 10,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'k. Crack fumado',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_4_1_11',
                  value: 'Sí',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_4_1_11',
                  value: 'No',
                },
              ],
            },
            {
              id: 11,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'l. Heroína',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_4_1_12',
                  value: 'Sí',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_4_1_12',
                  value: 'No',
                },
              ],
            },
            {
              id: 12,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'm. Otras drogas',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_4_1_13',
                  value: 'Sí',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_4_1_13',
                  value: 'No',
                },
              ],
            },
          ],
        },
        {
          id: 'section_4_2',
          type: 'table',
          header: '2. En los últimos 12 meses, he usado…',
          subheader: null,
          valid: [
            'section_4_2_1',
            'section_4_2_2',
            'section_4_2_3',
            'section_4_2_4',
            'section_4_2_5',
            'section_4_2_6',
            'section_4_2_7',
            'section_4_2_8',
            'section_4_2_9',
            'section_4_2_10',
            'section_4_2_11',
            'section_4_2_12',
            'section_4_2_13',
          ],
          errors: [
            'section_4_2_1',
            'section_4_2_2',
            'section_4_2_3',
            'section_4_2_4',
            'section_4_2_5',
            'section_4_2_6',
            'section_4_2_7',
            'section_4_2_8',
            'section_4_2_9',
            'section_4_2_10',
            'section_4_2_11',
            'section_4_2_12',
            'section_4_2_13',
          ],
          columns: 1,
          tblHeader: [
            { id: 0, title: '' },
            { id: 1, title: 'Sí' },
            { id: 2, title: 'No' },
          ],
          tblRows: [
            {
              id: 0,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'a. Cigarrillos',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_4_2_1',
                  value: 'Sí',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_4_2_1',
                  value: 'No',
                },
              ],
            },
            {
              id: 1,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'b. Cigarrillo electrónico o vaporizador ("vaper")',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_4_2_2',
                  value: 'Sí',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_4_2_2',
                  value: 'No',
                },
              ],
            },
            {
              id: 2,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'c. Otros productos de tabaco',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_4_2_3',
                  value: 'Sí',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_4_2_3',
                  value: 'No',
                },
              ],
            },
            {
              id: 3,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'd. Bebidas alcohólicas más allá de un sorbo',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_4_2_4',
                  value: 'Sí',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_4_2_4',
                  value: 'No',
                },
              ],
            },
            {
              id: 4,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'e. Inhalantes para coger una nota o endrogarte',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_4_2_5',
                  value: 'Sí',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_4_2_5',
                  value: 'No',
                },
              ],
            },
            {
              id: 5,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text:
                    'f. Pastillas para coger una nota (Incluye sedantes, tranquilizantes, xanax, palitroque, percocet, etc.)',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_4_2_6',
                  value: 'Sí',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_4_2_6',
                  value: 'No',
                },
              ],
            },
            {
              id: 6,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'g. Marihuana o hashish fumada',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_4_2_7',
                  value: 'Sí',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_4_2_7',
                  value: 'No',
                },
              ],
            },
            {
              id: 7,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'h. Marihuana sintética',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_4_2_8',
                  value: 'Sí',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_4_2_8',
                  value: 'No',
                },
              ],
            },
            {
              id: 8,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'i. Productos de cannabis medicinal',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_4_2_9',
                  value: 'Sí',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_4_2_9',
                  value: 'No',
                },
              ],
            },
            {
              id: 9,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'j. Cocaína en polvo inhalada',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_4_2_10',
                  value: 'Sí',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_4_2_10',
                  value: 'No',
                },
              ],
            },
            {
              id: 10,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'k. Crack fumado',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_4_2_11',
                  value: 'Sí',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_4_2_11',
                  value: 'No',
                },
              ],
            },
            {
              id: 11,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'l. Heroína',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_4_2_12',
                  value: 'Sí',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_4_2_12',
                  value: 'No',
                },
              ],
            },
            {
              id: 12,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'm. Otras drogas',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_4_2_13',
                  value: 'Sí',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_4_2_13',
                  value: 'No',
                },
              ],
            },
          ],
        },
        {
          id: 'section_4_3',
          type: 'table',
          header: '3. En los últimos 30 días, he usado...',
          subheader: null,
          valid: [
            'section_4_3_1',
            'section_4_3_2',
            'section_4_3_3',
            'section_4_3_4',
            'section_4_3_5',
            'section_4_3_6',
            'section_4_3_7',
            'section_4_3_8',
            'section_4_3_9',
            'section_4_3_10',
            'section_4_3_11',
            'section_4_3_12',
            'section_4_3_13',
          ],
          errors: [
            'section_4_3_1',
            'section_4_3_2',
            'section_4_3_3',
            'section_4_3_4',
            'section_4_3_5',
            'section_4_3_6',
            'section_4_3_7',
            'section_4_3_8',
            'section_4_3_9',
            'section_4_3_10',
            'section_4_3_11',
            'section_4_3_12',
            'section_4_3_13',
          ],
          columns: 1,
          tblHeader: [
            { id: 0, title: '' },
            { id: 1, title: 'Sí' },
            { id: 2, title: 'No' },
          ],
          tblRows: [
            {
              id: 0,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'a. Cigarrillos',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_4_3_1',
                  value: 'Sí',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_4_3_1',
                  value: 'No',
                },
              ],
            },
            {
              id: 1,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'b. Cigarrillo electrónico o vaporizador ("vaper")',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_4_3_2',
                  value: 'Sí',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_4_3_2',
                  value: 'No',
                },
              ],
            },
            {
              id: 2,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'c. Otros productos de tabaco',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_4_3_3',
                  value: 'Sí',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_4_3_3',
                  value: 'No',
                },
              ],
            },
            {
              id: 3,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'd. Bebidas alcohólicas más allá de un sorbo',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_4_3_4',
                  value: 'Sí',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_4_3_4',
                  value: 'No',
                },
              ],
            },
            {
              id: 4,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'e. Inhalantes para coger una nota o endrogarte',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_4_3_5',
                  value: 'Sí',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_4_3_5',
                  value: 'No',
                },
              ],
            },
            {
              id: 5,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text:
                    'f. Pastillas para coger una nota (Incluye sedantes, tranquilizantes, xanax, palitroque, percocet, etc.)',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_4_3_6',
                  value: 'Sí',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_4_3_6',
                  value: 'No',
                },
              ],
            },
            {
              id: 6,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'g. Marihuana o hashish fumada',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_4_3_7',
                  value: 'Sí',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_4_3_7',
                  value: 'No',
                },
              ],
            },
            {
              id: 7,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'h. Marihuana sintética',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_4_3_8',
                  value: 'Sí',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_4_3_8',
                  value: 'No',
                },
              ],
            },
            {
              id: 8,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'i. Productos de cannabis medicinal',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_4_3_9',
                  value: 'Sí',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_4_3_9',
                  value: 'No',
                },
              ],
            },
            {
              id: 9,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'j. Cocaína en polvo inhalada',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_4_3_10',
                  value: 'Sí',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_4_3_10',
                  value: 'No',
                },
              ],
            },
            {
              id: 10,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'k. Crack fumado',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_4_3_11',
                  value: 'Sí',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_4_3_11',
                  value: 'No',
                },
              ],
            },
            {
              id: 11,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'l. Heroína',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_4_3_12',
                  value: 'Sí',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_4_3_12',
                  value: 'No',
                },
              ],
            },
            {
              id: 12,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'm. Otras drogas',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_4_3_13',
                  value: 'Sí',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_4_3_13',
                  value: 'No',
                },
              ],
            },
          ],
        },
        {
          id: 'section_4_4',
          type: 'table',
          header: '4. ¿Qué edad tenías la primera vez que…',
          subheader: null,
          valid: [
            'section_4_4_1',
            'section_4_4_2',
            'section_4_4_3',
            'section_4_4_4',
            'section_4_4_5',
            'section_4_4_6',
            'section_4_4_7',
            'section_4_4_8',
            'section_4_4_9',
            'section_4_4_10',
            'section_4_4_11',
          ],
          errors: [
            'section_4_4_1',
            'section_4_4_2',
            'section_4_4_3',
            'section_4_4_4',
            'section_4_4_5',
            'section_4_4_6',
            'section_4_4_7',
            'section_4_4_8',
            'section_4_4_9',
            'section_4_4_10',
            'section_4_4_11',
          ],
          columns: 1,
          tblHeader: [
            { id: 0, title: '' },
            { id: 1, title: 'Seleccionar Edad' },
          ],
          tblRows: [
            {
              id: 0,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'a. fumaste un cigarrillo o un tabaco completo?',
                },
                {
                  id: 1,
                  type: 'dropdown',
                  name: 'section_4_4_1',
                  options: [
                    { label: 'Nunca he usado', value: 'Nunca he usado' },
                    { label: '8 años o menos', value: '8 años o menos' },
                    { label: '9 años', value: '9 años' },
                    { label: '10 años', value: '10 años' },
                    { label: '11 años', value: '11 años' },
                    { label: '12 años', value: '12 años' },
                    { label: '13 años', value: '13 años' },
                    { label: '14 años', value: '14 años' },
                    { label: '15 años', value: '15 años' },
                    { label: '16 años', value: '16 años' },
                    { label: '17 años', value: '17 años' },
                    { label: '18 0 más', value: '18 0 más' },
                  ],
                },
              ],
            },
            {
              id: 1,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'b. tomaste una bebida alcohólica?',
                },
                {
                  id: 1,
                  type: 'dropdown',
                  name: 'section_4_4_2',
                  options: [
                    { label: 'Nunca he usado', value: 'Nunca he usado' },
                    { label: '8 años o menos', value: '8 años o menos' },
                    { label: '9 años', value: '9 años' },
                    { label: '10 años', value: '10 años' },
                    { label: '11 años', value: '11 años' },
                    { label: '12 años', value: '12 años' },
                    { label: '13 años', value: '13 años' },
                    { label: '14 años', value: '14 años' },
                    { label: '15 años', value: '15 años' },
                    { label: '16 años', value: '16 años' },
                    { label: '17 años', value: '17 años' },
                    { label: '18 0 más', value: '18 0 más' },
                  ],
                },
              ],
            },
            {
              id: 2,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text:
                    'c. oliste inhalantes para coger una nota o endrogarte?',
                },
                {
                  id: 1,
                  type: 'dropdown',
                  name: 'section_4_4_3',
                  options: [
                    { label: 'Nunca he usado', value: 'Nunca he usado' },
                    { label: '8 años o menos', value: '8 años o menos' },
                    { label: '9 años', value: '9 años' },
                    { label: '10 años', value: '10 años' },
                    { label: '11 años', value: '11 años' },
                    { label: '12 años', value: '12 años' },
                    { label: '13 años', value: '13 años' },
                    { label: '14 años', value: '14 años' },
                    { label: '15 años', value: '15 años' },
                    { label: '16 años', value: '16 años' },
                    { label: '17 años', value: '17 años' },
                    { label: '18 0 más', value: '18 0 más' },
                  ],
                },
              ],
            },
            {
              id: 3,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'd. usaste un cigarrillo electrónico o vaporizadores?',
                },
                {
                  id: 1,
                  type: 'dropdown',
                  name: 'section_4_4_4',
                  options: [
                    { label: 'Nunca he usado', value: 'Nunca he usado' },
                    { label: '8 años o menos', value: '8 años o menos' },
                    { label: '9 años', value: '9 años' },
                    { label: '10 años', value: '10 años' },
                    { label: '11 años', value: '11 años' },
                    { label: '12 años', value: '12 años' },
                    { label: '13 años', value: '13 años' },
                    { label: '14 años', value: '14 años' },
                    { label: '15 años', value: '15 años' },
                    { label: '16 años', value: '16 años' },
                    { label: '17 años', value: '17 años' },
                    { label: '18 0 más', value: '18 0 más' },
                  ],
                },
              ],
            },
            {
              id: 4,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'e. tomaste pastillas para coger una nota?',
                },
                {
                  id: 1,
                  type: 'dropdown',
                  name: 'section_4_4_5',
                  options: [
                    { label: 'Nunca he usado', value: 'Nunca he usado' },
                    { label: '8 años o menos', value: '8 años o menos' },
                    { label: '9 años', value: '9 años' },
                    { label: '10 años', value: '10 años' },
                    { label: '11 años', value: '11 años' },
                    { label: '12 años', value: '12 años' },
                    { label: '13 años', value: '13 años' },
                    { label: '14 años', value: '14 años' },
                    { label: '15 años', value: '15 años' },
                    { label: '16 años', value: '16 años' },
                    { label: '17 años', value: '17 años' },
                    { label: '18 0 más', value: '18 0 más' },
                  ],
                },
              ],
            },
            {
              id: 5,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'f. fumaste marihuana?',
                },
                {
                  id: 1,
                  type: 'dropdown',
                  name: 'section_4_4_6',
                  options: [
                    { label: 'Nunca he usado', value: 'Nunca he usado' },
                    { label: '8 años o menos', value: '8 años o menos' },
                    { label: '9 años', value: '9 años' },
                    { label: '10 años', value: '10 años' },
                    { label: '11 años', value: '11 años' },
                    { label: '12 años', value: '12 años' },
                    { label: '13 años', value: '13 años' },
                    { label: '14 años', value: '14 años' },
                    { label: '15 años', value: '15 años' },
                    { label: '16 años', value: '16 años' },
                    { label: '17 años', value: '17 años' },
                    { label: '18 0 más', value: '18 0 más' },
                  ],
                },
              ],
            },
            {
              id: 6,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'g. usaste marihuana sintética?',
                },
                {
                  id: 1,
                  type: 'dropdown',
                  name: 'section_4_4_7',
                  options: [
                    { label: 'Nunca he usado', value: 'Nunca he usado' },
                    { label: '8 años o menos', value: '8 años o menos' },
                    { label: '9 años', value: '9 años' },
                    { label: '10 años', value: '10 años' },
                    { label: '11 años', value: '11 años' },
                    { label: '12 años', value: '12 años' },
                    { label: '13 años', value: '13 años' },
                    { label: '14 años', value: '14 años' },
                    { label: '15 años', value: '15 años' },
                    { label: '16 años', value: '16 años' },
                    { label: '17 años', value: '17 años' },
                    { label: '18 0 más', value: '18 0 más' },
                  ],
                },
              ],
            },
            {
              id: 7,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'h. usaste productos de cannabis medicinal?',
                },
                {
                  id: 1,
                  type: 'dropdown',
                  name: 'section_4_4_8',
                  options: [
                    { label: 'Nunca he usado', value: 'Nunca he usado' },
                    { label: '8 años o menos', value: '8 años o menos' },
                    { label: '9 años', value: '9 años' },
                    { label: '10 años', value: '10 años' },
                    { label: '11 años', value: '11 años' },
                    { label: '12 años', value: '12 años' },
                    { label: '13 años', value: '13 años' },
                    { label: '14 años', value: '14 años' },
                    { label: '15 años', value: '15 años' },
                    { label: '16 años', value: '16 años' },
                    { label: '17 años', value: '17 años' },
                    { label: '18 0 más', value: '18 0 más' },
                  ],
                },
              ],
            },
            {
              id: 8,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'i. inhalaste cocaína en polvo?',
                },
                {
                  id: 1,
                  type: 'dropdown',
                  name: 'section_4_4_9',
                  options: [
                    { label: 'Nunca he usado', value: 'Nunca he usado' },
                    { label: '8 años o menos', value: '8 años o menos' },
                    { label: '9 años', value: '9 años' },
                    { label: '10 años', value: '10 años' },
                    { label: '11 años', value: '11 años' },
                    { label: '12 años', value: '12 años' },
                    { label: '13 años', value: '13 años' },
                    { label: '14 años', value: '14 años' },
                    { label: '15 años', value: '15 años' },
                    { label: '16 años', value: '16 años' },
                    { label: '17 años', value: '17 años' },
                    { label: '18 0 más', value: '18 0 más' },
                  ],
                },
              ],
            },
            {
              id: 9,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'j. fumaste crack?',
                },
                {
                  id: 1,
                  type: 'dropdown',
                  name: 'section_4_4_10',
                  options: [
                    { label: 'Nunca he usado', value: 'Nunca he usado' },
                    { label: '8 años o menos', value: '8 años o menos' },
                    { label: '9 años', value: '9 años' },
                    { label: '10 años', value: '10 años' },
                    { label: '11 años', value: '11 años' },
                    { label: '12 años', value: '12 años' },
                    { label: '13 años', value: '13 años' },
                    { label: '14 años', value: '14 años' },
                    { label: '15 años', value: '15 años' },
                    { label: '16 años', value: '16 años' },
                    { label: '17 años', value: '17 años' },
                    { label: '18 0 más', value: '18 0 más' },
                  ],
                },
              ],
            },
            {
              id: 10,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'k. usaste heroína?',
                },
                {
                  id: 1,
                  type: 'dropdown',
                  name: 'section_4_4_11',
                  options: [
                    { label: 'Nunca he usado', value: 'Nunca he usado' },
                    { label: '8 años o menos', value: '8 años o menos' },
                    { label: '9 años', value: '9 años' },
                    { label: '10 años', value: '10 años' },
                    { label: '11 años', value: '11 años' },
                    { label: '12 años', value: '12 años' },
                    { label: '13 años', value: '13 años' },
                    { label: '14 años', value: '14 años' },
                    { label: '15 años', value: '15 años' },
                    { label: '16 años', value: '16 años' },
                    { label: '17 años', value: '17 años' },
                    { label: '18 0 más', value: '18 0 más' },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: 'section_4_5',
          type: 'checkbox',
          header:
            '5. Marca todos los tipos de tabaco que alguna vez has usado…',
          subheader: 'Puedes marcar más de una respuesta',
          valid: [
            'section_4_5_1',
            'section_4_5_2',
            'section_4_5_3',
            'section_4_5_4',
            'section_4_5_5',
            'section_4_5_6',
          ],
          errors: [
            'section_4_5_1',
            'section_4_5_2',
            'section_4_5_3',
            'section_4_5_4',
            'section_4_5_5',
            'section_4_5_6',
          ],
          columns: 3,
          items: [
            {
              id: 'section_4_5_1',
              type: 'checkbox',
              label: 'Tabaco de mascar',
              name: 'section_4_5_1',
              value: 'Tabaco de mascar',
            },
            {
              id: 'section_4_5_2',
              type: 'checkbox',
              label: 'Cigarros',
              name: 'section_4_5_2',
              value: 'Cigarros',
            },
            {
              id: 'section_4_5_3',
              type: 'checkbox',
              label: 'Algún otro producto de tabaco',
              name: 'section_4_5_3',
              value: 'Algún otro producto de tabaco',
            },
            {
              id: 'section_4_5_4',
              type: 'checkbox',
              label: 'Snuff o dip',
              name: 'section_4_5_4',
              value: 'Snuff o dip',
            },
            {
              id: 'section_4_5_5',
              type: 'checkbox',
              label: 'Cigarros delgados o pequeños',
              name: 'section_4_5_5',
              value: 'Cigarros delgados o pequeños',
            },
            {
              id: 'section_4_5_6',
              type: 'checkbox',
              label: 'Nunca he usado productos de tabaco',
              name: 'section_4_5_6',
              value: 'Nunca he usado productos de tabaco',
            },
          ],
        },
      ],
    },
    {
      id: 5,
      section: 'PAGINA 5',
      questions: [
        {
          id: 'section_4_6',
          type: 'radio',
          header:
            '6. Durante los pasados 30 días, ¿cuántos días fumaste cigarrillos?',
          subheader: null,
          valid: 'section_4_6',
          errors: 'section_4_6',
          columns: 3,
          items: [
            {
              id: 'section_4_6',
              type: 'radio',
              label: 'Ningún día',
              name: 'section_4_6',
              value: 'Ningún día',
            },
            {
              id: 'section_4_6',
              type: 'radio',
              label: '6 a 9 días',
              name: 'section_4_6',
              value: '6 a 9 días',
            },
            {
              id: 'section_4_6',
              type: 'radio',
              label: '20 a 29 días',
              name: 'section_4_6',
              value: '20 a 29 días',
            },
            {
              id: 'section_4_6',
              type: 'radio',
              label: '1 a 2 días',
              name: 'section_4_6',
              value: '1 a 2 días',
            },
            {
              id: 'section_4_6',
              type: 'radio',
              label: '10 a 19 días',
              name: 'section_4_6',
              value: '10 a 19 días',
            },
            {
              id: 'section_4_6',
              type: 'radio',
              label: 'Los 30 días',
              name: 'section_4_6',
              value: 'Los 30 días',
            },
            {
              id: 'section_4_6',
              type: 'radio',
              label: '3 a 5 días',
              name: 'section_4_6',
              value: '3 a 5 días',
            },
          ],
        },
        {
          id: 'section_4_7',
          type: 'radio',
          header:
            '7. La última vez que fuiste a comprar cigarrillos o tabaco, ¿te pidieron identificación?',
          subheader: null,
          valid: 'section_4_7',
          errors: 'section_4_7',
          columns: 1,
          items: [
            {
              id: 'section_4_7',
              type: 'radio',
              label: 'No',
              name: 'section_4_7',
              value: 'No',
            },
            {
              id: 'section_4_7',
              type: 'radio',
              label: 'Sí',
              name: 'section_4_7',
              value: 'Sí',
            },
            {
              id: 'section_4_7',
              type: 'radio',
              label: 'No he tratado de comprar cigarrillos o tabaco',
              name: 'section_4_7',
              value: 'No he tratado de comprar cigarrillos o tabaco',
            },
          ],
        },
        {
          id: 'section_4_8',
          type: 'radio',
          header:
            '8. En los últimos 30 días, ¿alguien se rehusó a venderte cigarrillos o tabaco por tu edad?',
          subheader: null,
          valid: 'section_4_8',
          errors: 'section_4_8',
          columns: 1,
          items: [
            {
              id: 'section_4_8',
              type: 'radio',
              label: 'No',
              name: 'section_4_8',
              value: 'No',
            },
            {
              id: 'section_4_8',
              type: 'radio',
              label: 'Sí',
              name: 'section_4_8',
              value: 'Sí',
            },
            {
              id: 'section_4_8',
              type: 'radio',
              label: 'No he tratado de comprar cigarrillos o tabaco',
              name: 'section_4_8',
              value: 'No he tratado de comprar cigarrillos o tabaco',
            },
          ],
        },
        {
          id: 'section_4_9',
          type: 'radio',
          header:
            '9. En los últimos 30 días, ¿con qué frecuencia has tomado una o varias bebidas alcohólicas?',
          subheader: null,
          valid: 'section_4_9',
          errors: 'section_4_9',
          columns: 1,
          items: [
            {
              id: 'section_4_9',
              type: 'radio',
              label: 'Una sola vez',
              name: 'section_4_9',
              value: 'Una sola vez',
            },
            {
              id: 'section_4_9',
              type: 'radio',
              label: 'De vez en cuando, pero no regularmente',
              name: 'section_4_9',
              value: 'De vez en cuando, pero no regularmente',
            },
            {
              id: 'section_4_9',
              type: 'radio',
              label: 'Sólo en los fines de semana',
              name: 'section_4_9',
              value: 'Sólo en los fines de semana',
            },
            {
              id: 'section_4_9',
              type: 'radio',
              label: 'Varias veces a la semana',
              name: 'section_4_9',
              value: 'Varias veces a la semana',
            },
            {
              id: 'section_4_9',
              type: 'radio',
              label: 'Diariamente',
              name: 'section_4_9',
              value: 'Diariamente',
            },
            {
              id: 'section_4_9',
              type: 'radio',
              label: 'No he tomado bebidas alcohólicas',
              name: 'section_4_9',
              value: 'No he tomado bebidas alcohólicas',
            },
          ],
        },
        {
          id: 'section_4_10',
          type: 'radio',
          header:
            '10. En los últimos 30 días, ¿cuántas veces has tomado 5 o más tragos seguidos de bebidas alcohólicas?',
          subheader: null,
          valid: 'section_4_10',
          errors: 'section_4_10',
          columns: 2,
          items: [
            {
              id: 'section_4_10',
              type: 'radio',
              label: 'Nunca',
              name: 'section_4_10',
              value: 'Nunca',
            },
            {
              id: 'section_4_10',
              type: 'radio',
              label: '2 a 3 veces',
              name: 'section_4_10',
              value: '2 a 3 veces',
            },
            {
              id: 'section_4_10',
              type: 'radio',
              label: '1 vez',
              name: 'section_4_10',
              value: '1 vez',
            },
            {
              id: 'section_4_10',
              type: 'radio',
              label: 'Más de 3 veces',
              name: 'section_4_10',
              value: 'Más de 3 veces',
            },
          ],
        },
        {
          id: 'section_4_11',
          type: 'radio',
          header:
            '11. En los últimos 30 días, ¿cuál es la cantidad máxima de bebidas alcohólicas que has tomado de corrido?',
          subheader: null,
          valid: 'section_4_11',
          errors: 'section_4_11',
          columns: 2,
          items: [
            {
              id: 'section_4_11',
              type: 'radio',
              label: '1 o 2 bebidas',
              name: 'section_4_11',
              value: '1 o 2 bebidas',
            },
            {
              id: 'section_4_11',
              type: 'radio',
              label: '6 o 7 bebidas',
              name: 'section_4_11',
              value: '6 o 7 bebidas',
            },
            {
              id: 'section_4_11',
              type: 'radio',
              label: '3 bebidas',
              name: 'section_4_11',
              value: '3 bebidas',
            },
            {
              id: 'section_4_11',
              type: 'radio',
              label: '8 o 9 bebidas',
              name: 'section_4_11',
              value: '8 o 9 bebidas',
            },
            {
              id: 'section_4_11',
              type: 'radio',
              label: '4 bebidas',
              name: 'section_4_11',
              value: '4 bebidas',
            },
            {
              id: 'section_4_11',
              type: 'radio',
              label: '10 o más bebidas',
              name: 'section_4_11',
              value: '10 o más bebidas',
            },
            {
              id: 'section_4_11',
              type: 'radio',
              label: '5 bebidas',
              name: 'section_4_11',
              value: '5 bebidas',
            },
            {
              id: 'section_4_11',
              type: 'radio',
              label:
                'No he tomado ninguna bebida alcohólica en los últimos 30 días',
              name: 'section_4_11',
              value:
                'No he tomado ninguna bebida alcohólica en los últimos 30 días',
            },
          ],
        },
        {
          id: 'section_4_12',
          type: 'radio',
          header:
            '12. La última vez que fuiste a comprar una bebida alcohólica, ¿te pidieron identificación?',
          subheader: null,
          valid: 'section_4_12',
          errors: 'section_4_12',
          columns: 1,
          items: [
            {
              id: 'section_4_12',
              type: 'radio',
              label: 'No',
              name: 'section_4_12',
              value: 'No',
            },
            {
              id: 'section_4_12',
              type: 'radio',
              label: 'Sí',
              name: 'section_4_12',
              value: 'Sí',
            },
            {
              id: 'section_4_12',
              type: 'radio',
              label: 'No he tratado de comprar cigarrillos o tabaco',
              name: 'section_4_12',
              value: 'No he tratado de comprar cigarrillos o tabaco',
            },
          ],
        },
        {
          id: 'section_4_13',
          type: 'radio',
          header:
            '13. En los últimos 30 días, ¿alguien se rehusó a venderte alguna bebida alcohólica por tu edad?',
          subheader: null,
          valid: 'section_4_13',
          errors: 'section_4_13',
          columns: 1,
          items: [
            {
              id: 'section_4_13',
              type: 'radio',
              label: 'No',
              name: 'section_4_13',
              value: 'No',
            },
            {
              id: 'section_4_13',
              type: 'radio',
              label: 'Sí',
              name: 'section_4_13',
              value: 'Sí',
            },
            {
              id: 'section_4_13',
              type: 'radio',
              label: 'No he tratado de comprar cigarrillos o tabaco',
              name: 'section_4_13',
              value: 'No he tratado de comprar cigarrillos o tabaco',
            },
          ],
        },
        {
          id: 'section_4_14',
          type: 'radio',
          description:
            'Los cigarrillos electrónicos o vaporizadores se usan para inhalar nicotina, cannabis o saborizantes.',
          header:
            '14. Durante los últimos 12 meses, ¿con qué frecuencia has usado cigarrillos electrónicos o vaporizadores ("vapers")?',
          subheader: null,
          valid: 'section_4_14',
          errors: 'section_4_14',
          columns: 2,
          items: [
            {
              id: 'section_4_14',
              type: 'radio',
              label: 'No he fumado',
              name: 'section_4_14',
              value: 'No he fumado',
            },
            {
              id: 'section_4_14',
              type: 'radio',
              label: 'Varias veces a la semana',
              name: 'section_4_14',
              value: 'Varias veces a la semana',
            },
            {
              id: 'section_4_14',
              type: 'radio',
              label: 'Una sola vez',
              name: 'section_4_14',
              value: 'Una sola vez',
            },
            {
              id: 'section_4_14',
              type: 'radio',
              label: 'Sólo en los fines de semana',
              name: 'section_4_14',
              value: 'Sólo en los fines de semana',
            },
            {
              id: 'section_4_14',
              type: 'radio',
              label: 'De vez en cuando, pero no regularmente',
              name: 'section_4_14',
              value: 'De vez en cuando, pero no regularmente',
            },
            {
              id: 'section_4_14',
              type: 'radio',
              label: 'Diariamente',
              name: 'section_4_14',
              value: 'Diariamente',
            },
          ],
        },
      ],
    },
    {
      id: 6,
      section: 'PAGINA 6',
      questions: [
        {
          id: 'section_4_15',
          type: 'checkbox',
          header:
            '15. ¿Qué sustancias has fumado a través de un cigarrillo electrónico o vaporizador ("vaper")?',
          subheader: 'Puedes marcar más de una respuesta',
          valid: [
            'section_4_15_1',
            'section_4_15_2',
            'section_4_15_3',
            'section_4_15_4',
            'section_4_15_5',
            'section_4_15_6',
            'section_4_15_7',
          ],
          errors: [
            'section_4_15_1',
            'section_4_15_2',
            'section_4_15_3',
            'section_4_15_4',
            'section_4_15_5',
            'section_4_15_6',
            'section_4_15_7',
          ],
          columns: 2,
          items: [
            {
              id: 'section_4_15_1',
              type: 'checkbox',
              label: 'Tabaco o nicotina',
              name: 'section_4_15_1',
              value: 'Tabaco o nicotina',
            },
            {
              id: 'section_4_15_2',
              type: 'checkbox',
              label: 'Cannabis medicinal o CBD',
              name: 'section_4_15_2',
              value: 'Cannabis medicinal o CBD',
            },
            {
              id: 'section_4_15_3',
              type: 'checkbox',
              label: 'Marihuana',
              name: 'section_4_15_3',
              value: 'Marihuana',
            },
            {
              id: 'section_4_15_4',
              type: 'checkbox',
              label: 'Solo sabores',
              name: 'section_4_15_4',
              value: 'Solo sabores',
            },
            {
              id: 'section_4_15_5',
              type: 'checkbox',
              label: 'No sé qué substancias tenía',
              name: 'section_4_15_5',
              value: 'No sé qué substancias tenía',
            },
            {
              id: 'section_4_15_6',
              type: 'checkbox',
              label: 'Otras substancias',
              name: 'section_4_15_6',
              value: 'Otras substancias',
            },
            {
              id: 'section_4_15_7',
              type: 'checkbox',
              label: 'Nunca he usado cigarrillos electrónicos o vaporizadores',
              name: 'section_4_15_7',
              value: 'Nunca he usado cigarrillos electrónicos o vaporizadores',
            },
            {
              id: 'section_4_15_8',
              type: 'input',
              label: '',
              name: 'section_4_15_8',
              value: '',
              visible: 'section_4_15_6',
              placeholder: 'Escribir otras substancias...',
            },
          ],
        },
        {
          id: 'section_4_16',
          type: 'checkbox',
          header:
            '16. ¿Qué pastillas no recetadas has usado para coger una nota o endrogarte?',
          subheader: 'Puedes marcar más de una respuesta',
          valid: [
            'section_4_16_1',
            'section_4_16_2',
            'section_4_16_3',
            'section_4_16_4',
            'section_4_16_5',
            'section_4_16_6',
            'section_4_16_7',
            'section_4_16_8',
            'section_4_16_9',
            'section_4_16_10',
            'section_4_16_11',
            'section_4_16_12',
            'section_4_16_13',
            'section_4_16_14',
            'section_4_16_15',
          ],
          errors: [
            'section_4_16_1',
            'section_4_16_2',
            'section_4_16_3',
            'section_4_16_4',
            'section_4_16_5',
            'section_4_16_6',
            'section_4_16_7',
            'section_4_16_8',
            'section_4_16_9',
            'section_4_16_10',
            'section_4_16_11',
            'section_4_16_12',
            'section_4_16_13',
            'section_4_16_14',
            'section_4_16_15',
          ],
          columns: 2,
          items: [
            {
              id: 'section_4_16_1',
              type: 'checkbox',
              label: 'Ninguna',
              name: 'section_4_16_1',
              value: 'Ninguna',
            },
            {
              id: 'section_4_16_2',
              type: 'checkbox',
              label: 'Demerol',
              name: 'section_4_16_2',
              value: 'Demerol',
            },
            {
              id: 'section_4_16_3',
              type: 'checkbox',
              label: 'Klonopin',
              name: 'section_4_16_3',
              value: 'Klonopin',
            },
            {
              id: 'section_4_16_4',
              type: 'checkbox',
              label: 'Darvon',
              name: 'section_4_16_4',
              value: 'Darvon',
            },
            {
              id: 'section_4_16_5',
              type: 'checkbox',
              label: 'Xanax',
              name: 'section_4_16_5',
              value: 'Xanax',
            },
            {
              id: 'section_4_16_6',
              type: 'checkbox',
              label: 'Morfina',
              name: 'section_4_16_6',
              value: 'Morfina',
            },
            {
              id: 'section_4_16_7',
              type: 'checkbox',
              label: 'Vicodin',
              name: 'section_4_16_7',
              value: 'Vicodin',
            },
            {
              id: 'section_4_16_8',
              type: 'checkbox',
              label: 'Codeína',
              name: 'section_4_16_8',
              value: 'Codeína',
            },
            {
              id: 'section_4_16_9',
              type: 'checkbox',
              label: 'Lomotil',
              name: 'section_4_16_9',
              value: 'Lomotil',
            },
            {
              id: 'section_4_16_10',
              type: 'checkbox',
              label: 'Fentanilo (Duragesic)',
              name: 'section_4_16_10',
              value: 'Fentanilo (Duragesic)',
            },
            {
              id: 'section_4_16_11',
              type: 'checkbox',
              label: 'Adderall',
              name: 'section_4_16_11',
              value: 'Adderall',
            },
            {
              id: 'section_4_16_12',
              type: 'checkbox',
              label: 'Percocet, Oxicodona, OxyContin, Percodan',
              name: 'section_4_16_12',
              value: 'Percocet, Oxicodona, OxyContin, Percodan',
            },
            {
              id: 'section_4_16_13',
              type: 'checkbox',
              label: 'Ritalin',
              name: 'section_4_16_13',
              value: 'Ritalin',
            },
            {
              id: 'section_4_16_14',
              type: 'checkbox',
              label: 'Ambien',
              name: 'section_4_16_14',
              value: 'Ambien',
            },
            {
              id: 'section_4_16_15',
              type: 'checkbox',
              label: 'Estas otras:',
              name: 'section_4_16_15',
              value: 'Estas otras:',
            },
            {
              id: 'section_4_16_16',
              type: 'input',
              label: '',
              name: 'section_4_16_16',
              value: '',
              visible: 'section_4_16_15',
              placeholder: 'Escribir otras pastillas...',
            },
          ],
        },
        {
          id: 'section_4_17',
          type: 'checkbox',
          header:
            '17. Marca todos los tipos de marihuana que has usado en los últimos 12 meses.',
          subheader: 'Puedes marcar más de una respuesta',
          valid: [
            'section_4_17_1',
            'section_4_17_2',
            'section_4_17_3',
            'section_4_17_4',
            'section_4_17_5',
            'section_4_17_6',
            'section_4_17_7',
          ],
          errors: [
            'section_4_17_1',
            'section_4_17_2',
            'section_4_17_3',
            'section_4_17_4',
            'section_4_17_5',
            'section_4_17_6',
            'section_4_17_7',
          ],
          columns: 1,
          items: [
            {
              id: 'section_4_17_1',
              type: 'checkbox',
              label:
                'Marihuana (flor, Kripy) fumada en cigarrillos, pipa, o hookah',
              name: 'section_4_17_1',
              value:
                'Marihuana (flor, Kripy) fumada en cigarrillos, pipa, o hookah',
            },
            {
              id: 'section_4_17_2',
              type: 'checkbox',
              label: 'Marihuana sintética (Spice, K2, etc)',
              name: 'section_4_17_2',
              value: 'Marihuana sintética (Spice, K2, etc)',
            },
            {
              id: 'section_4_17_3',
              type: 'checkbox',
              label: 'Marihuana en aceite o wax',
              name: 'section_4_17_3',
              value: 'Marihuana en aceite o wax',
            },
            {
              id: 'section_4_17_4',
              type: 'checkbox',
              label: 'Comestibles de marihuana (galletas o brownies)',
              name: 'section_4_17_4',
              value: 'Comestibles de marihuana (galletas o brownies)',
            },
            {
              id: 'section_4_17_5',
              type: 'checkbox',
              label: 'Hashish',
              name: 'section_4_17_5',
              value: 'Hashish',
            },
            {
              id: 'section_4_17_6',
              type: 'checkbox',
              label: 'Nunca he usado marihuana',
              name: 'section_4_17_6',
              value: 'Nunca he usado marihuana',
            },
            {
              id: 'section_4_17_7',
              type: 'checkbox',
              label: 'Alguna otra forma de marihuana',
              name: 'section_4_17_7',
              value: 'Alguna otra forma de marihuana',
            },
          ],
        },
        {
          id: 'section_4_18',
          type: 'checkbox',
          header:
            '18. ¿Cuáles de estas drogas has usado en los últimos 12 meses para coger una nota o arrebatarte?',
          subheader: 'Puedes marcar más de una respuesta',
          valid: [
            'section_4_18_1',
            'section_4_18_2',
            'section_4_18_3',
            'section_4_18_4',
            'section_4_18_5',
            'section_4_18_6',
            'section_4_18_7',
            'section_4_18_8',
            'section_4_18_9',
            'section_4_18_10',
          ],
          errors: [
            'section_4_18_1',
            'section_4_18_2',
            'section_4_18_3',
            'section_4_18_4',
            'section_4_18_5',
            'section_4_18_6',
            'section_4_18_7',
            'section_4_18_8',
            'section_4_18_9',
            'section_4_18_10',
          ],
          columns: 2,
          items: [
            {
              id: 'section_4_18_1',
              type: 'checkbox',
              label: 'Éxtasis (rola, “X”)',
              name: 'section_4_18_1',
              value: 'Éxtasis (rola, “X”)',
            },
            {
              id: 'section_4_18_2',
              type: 'checkbox',
              label: 'Rohypnol (“Roofies”)',
              name: 'section_4_18_2',
              value: 'Rohypnol (“Roofies”)',
            },
            {
              id: 'section_4_18_3',
              type: 'checkbox',
              label: 'Ketamina (K, ”Special K”)',
              name: 'section_4_18_3',
              value: 'Ketamina (K, ”Special K”)',
            },
            {
              id: 'section_4_18_4',
              type: 'checkbox',
              label: 'Hongos o flor de campana',
              name: 'section_4_18_4',
              value: 'Hongos o flor de campana',
            },
            {
              id: 'section_4_18_5',
              type: 'checkbox',
              label: 'LSD (gota, ácido, otros psicodélicos)',
              name: 'section_4_18_5',
              value: 'LSD (gota, ácido, otros psicodélicos)',
            },
            {
              id: 'section_4_18_6',
              type: 'checkbox',
              label: 'Metanfetaminas (“cristal meth”)',
              name: 'section_4_18_6',
              value: 'Metanfetaminas (“cristal meth”)',
            },
            {
              id: 'section_4_18_7',
              type: 'checkbox',
              label: 'GHB (“G”)',
              name: 'section_4_18_7',
              value: 'GHB (“G”)',
            },
            {
              id: 'section_4_18_8',
              type: 'checkbox',
              label: 'Nunca he usado alguna de éstas',
              name: 'section_4_18_8',
              value: 'Nunca he usado alguna de éstas',
            },
            {
              id: 'section_4_18_9',
              type: 'checkbox',
              label: 'PCP o polvo de ángel',
              name: 'section_4_18_9',
              value: 'PCP o polvo de ángel',
            },
            {
              id: 'section_4_18_10',
              type: 'checkbox',
              label: 'Otra droga que he usado',
              name: 'section_4_18_10',
              value: 'Otra droga que he usado',
            },
            {
              id: 'section_4_18_11',
              type: 'input',
              label: '',
              name: 'section_4_18_11',
              value: '',
              visible: 'section_4_18_10',
              placeholder: 'Escribir otras drogas...',
            },
          ],
        },
        {
          id: 'section_4_19',
          type: 'radio',
          header: '19. Mientras contestabas este cuestionario…',
          subheader: null,
          valid: 'section_4_19',
          errors: 'section_4_19',
          columns: 1,
          items: [
            {
              id: 'section_4_19',
              type: 'radio',
              label: 'Había alguien mirando lo que contestaba',
              name: 'section_4_19',
              value: 'Había alguien mirando lo que contestaba',
            },
            {
              id: 'section_4_19',
              type: 'radio',
              label: 'Había alguien cerca de mi mientras contestaba',
              name: 'section_4_19',
              value: 'Había alguien cerca de mi mientras contestaba',
            },
            {
              id: 'section_4_19',
              type: 'radio',
              label: 'Nadie vio lo que contestaba',
              name: 'section_4_19',
              value: 'Nadie vio lo que contestaba',
            },
          ],
        },
        {
          id: 'section_5_1',
          type: 'table',
          sectionTitle: 'PERSONALIDAD',
          header: '1. Durante los últimos 12 meses, ¿cuán a menudo…',
          subheader: null,
          valid: [
            'section_5_1_1',
            'section_5_1_2',
            'section_5_1_3',
            'section_5_1_4',
            'section_5_1_5',
            'section_5_1_6',
            'section_5_1_7',
          ],
          errors: [
            'section_5_1_1',
            'section_5_1_2',
            'section_5_1_3',
            'section_5_1_4',
            'section_5_1_5',
            'section_5_1_6',
            'section_5_1_7',
          ],
          columns: 1,
          tblHeader: [
            { id: 0, title: '' },
            { id: 1, title: 'Nunca' },
            { id: 2, title: 'A veces' },
            { id: 3, title: 'Siempre' },
          ],
          tblRows: [
            {
              id: 0,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'a. Exigiste mucha atención?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_5_1_1',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_5_1_1',
                  value: 'A veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_5_1_1',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 1,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'b. Destruiste tus cosas?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_5_1_2',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_5_1_2',
                  value: 'A veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_5_1_2',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 2,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'c. Tuviste cambios de humor o sentimientos?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_5_1_3',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_5_1_3',
                  value: 'A veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_5_1_3',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 3,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'd. Fuiste desconfiado(a), receloso(a)?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_5_1_4',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_5_1_4',
                  value: 'A veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_5_1_4',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 4,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'e. Te burlaste mucho de los demás?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_5_1_5',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_5_1_5',
                  value: 'A veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_5_1_5',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 5,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'f. Gritaste mucho?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_5_1_6',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_5_1_6',
                  value: 'A veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_5_1_6',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 6,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'g. Fuiste más ruidoso(a) de lo común?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_5_1_7',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_5_1_7',
                  value: 'A veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_5_1_7',
                  value: 'Siempre',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 7,
      section: 'PAGINA 7',
      questions: [
        {
          id: 'section_5_2',
          type: 'table',
          header: '2. Durante los últimos 12 meses, ¿cuán a menudo…',
          subheader: null,
          valid: [
            'section_5_2_1',
            'section_5_2_2',
            'section_5_2_3',
            'section_5_2_4',
            'section_5_2_5',
            'section_5_2_6',
            'section_5_2_7',
            'section_5_2_8',
            'section_5_2_9',
            'section_5_2_10',
            'section_5_2_11',
            'section_5_2_12',
            'section_5_2_13',
          ],
          errors: [
            'section_5_2_1',
            'section_5_2_2',
            'section_5_2_3',
            'section_5_2_4',
            'section_5_2_5',
            'section_5_2_6',
            'section_5_2_7',
            'section_5_2_8',
            'section_5_2_9',
            'section_5_2_10',
            'section_5_2_11',
            'section_5_2_12',
            'section_5_2_13',
          ],
          columns: 1,
          tblHeader: [
            { id: 0, title: '' },
            { id: 1, title: 'Nunca' },
            { id: 2, title: 'A veces' },
            { id: 3, title: 'Siempre' },
          ],
          tblRows: [
            {
              id: 0,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'a. Disfrutaste de muy pocas cosas?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_5_2_1',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_5_2_1',
                  value: 'A veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_5_2_1',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 1,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'b. Lloraste mucho?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_5_2_2',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_5_2_2',
                  value: 'A veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_5_2_2',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 2,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text:
                    'c. Te hiciste daño deliberadamente o intentaste suicidarte?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_5_2_3',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_5_2_3',
                  value: 'A veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_5_2_3',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 3,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'd. No comiste bien?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_5_2_4',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_5_2_4',
                  value: 'A veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_5_2_4',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 4,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'e. Te sentiste inferior o creíste que no valías nada?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_5_2_5',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_5_2_5',
                  value: 'A veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_5_2_5',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 5,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'f. Te sentiste demasiado culpable?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_5_2_6',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_5_2_6',
                  value: 'A veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_5_2_6',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 6,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text:
                    'g. Te sentiste demasiado cansado sin razón para estarlo?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_5_2_7',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_5_2_7',
                  value: 'A veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_5_2_7',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 7,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'h. Dormiste menos que la mayoría de los(as) jóvenes?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_5_2_8',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_5_2_8',
                  value: 'A veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_5_2_8',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 8,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'i. Dormiste más que la mayoría de los(as) jóvenes?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_5_2_9',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_5_2_9',
                  value: 'A veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_5_2_9',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 9,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'j. Hablaste de querer matarte?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_5_2_10',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_5_2_10',
                  value: 'A veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_5_2_10',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 10,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'k. No dormiste bien?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_5_2_11',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_5_2_11',
                  value: 'A veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_5_2_11',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 11,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text:
                    'l. Fuiste poco activo(a), lento(a) o te faltó energía?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_5_2_12',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_5_2_12',
                  value: 'A veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_5_2_12',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 12,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'm. Te sentiste infeliz, triste o deprimido(a)?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_5_2_13',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_5_2_13',
                  value: 'A veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_5_2_13',
                  value: 'Siempre',
                },
              ],
            },
          ],
        },
        {
          id: 'section_5_3',
          type: 'table',
          header: '3. Durante los últimos 12 meses, ¿cuán a menudo…',
          subheader: null,
          valid: [
            'section_5_3_1',
            'section_5_3_2',
            'section_5_3_3',
            'section_5_3_4',
            'section_5_3_5',
          ],
          errors: [
            'section_5_3_1',
            'section_5_3_2',
            'section_5_3_3',
            'section_5_3_4',
            'section_5_3_5',
          ],
          columns: 1,
          tblHeader: [
            { id: 0, title: '' },
            { id: 1, title: 'Nunca' },
            { id: 2, title: 'A veces' },
            { id: 3, title: 'Siempre' },
          ],
          tblRows: [
            {
              id: 0,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text:
                    'a. Fuiste cruel, abusivo (o abusador) y malo con los demás?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_5_3_1',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_5_3_1',
                  value: 'A veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_5_3_1',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 1,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'b. Te metiste mucho en peleas?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_5_3_2',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_5_3_2',
                  value: 'A veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_5_3_2',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 2,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'c. Atacaste físicamente a la gente?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_5_3_3',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_5_3_3',
                  value: 'A veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_5_3_3',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 3,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'd. Amenazaste a otros?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_5_3_4',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_5_3_4',
                  value: 'A veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_5_3_4',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 4,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text:
                    'e. Destruiste las pertenencias de tus familiares o de otras personas?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_5_3_5',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_5_3_5',
                  value: 'A veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_5_3_5',
                  value: 'Siempre',
                },
              ],
            },
          ],
        },
        {
          id: 'section_5_4',
          type: 'table',
          header: '4. Durante los últimos 12 meses, ¿cuán a menudo…',
          subheader: null,
          valid: [
            'section_5_4_1',
            'section_5_4_2',
            'section_5_4_3',
            'section_5_4_4',
            'section_5_4_5',
          ],
          errors: [
            'section_5_4_1',
            'section_5_4_2',
            'section_5_4_3',
            'section_5_4_4',
            'section_5_4_5',
          ],
          columns: 1,
          tblHeader: [
            { id: 0, title: '' },
            { id: 1, title: 'Nunca' },
            { id: 2, title: 'A veces' },
            { id: 3, title: 'Siempre' },
          ],
          tblRows: [
            {
              id: 0,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'a. Desobedeciste en casa?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_5_4_1',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_5_4_1',
                  value: 'A veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_5_4_1',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 1,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'b. Desobedeciste en la escuela?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_5_4_2',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_5_4_2',
                  value: 'A veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_5_4_2',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 2,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'c. Discutiste mucho?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_5_4_3',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_5_4_3',
                  value: 'A veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_5_4_3',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 3,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'd. Fuiste obstinado(a), malhumorado(a), irritable?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_5_4_4',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_5_4_4',
                  value: 'A veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_5_4_4',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 4,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'e. Te dieron rabietas o tuviste mal genio?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_5_4_5',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_5_4_5',
                  value: 'A veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_5_4_5',
                  value: 'Siempre',
                },
              ],
            },
          ],
        },
        {
          id: 'section_5_5',
          type: 'table',
          header: '5. Durante los últimos 12 meses, ¿cuán a menudo…',
          subheader: null,
          valid: [
            'section_5_5_1',
            'section_5_5_2',
            'section_5_5_3',
            'section_5_5_4',
            'section_5_5_5',
            'section_5_5_6',
          ],
          errors: [
            'section_5_5_1',
            'section_5_5_2',
            'section_5_5_3',
            'section_5_5_4',
            'section_5_5_5',
            'section_5_5_6',
          ],
          columns: 1,
          tblHeader: [
            { id: 0, title: '' },
            { id: 1, title: 'Nunca' },
            { id: 2, title: 'A veces' },
            { id: 3, title: 'Siempre' },
          ],
          tblRows: [
            {
              id: 0,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text:
                    'a. Fuiste demasiado dependiente o apegado(a) a los adultos?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_5_5_1',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_5_5_1',
                  value: 'A veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_5_5_1',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 1,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text:
                    'b. Tuviste miedo de ciertas situaciones, animales o lugares que no sean la escuela?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_5_5_2',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_5_5_2',
                  value: 'A veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_5_5_2',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 2,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'c. Te dio miedo ir a la escuela?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_5_5_3',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_5_5_3',
                  value: 'A veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_5_5_3',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 3,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'd. Estuviste nervioso(a) o tenso(a)?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_5_5_4',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_5_5_4',
                  value: 'A veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_5_5_4',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 4,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'e. Estuviste demasiado ansioso(a) o miedoso(a)?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_5_5_5',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_5_5_5',
                  value: 'A veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_5_5_5',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 5,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'f. Te preocupaste mucho?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_5_5_6',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_5_5_6',
                  value: 'A veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_5_5_6',
                  value: 'Siempre',
                },
              ],
            },
          ],
        },
        {
          id: 'section_5_6',
          type: 'table',
          header: '6. Durante los últimos 12 meses, ¿cuán a menudo…',
          subheader: null,
          valid: [
            'section_5_6_1',
            'section_5_6_2',
            'section_5_6_3',
            'section_5_6_4',
            'section_5_6_5',
            'section_5_6_6',
            'section_5_6_7',
            'section_5_6_8',
            'section_5_6_9',
            'section_5_6_10',
          ],
          errors: [
            'section_5_6_1',
            'section_5_6_2',
            'section_5_6_3',
            'section_5_6_4',
            'section_5_6_5',
            'section_5_6_6',
            'section_5_6_7',
            'section_5_6_8',
            'section_5_6_9',
            'section_5_6_10',
          ],
          columns: 1,
          tblHeader: [
            { id: 0, title: '' },
            { id: 1, title: 'Nunca' },
            { id: 2, title: 'A veces' },
            { id: 3, title: 'Siempre' },
          ],
          tblRows: [
            {
              id: 0,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text:
                    'a. No pareciste sentirte culpable después de portarte mal?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_5_6_1',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_5_6_1',
                  value: 'A veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_5_6_1',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 1,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text:
                    'b. Rompiste las reglas en casa, en la escuela o en otro lugar?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_5_6_2',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_5_6_2',
                  value: 'A veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_5_6_2',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 2,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text:
                    'c. Te juntaste con niños(as)/jóvenes que se meten en problemas?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_5_6_3',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_5_6_3',
                  value: 'A veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_5_6_3',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 3,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'd. Dijiste mentiras o hiciste trampas?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_5_6_4',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_5_6_4',
                  value: 'A veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_5_6_4',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 4,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'e. Te fugaste de la casa?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_5_6_5',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_5_6_5',
                  value: 'A veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_5_6_5',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 5,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'f. Prendiste fuegos?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_5_6_6',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_5_6_6',
                  value: 'A veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_5_6_6',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 6,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'g. Robaste en casa?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_5_6_7',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_5_6_7',
                  value: 'A veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_5_6_7',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 7,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'h. Robaste fuera de casa?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_5_6_8',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_5_6_8',
                  value: 'A veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_5_6_8',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 8,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'i. Dijiste groserías, usaste lenguaje obsceno?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_5_6_9',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_5_6_9',
                  value: 'A veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_5_6_9',
                  value: 'Siempre',
                },
              ],
            },
            {
              id: 9,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'j. Faltaste a la escuela sin motivo?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_5_6_10',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_5_6_10',
                  value: 'A veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_5_6_10',
                  value: 'Siempre',
                },
              ],
            },
          ],
        },
        {
          id: 'section_6_1',
          type: 'table',
          sectionTitle: 'TUS AMIGOS',
          header:
            '1. Piensa en tus mejores amigos, en los últimos 12 meses, ¿cuántos han…',
          subheader: null,
          valid: [
            'section_6_1_1',
            'section_6_1_2',
            'section_6_1_3',
            'section_6_1_4',
          ],
          errors: [
            'section_6_1_1',
            'section_6_1_2',
            'section_6_1_3',
            'section_6_1_4',
          ],
          columns: 1,
          tblHeader: [
            { id: 0, title: '' },
            { id: 1, title: 'Ninguno' },
            { id: 2, title: 'Alguno' },
            { id: 3, title: 'La mayoría' },
            { id: 4, title: 'Todos' },
          ],
          tblRows: [
            {
              id: 0,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'a. fumado cigarrillos?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_6_1_1',
                  value: 'Ninguno',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_6_1_1',
                  value: 'Alguno',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_6_1_1',
                  value: 'La mayoría',
                },
                {
                  id: 4,
                  type: 'radio',
                  name: 'section_6_1_1',
                  value: 'Todos',
                },
              ],
            },
            {
              id: 1,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'b. tomado bebidas alcohólicas?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_6_1_2',
                  value: 'Ninguno',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_6_1_2',
                  value: 'Alguno',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_6_1_2',
                  value: 'La mayoría',
                },
                {
                  id: 4,
                  type: 'radio',
                  name: 'section_6_1_2',
                  value: 'Todos',
                },
              ],
            },
            {
              id: 2,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'c. usado marihuana, kripy o hashish?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_6_1_3',
                  value: 'Ninguno',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_6_1_3',
                  value: 'Alguno',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_6_1_3',
                  value: 'La mayoría',
                },
                {
                  id: 4,
                  type: 'radio',
                  name: 'section_6_1_3',
                  value: 'Todos',
                },
              ],
            },
            {
              id: 3,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'd. usado cocaína, heroína, crack u otras drogas?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_6_1_4',
                  value: 'Ninguno',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_6_1_4',
                  value: 'Alguno',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_6_1_4',
                  value: 'La mayoría',
                },
                {
                  id: 4,
                  type: 'radio',
                  name: 'section_6_1_4',
                  value: 'Todos',
                },
              ],
            },
          ],
        },
        {
          id: 'section_7_1',
          type: 'table',
          sectionTitle: 'TUS OPINIONES',
          header: '1. Aunque no las uses, ¿cuán fácil se te haría conseguir...',
          subheader: null,
          valid: [
            'section_7_1_1',
            'section_7_1_2',
            'section_7_1_3',
            'section_7_1_4',
          ],
          errors: [
            'section_7_1_1',
            'section_7_1_2',
            'section_7_1_3',
            'section_7_1_4',
          ],
          columns: 1,
          tblHeader: [
            { id: 0, title: '' },
            { id: 1, title: 'Muy difícil' },
            { id: 2, title: 'Un poco difícil' },
            { id: 3, title: 'Un poco fácil' },
            { id: 4, title: 'Muy fácil' },
          ],
          tblRows: [
            {
              id: 0,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'a. cigarrillos?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_7_1_1',
                  value: 'Muy difícil',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_7_1_1',
                  value: 'Un poco difícil',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_7_1_1',
                  value: 'Un poco fácil',
                },
                {
                  id: 4,
                  type: 'radio',
                  name: 'section_7_1_1',
                  value: 'Muy fácil',
                },
              ],
            },
            {
              id: 1,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'b. bebidas alcohólicas?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_7_1_2',
                  value: 'Muy difícil',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_7_1_2',
                  value: 'Un poco difícil',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_7_1_2',
                  value: 'Un poco fácil',
                },
                {
                  id: 4,
                  type: 'radio',
                  name: 'section_7_1_2',
                  value: 'Muy fácil',
                },
              ],
            },
            {
              id: 2,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'c. marihuana o hashish?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_7_1_3',
                  value: 'Muy difícil',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_7_1_3',
                  value: 'Un poco difícil',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_7_1_3',
                  value: 'Un poco fácil',
                },
                {
                  id: 4,
                  type: 'radio',
                  name: 'section_7_1_3',
                  value: 'Muy fácil',
                },
              ],
            },
            {
              id: 3,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'd. drogas como cocaína, heroína o crack?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_7_1_4',
                  value: 'Muy difícil',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_7_1_4',
                  value: 'Un poco difícil',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_7_1_4',
                  value: 'Un poco fácil',
                },
                {
                  id: 4,
                  type: 'radio',
                  name: 'section_7_1_4',
                  value: 'Muy fácil',
                },
              ],
            },
          ],
        },
        {
          id: 'section_7_2',
          type: 'table',
          header:
            '2. En tu opinión, ¿cúal es el riesgo de hacerse daño fisicamente o de otra manera al…',
          subheader: null,
          valid: [
            'section_7_2_1',
            'section_7_2_2',
            'section_7_2_3',
            'section_7_2_4',
            'section_7_2_5',
            'section_7_2_6',
            'section_7_2_7',
          ],
          errors: [
            'section_7_2_1',
            'section_7_2_2',
            'section_7_2_3',
            'section_7_2_4',
            'section_7_2_5',
            'section_7_2_6',
            'section_7_2_7',
          ],
          columns: 1,
          tblHeader: [
            { id: 0, title: '' },
            { id: 1, title: 'Ningún riesgo' },
            { id: 2, title: 'Algún riesgo' },
            { id: 3, title: 'Riesgo moderado' },
            { id: 4, title: 'Mucho riesgo' },
          ],
          tblRows: [
            {
              id: 0,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text:
                    'a.  tomar cinco o más tragos con alcohol de corrido una o dos veces a la semana?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_7_2_1',
                  value: 'Ningún riesgo',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_7_2_1',
                  value: 'Algún riesgo',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_7_2_1',
                  value: 'Riesgo moderado',
                },
                {
                  id: 4,
                  type: 'radio',
                  name: 'section_7_2_1',
                  value: 'Mucho riesgo',
                },
              ],
            },
            {
              id: 1,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'b.  fumar una o más cajetillas de cigarrillos al día?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_7_2_2',
                  value: 'Ningún riesgo',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_7_2_2',
                  value: 'Algún riesgo',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_7_2_2',
                  value: 'Riesgo moderado',
                },
                {
                  id: 4,
                  type: 'radio',
                  name: 'section_7_2_2',
                  value: 'Mucho riesgo',
                },
              ],
            },
            {
              id: 2,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'c.  fumar marihuana 1 o 2 veces a la semana?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_7_2_3',
                  value: 'Ningún riesgo',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_7_2_3',
                  value: 'Algún riesgo',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_7_2_3',
                  value: 'Riesgo moderado',
                },
                {
                  id: 4,
                  type: 'radio',
                  name: 'section_7_2_3',
                  value: 'Mucho riesgo',
                },
              ],
            },
            {
              id: 3,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'd.  usar pastillas que no te han sido recetadas?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_7_2_4',
                  value: 'Ningún riesgo',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_7_2_4',
                  value: 'Algún riesgo',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_7_2_4',
                  value: 'Riesgo moderado',
                },
                {
                  id: 4,
                  type: 'radio',
                  name: 'section_7_2_4',
                  value: 'Mucho riesgo',
                },
              ],
            },
            {
              id: 4,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text:
                    'e.  tomar uno o dos tragos con alcohol casi todos los días?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_7_2_5',
                  value: 'Ningún riesgo',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_7_2_5',
                  value: 'Algún riesgo',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_7_2_5',
                  value: 'Riesgo moderado',
                },
                {
                  id: 4,
                  type: 'radio',
                  name: 'section_7_2_5',
                  value: 'Mucho riesgo',
                },
              ],
            },
            {
              id: 5,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'f.  probar marihuana una o dos veces?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_7_2_6',
                  value: 'Ningún riesgo',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_7_2_6',
                  value: 'Algún riesgo',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_7_2_6',
                  value: 'Riesgo moderado',
                },
                {
                  id: 4,
                  type: 'radio',
                  name: 'section_7_2_6',
                  value: 'Mucho riesgo',
                },
              ],
            },
            {
              id: 6,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'g.  fumar marihuana por lo menos una vez al mes?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_7_2_7',
                  value: 'Ningún riesgo',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_7_2_7',
                  value: 'Algún riesgo',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_7_2_7',
                  value: 'Riesgo moderado',
                },
                {
                  id: 4,
                  type: 'radio',
                  name: 'section_7_2_7',
                  value: 'Mucho riesgo',
                },
              ],
            },
          ],
        },
        {
          id: 'section_7_3',
          type: 'table',
          header: '3. Para ti, ¿cuán malo es que alguien de tu edad…',
          subheader: null,
          valid: [
            'section_7_3_1',
            'section_7_3_2',
            'section_7_3_3',
            'section_7_3_4',
          ],
          errors: [
            'section_7_3_1',
            'section_7_3_2',
            'section_7_3_3',
            'section_7_3_4',
          ],
          columns: 1,
          tblHeader: [
            { id: 0, title: '' },
            { id: 1, title: 'Muy malo' },
            { id: 2, title: 'Malo' },
            { id: 3, title: 'Un poco malo' },
            { id: 4, title: 'Nada malo' },
          ],
          tblRows: [
            {
              id: 0,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'a. fume uno o más paquetes de cigarrillos?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_7_3_1',
                  value: 'Muy malo',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_7_3_1',
                  value: 'Malo',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_7_3_1',
                  value: 'Un poco malo',
                },
                {
                  id: 4,
                  type: 'radio',
                  name: 'section_7_3_1',
                  value: 'Nada malo',
                },
              ],
            },
            {
              id: 1,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text:
                    'b. tome una o dos bebidas alcohólicas casi todos los días?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_7_3_2',
                  value: 'Muy malo',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_7_3_2',
                  value: 'Malo',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_7_3_2',
                  value: 'Un poco malo',
                },
                {
                  id: 4,
                  type: 'radio',
                  name: 'section_7_3_2',
                  value: 'Nada malo',
                },
              ],
            },
            {
              id: 2,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'c. use cocaína, heroína u otras drogas?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_7_3_3',
                  value: 'Muy malo',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_7_3_3',
                  value: 'Malo',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_7_3_3',
                  value: 'Un poco malo',
                },
                {
                  id: 4,
                  type: 'radio',
                  name: 'section_7_3_3',
                  value: 'Nada malo',
                },
              ],
            },
            {
              id: 3,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'd. prueba marihuana, kripy o hashish una o dos veces?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_7_3_4',
                  value: 'Muy malo',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_7_3_4',
                  value: 'Malo',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_7_3_4',
                  value: 'Un poco malo',
                },
                {
                  id: 4,
                  type: 'radio',
                  name: 'section_7_3_4',
                  value: 'Nada malo',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 8,
      section: 'PAGINA 8',
      questions: [
        {
          id: 'section_8_1',
          type: 'table',
          sectionTitle: 'ACOSO',
          description:
            'Las siguientes preguntas se refieren al acoso o "bullying".  Acoso es cuando otra persona te amenaza, te insulta, te escribe palabras ofensivas, te saca del grupo, te pega o te ataca.',
          header: '1. ¿Fuiste acosado o abusado ("bullying") por…',
          subheader: null,
          valid: [
            'section_8_1_1',
            'section_8_1_2',
            'section_8_1_3',
            'section_8_1_4',
            'section_8_1_5',
            'section_8_1_6',
            'section_8_1_7',
            'section_8_1_8',
            'section_8_1_9',
          ],
          errors: [
            'section_8_1_1',
            'section_8_1_2',
            'section_8_1_3',
            'section_8_1_4',
            'section_8_1_5',
            'section_8_1_6',
            'section_8_1_7',
            'section_8_1_8',
            'section_8_1_9',
          ],
          columns: 1,
          tblHeader: [
            { id: 0, title: '' },
            { id: 1, title: 'Nunca' },
            { id: 2, title: 'Sí, en los últimos 12 meses' },
            { id: 3, title: 'Sí, hace más de un año' },
          ],
          tblRows: [
            {
              id: 0,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'Tu peso',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_8_1_1',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_8_1_1',
                  value: 'Sí, en los últimos 12 meses',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_8_1_1',
                  value: 'Sí, hace más de un año',
                },
              ],
            },
            {
              id: 1,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'Tu apariencia física',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_8_1_2',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_8_1_2',
                  value: 'Sí, en los últimos 12 meses',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_8_1_2',
                  value: 'Sí, hace más de un año',
                },
              ],
            },
            {
              id: 2,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'Ser estudioso(a) o estofón',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_8_1_3',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_8_1_3',
                  value: 'Sí, en los últimos 12 meses',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_8_1_3',
                  value: 'Sí, hace más de un año',
                },
              ],
            },
            {
              id: 3,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'Porque piensan que eres amanerado, afeminado',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_8_1_4',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_8_1_4',
                  value: 'Sí, en los últimos 12 meses',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_8_1_4',
                  value: 'Sí, hace más de un año',
                },
              ],
            },
            {
              id: 4,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'Porque piensan que eres machúa, bucha o tomboy',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_8_1_5',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_8_1_5',
                  value: 'Sí, en los últimos 12 meses',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_8_1_5',
                  value: 'Sí, hace más de un año',
                },
              ],
            },
            {
              id: 5,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'Porque piensan que eres gay, homosexual o lesbiana',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_8_1_6',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_8_1_6',
                  value: 'Sí, en los últimos 12 meses',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_8_1_6',
                  value: 'Sí, hace más de un año',
                },
              ],
            },
            {
              id: 6,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'Tus creencias religiosas',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_8_1_7',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_8_1_7',
                  value: 'Sí, en los últimos 12 meses',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_8_1_7',
                  value: 'Sí, hace más de un año',
                },
              ],
            },
            {
              id: 7,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'Ser talentoso(a) en arte, música o deportes',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_8_1_8',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_8_1_8',
                  value: 'Sí, en los últimos 12 meses',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_8_1_8',
                  value: 'Sí, hace más de un año',
                },
              ],
            },
            {
              id: 8,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'Otras razones',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_8_1_9',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_8_1_9',
                  value: 'Sí, en los últimos 12 meses',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_8_1_9',
                  value: 'Sí, hace más de un año',
                },
              ],
            },
          ],
        },
        {
          id: 'section_8_2',
          type: 'table',
          header: '2. Durante los últimos 12 meses…',
          subheader: null,
          valid: [
            'section_8_2_1',
            'section_8_2_2',
            'section_8_2_3',
            'section_8_2_4',
            'section_8_2_5',
            'section_8_2_6',
            'section_8_2_7',
            'section_8_2_8',
            'section_8_2_9',
          ],
          errors: [
            'section_8_2_1',
            'section_8_2_2',
            'section_8_2_3',
            'section_8_2_4',
            'section_8_2_5',
            'section_8_2_6',
            'section_8_2_7',
            'section_8_2_8',
            'section_8_2_9',
          ],
          columns: 1,
          tblHeader: [
            { id: 0, title: '' },
            { id: 1, title: 'No' },
            { id: 2, title: 'Sí' },
          ],
          tblRows: [
            {
              id: 0,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'a. ¿Te han tocado de manera sexual sin tú quererlo?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_8_2_1',
                  value: 'No',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_8_2_1',
                  value: 'Sí',
                },
              ],
            },
            {
              id: 1,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'b. ¿Has salido con alguien que te pegó o abusó de ti?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_8_2_2',
                  value: 'No',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_8_2_2',
                  value: 'Sí',
                },
              ],
            },
            {
              id: 2,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text:
                    'c. ¿Te han obligado a tener relaciones sexuales sin tú quererlo?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_8_2_3',
                  value: 'No',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_8_2_3',
                  value: 'Sí',
                },
              ],
            },
            {
              id: 3,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text:
                    'd. ¿Has sido acosado, abusado o "bullied" por estudiantes o alguien de tu escuela?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_8_2_4',
                  value: 'No',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_8_2_4',
                  value: 'Sí',
                },
              ],
            },
            {
              id: 4,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text:
                    'e. ¿Tu novio, novia o pareja te ha pegado, abofeteado o ha tratado de hacerte daño físico?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_8_2_5',
                  value: 'No',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_8_2_5',
                  value: 'Sí',
                },
              ],
            },
            {
              id: 5,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text:
                    'f. ¿Te han acosado, se han burlado de ti o te han insultado por internet o por mensajes de texto?  ',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_8_2_6',
                  value: 'No',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_8_2_6',
                  value: 'Sí',
                },
              ],
            },
            {
              id: 6,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text:
                    'g. ¿Has dejado de ir a la escuela porque no te sentías seguro en la escuela o en el camino a la escuela?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_8_2_7',
                  value: 'No',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_8_2_7',
                  value: 'Sí',
                },
              ],
            },
            {
              id: 7,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text:
                    'h. ¿Alguien te dio tan fuerte que te dejó marcado o te hirió?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_8_2_8',
                  value: 'No',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_8_2_8',
                  value: 'Sí',
                },
              ],
            },
            {
              id: 8,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text:
                    'i. ¿Has acosado, insultado, amenazado o abusado de alguien (hecho "bullying"")?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_8_2_9',
                  value: 'No',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_8_2_9',
                  value: 'Sí',
                },
              ],
            },
          ],
        },
        {
          id: 'section_9_1',
          type: 'table',
          sectionTitle: 'TU VECINDARIO',
          header: '1. ¿Cuán seguro(a) te sientes ...',
          subheader: null,
          valid: ['section_9_1_1', 'section_9_1_2', 'section_9_1_3'],
          errors: ['section_9_1_1', 'section_9_1_2', 'section_9_1_3'],
          columns: 1,
          tblHeader: [
            { id: 0, title: '' },
            { id: 1, title: 'Nada' },
            { id: 2, title: 'Poco' },
            { id: 3, title: 'Mucho' },
          ],
          tblRows: [
            {
              id: 0,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'a. en la escuela?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_9_1_1',
                  value: 'Nada',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_9_1_1',
                  value: 'Poco',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_9_1_1',
                  value: 'Mucho',
                },
              ],
            },
            {
              id: 1,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'b. en tu casa?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_9_1_2',
                  value: 'Nada',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_9_1_2',
                  value: 'Poco',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_9_1_2',
                  value: 'Mucho',
                },
              ],
            },
            {
              id: 2,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'c. en tu vecindario?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_9_1_3',
                  value: 'Nada',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_9_1_3',
                  value: 'Poco',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_9_1_3',
                  value: 'Mucho',
                },
              ],
            },
          ],
        },
        {
          id: 'section_10_1',
          type: 'radio',
          sectionTitle: 'SEXUALIDAD',
          header:
            '1. ¿Qué edad tenías cuando tuviste relaciones sexuales por primera vez?',
          subheader: null,
          valid: 'section_10_1',
          errors: 'section_10_1',
          columns: 3,
          items: [
            {
              id: 'section_10_1',
              type: 'radio',
              label: '10 años o menos',
              name: 'section_10_1',
              value: '10 años o menos',
            },
            {
              id: 'section_10_1',
              type: 'radio',
              label: '13 años',
              name: 'section_10_1',
              value: '13 años',
            },
            {
              id: 'section_10_1',
              type: 'radio',
              label: '16 años',
              name: 'section_10_1',
              value: '16 años',
            },
            {
              id: 'section_10_1',
              type: 'radio',
              label: '11 años',
              name: 'section_10_1',
              value: '11 años',
            },
            {
              id: 'section_10_1',
              type: 'radio',
              label: '14 años',
              name: 'section_10_1',
              value: '14 años',
            },
            {
              id: 'section_10_1',
              type: 'radio',
              label: '17 años o más',
              name: 'section_10_1',
              value: '17 años o más',
            },
            {
              id: 'section_10_1',
              type: 'radio',
              label: '12 años',
              name: 'section_10_1',
              value: '12 años',
            },
            {
              id: 'section_10_1',
              type: 'radio',
              label: '15 años',
              name: 'section_10_1',
              value: '15 años',
            },
            {
              id: 'section_10_1',
              type: 'radio',
              label: 'Nunca he tenido relaciones sexuales',
              name: 'section_10_1',
              value: 'Nunca he tenido relaciones sexuales',
            },
          ],
        },
        {
          id: 'section_10_2',
          type: 'radio',
          header:
            '2. Durante tu vida, ¿con cuántas personas has tenido relaciones sexuales?',
          subheader: null,
          valid: 'section_10_2',
          errors: 'section_10_2',
          columns: 3,
          items: [
            {
              id: 'section_10_2',
              type: 'radio',
              label: '1 persona',
              name: 'section_10_2',
              value: '1 persona',
            },
            {
              id: 'section_10_2',
              type: 'radio',
              label: '3-4 personas',
              name: 'section_10_2',
              value: '3-4 personas',
            },
            {
              id: 'section_10_2',
              type: 'radio',
              label: 'Nunca he tenido relaciones sexuales',
              name: 'section_10_2',
              value: 'Nunca he tenido relaciones sexuales',
            },
            {
              id: 'section_10_2',
              type: 'radio',
              label: '2 personas',
              name: 'section_10_2',
              value: '2 personas',
            },
            {
              id: 'section_10_2',
              type: 'radio',
              label: '5 o más personas',
              name: 'section_10_2',
              value: '5 o más personas',
            },
          ],
        },
        {
          id: 'section_10_3',
          type: 'checkbox',
          header:
            '3. La última vez que tuviste relaciones sexuales, ¿qué método(s) usaron para evitar el embarazo?',
          subheader: 'Puedes marcar más de una respuesta',
          valid: [
            'section_10_3_1',
            'section_10_3_2',
            'section_10_3_3',
            'section_10_3_4',
            'section_10_3_5',
            'section_10_3_6',
            'section_10_3_7',
            'section_10_3_8',
            'section_10_3_9',
            'section_10_3_10',
            'section_10_3_11',
            'section_10_3_12',
          ],
          errors: [
            'section_10_3_1',
            'section_10_3_2',
            'section_10_3_3',
            'section_10_3_4',
            'section_10_3_5',
            'section_10_3_6',
            'section_10_3_7',
            'section_10_3_8',
            'section_10_3_9',
            'section_10_3_10',
            'section_10_3_11',
            'section_10_3_12',
          ],
          columns: 2,
          items: [
            {
              id: 'section_10_3_1',
              type: 'checkbox',
              label: 'Nunca he tenido relaciones',
              name: 'section_10_3_1',
              value: 'Nunca he tenido relaciones',
            },
            {
              id: 'section_10_3_2',
              type: 'checkbox',
              label: 'No usamos ningún método',
              name: 'section_10_3_2',
              value: 'No usamos ningún método',
            },
            {
              id: 'section_10_3_3',
              type: 'checkbox',
              label: 'Pastillas anticonceptivas',
              name: 'section_10_3_3',
              value: 'Pastillas anticonceptivas',
            },
            {
              id: 'section_10_3_4',
              type: 'checkbox',
              label: 'Condones',
              name: 'section_10_3_4',
              value: 'Condones',
            },
            {
              id: 'section_10_3_5',
              type: 'checkbox',
              label: 'Inyecciones hormonales (Depoprovera)',
              name: 'section_10_3_5',
              value: 'Inyecciones hormonales (Depoprovera)',
            },
            {
              id: 'section_10_3_6',
              type: 'checkbox',
              label: 'Implantes hormonales',
              name: 'section_10_3_6',
              value: 'Implantes hormonales',
            },
            {
              id: 'section_10_3_7',
              type: 'checkbox',
              label: 'Parcho hormonal',
              name: 'section_10_3_7',
              value: 'Parcho hormonal',
            },
            {
              id: 'section_10_3_8',
              type: 'checkbox',
              label: 'Anillo vaginal',
              name: 'section_10_3_8',
              value: 'Anillo vaginal',
            },
            {
              id: 'section_10_3_9',
              type: 'checkbox',
              label: 'Dispositivo intrauterino',
              name: 'section_10_3_9',
              value: 'Dispositivo intrauterino',
            },
            {
              id: 'section_10_3_10',
              type: 'checkbox',
              label: 'La pastilla del día siguiente',
              name: 'section_10_3_10',
              value: 'La pastilla del día siguiente',
            },
            {
              id: 'section_10_3_11',
              type: 'checkbox',
              label: 'Separarse antes del orgasmo',
              name: 'section_10_3_11',
              value: 'Separarse antes del orgasmo',
            },
            {
              id: 'section_10_3_12',
              type: 'checkbox',
              label: 'Otro método anticonceptivo',
              name: 'section_10_3_12',
              value: 'Otro método anticonceptivo',
            },
          ],
        },
        {
          id: 'section_10_4',
          type: 'radio',
          header:
            '4. La última vez que tuviste relaciones sexuales, ¿tomaste alcohol o usaste drogas antes o durante la relación sexual?',
          subheader: null,
          valid: 'section_10_4',
          errors: 'section_10_4',
          columns: 3,
          items: [
            {
              id: 'section_10_4',
              type: 'radio',
              label: 'Nunca he tenido relaciones',
              name: 'section_10_4',
              value: 'Nunca he tenido relaciones',
            },
            {
              id: 'section_10_4',
              type: 'radio',
              label: 'Solamente tomé alcohol',
              name: 'section_10_4',
              value: 'Solamente tomé alcohol',
            },
            {
              id: 'section_10_4',
              type: 'radio',
              label: 'Tomé alcohol y usé drogas',
              name: 'section_10_4',
              value: 'Tomé alcohol y usé drogas',
            },
            {
              id: 'section_10_4',
              type: 'radio',
              label: 'No tomé alcohol o usé drogas',
              name: 'section_10_4',
              value: 'No tomé alcohol o usé drogas',
            },
            {
              id: 'section_10_4',
              type: 'radio',
              label: 'Solamente usé drogas',
              name: 'section_10_4',
              value: 'Solamente usé drogas',
            },
          ],
        },
        {
          id: 'section_10_5',
          type: 'checkbox',
          header:
            '5. Si fueras a tener relaciones sexuales, ¿con quién preferirías hacerlo?',
          subheader: 'Puedes marcar más de una respuesta',
          valid: [
            'section_10_5_1',
            'section_10_5_2',
            'section_10_5_3',
            'section_10_5_4',
            'section_10_5_5',
          ],
          errors: [
            'section_10_5_1',
            'section_10_5_2',
            'section_10_5_3',
            'section_10_5_4',
            'section_10_5_5',
          ],
          columns: 3,
          items: [
            {
              id: 'section_10_5_1',
              type: 'checkbox',
              label: 'Con chicas de tu edad',
              name: 'section_10_5_1',
              value: 'Con chicas de tu edad',
            },
            {
              id: 'section_10_5_2',
              type: 'checkbox',
              label: 'Con chicos de tu edad',
              name: 'section_10_5_2',
              value: 'Con chicos de tu edad',
            },
            {
              id: 'section_10_5_3',
              type: 'checkbox',
              label: 'No sé',
              name: 'section_10_5_3',
              value: 'No sé',
            },
            {
              id: 'section_10_5_4',
              type: 'checkbox',
              label: 'Con mujeres mayores',
              name: 'section_10_5_4',
              value: 'Con mujeres mayores',
            },
            {
              id: 'section_10_5_5',
              type: 'checkbox',
              label: 'Con hombres mayores',
              name: 'section_10_5_5',
              value: 'Con hombres mayores',
            },
          ],
        },
      ],
    },
    {
      id: 9,
      section: 'PAGINA 9',
      questions: [
        {
          id: 'section_10_6',
          type: 'checkbox',
          header: '6. ¿Por qué razones no tendrías relaciones sexuales?',
          subheader: 'Puedes marcar más de una respuesta',
          valid: [
            'section_10_6_1',
            'section_10_6_2',
            'section_10_6_3',
            'section_10_6_4',
            'section_10_6_5',
            'section_10_6_6',
          ],
          errors: [
            'section_10_6_1',
            'section_10_6_2',
            'section_10_6_3',
            'section_10_6_4',
            'section_10_6_5',
            'section_10_6_6',
          ],
          columns: 3,
          items: [
            {
              id: 'section_10_6_1',
              type: 'checkbox',
              label: 'Va en contra de mis creencias',
              name: 'section_10_6_1',
              value: 'Va en contra de mis creencias',
            },
            {
              id: 'section_10_6_2',
              type: 'checkbox',
              label: 'Soy demasiado joven',
              name: 'section_10_6_2',
              value: 'Soy demasiado joven',
            },
            {
              id: 'section_10_6_3',
              type: 'checkbox',
              label: 'Me sentiría culpable',
              name: 'section_10_6_3',
              value: 'Me sentiría culpable',
            },
            {
              id: 'section_10_6_4',
              type: 'checkbox',
              label: 'Me podría contagiar',
              name: 'section_10_6_4',
              value: 'Me podría contagiar',
            },
            {
              id: 'section_10_6_5',
              type: 'checkbox',
              label: 'Deseo evitar embarazos',
              name: 'section_10_6_5',
              value: 'Deseo evitar embarazos',
            },
            {
              id: 'section_10_6_6',
              type: 'checkbox',
              label: 'Otras razones',
              name: 'section_10_6_6',
              value: 'Otras razones',
            },
          ],
        },
        {
          id: 'section_10_7',
          type: 'radio',
          header: '7. Durante tu vida, ¿con quién has tenido contacto sexual?',
          subheader: null,
          valid: 'section_10_7',
          errors: 'section_10_7',
          columns: 2,
          items: [
            {
              id: 'section_10_7',
              type: 'radio',
              label: 'Nunca he tenido contacto sexual',
              name: 'section_10_7',
              value: 'Nunca he tenido contacto sexual',
            },
            {
              id: 'section_10_7',
              type: 'radio',
              label: 'Mujeres',
              name: 'section_10_7',
              value: 'Mujeres',
            },
            {
              id: 'section_10_7',
              type: 'radio',
              label: 'Varones',
              name: 'section_10_7',
              value: 'Varones',
            },
            {
              id: 'section_10_7',
              type: 'radio',
              label: 'Mujeres y varones',
              name: 'section_10_7',
              value: 'Mujeres y varones',
            },
          ],
        },
        {
          id: 'section_10_8',
          type: 'radio',
          header: '8. ¿Cuál de las siguientes opciones te describe mejor?',
          subheader: null,
          valid: 'section_10_8',
          errors: 'section_10_8',
          columns: 1,
          items: [
            {
              id: 'section_10_8',
              type: 'radio',
              label: 'Heterosexual',
              name: 'section_10_8',
              value: 'Heterosexual',
            },
            {
              id: 'section_10_8',
              type: 'radio',
              label: 'Homosexual (gay) o lesbiana',
              name: 'section_10_8',
              value: 'Homosexual (gay) o lesbiana',
            },
            {
              id: 'section_10_8',
              type: 'radio',
              label: 'Bisexual',
              name: 'section_10_8',
              value: 'Bisexual',
            },
            {
              id: 'section_10_8',
              type: 'radio',
              label: 'Describo mi identidad sexual de alguna otra manera',
              name: 'section_10_8',
              value: 'Describo mi identidad sexual de alguna otra manera',
            },
            {
              id: 'section_10_8',
              type: 'radio',
              label:
                'No estoy seguro/a de mi identidad sexual (me lo estoy preguntando)',
              name: 'section_10_8',
              value:
                'No estoy seguro/a de mi identidad sexual (me lo estoy preguntando)',
            },
            {
              id: 'section_10_8',
              type: 'radio',
              label: 'No entiendo qué se me está preguntando',
              name: 'section_10_8',
              value: 'No entiendo qué se me está preguntando',
            },
          ],
        },
        {
          id: 'section_11_1',
          type: 'radio',
          sectionTitle: 'TU SALUD',
          header: '1. ¿Cómo describes tu salud la mayor parte del tiempo?',
          subheader: null,
          valid: 'section_11_1',
          errors: 'section_11_1',
          columns: 2,
          items: [
            {
              id: 'section_11_1',
              type: 'radio',
              label: 'Pobre',
              name: 'section_11_1',
              value: 'Pobre',
            },
            {
              id: 'section_11_1',
              type: 'radio',
              label: 'Buena',
              name: 'section_11_1',
              value: 'Buena',
            },
            {
              id: 'section_11_1',
              type: 'radio',
              label: 'Regular',
              name: 'section_11_1',
              value: 'Regular',
            },
            {
              id: 'section_11_1',
              type: 'radio',
              label: 'Muy buena',
              name: 'section_11_1',
              value: 'Muy buena',
            },
          ],
        },
        {
          id: 'section_11_2',
          type: 'radio',
          header:
            '2. ¿Cuándo fue la última vez que usaste esteroides anabólicos?',
          subheader: null,
          valid: 'section_11_2',
          errors: 'section_11_2',
          columns: 3,
          items: [
            {
              id: 'section_11_2',
              type: 'radio',
              label: 'Nunca he usado esteroides',
              name: 'section_11_2',
              value: 'Nunca he usado esteroides',
            },
            {
              id: 'section_11_2',
              type: 'radio',
              label: 'En los pasados 12 meses',
              name: 'section_11_2',
              value: 'En los pasados 12 meses',
            },
            {
              id: 'section_11_2',
              type: 'radio',
              label: 'No sé lo que son esteroides',
              name: 'section_11_2',
              value: 'No sé lo que son esteroides',
            },
            {
              id: 'section_11_2',
              type: 'radio',
              label: 'Hace ya más de un año',
              name: 'section_11_2',
              value: 'Hace ya más de un año',
            },
            {
              id: 'section_11_2',
              type: 'radio',
              label: 'En los últimos 30 días',
              name: 'section_11_2',
              value: 'En los últimos 30 días',
            },
          ],
        },
        {
          id: 'section_11_3',
          type: 'radio',
          description:
            'Algunas veces las personas pueden considerar el suicidio, es decir, hacer algo para terminar con su vida.',
          header: '3. ¿Has pensado seriamente suicidarte?',
          subheader: null,
          valid: 'section_11_3',
          errors: 'section_11_3',
          columns: 3,
          items: [
            {
              id: 'section_11_3',
              type: 'radio',
              label: 'Nunca',
              name: 'section_11_3',
              value: 'Nunca',
            },
            {
              id: 'section_11_3',
              type: 'radio',
              label: 'Sí, hace ya más de un año',
              name: 'section_11_3',
              value: 'Sí, hace ya más de un año',
            },
            {
              id: 'section_11_3',
              type: 'radio',
              label: 'Sí, en los pasados 12 meses',
              name: 'section_11_3',
              value: 'Sí, en los pasados 12 meses',
            },
          ],
        },
        {
          id: 'section_11_4',
          type: 'radio',
          header: '4. ¿Has tratado de suicidarte?',
          subheader: null,
          valid: 'section_11_4',
          errors: 'section_11_4',
          columns: 3,
          items: [
            {
              id: 'section_11_4',
              type: 'radio',
              label: 'Nunca',
              name: 'section_11_4',
              value: 'Nunca',
            },
            {
              id: 'section_11_4',
              type: 'radio',
              label: 'Sí, hace ya más de un año',
              name: 'section_11_4',
              value: 'Sí, hace ya más de un año',
            },
            {
              id: 'section_11_4',
              type: 'radio',
              label: 'Sí, en los pasados 12 meses',
              name: 'section_11_4',
              value: 'Sí, en los pasados 12 meses',
            },
          ],
        },
        {
          id: 'section_11_5',
          type: 'radio',
          header:
            '5. En los últimos 12 meses, ¿cuántas veces has tratado de suicidarte?',
          subheader: null,
          valid: 'section_11_5',
          errors: 'section_11_5',
          columns: 3,
          items: [
            {
              id: 'section_11_5',
              type: 'radio',
              label: 'Nunca',
              name: 'section_11_5',
              value: 'Nunca',
            },
            {
              id: 'section_11_5',
              type: 'radio',
              label: '2 o 3 veces',
              name: 'section_11_5',
              value: '2 o 3 veces',
            },
            {
              id: 'section_11_5',
              type: 'radio',
              label: '6 veces o más',
              name: 'section_11_5',
              value: '6 veces o más',
            },
            {
              id: 'section_11_5',
              type: 'radio',
              label: 'Una vez',
              name: 'section_11_5',
              value: 'Una vez',
            },
            {
              id: 'section_11_5',
              type: 'radio',
              label: '4 o 5 veces',
              name: 'section_11_5',
              value: '4 o 5 veces',
            },
          ],
        },
        {
          id: 'section_11_6',
          type: 'radio',
          header: '6. ¿Cuánto pesas? Escribe tu peso en libras.',
          subheader: null,
          valid: 'section_11_6',
          errors: 'section_11_6',
          columns: 1,
          items: [
            {
              id: 'section_11_6',
              type: 'input',
              name: 'section_11_6',
              value: '',
              label: 'Escribe tu peso en libras o No sé si desconoces tu peso.',
              placeholder: 'Ejemplo: 125',
              alwaysShow: true,
            },
          ],
        },
        {
          id: 'section_11_7',
          type: 'radio',
          header: '7. ¿Cuánto mides? Escribe tu estatura en pies y pulgadas.',
          subheader: null,
          valid: 'section_11_7',
          errors: 'section_11_7',
          columns: 1,
          items: [
            {
              id: 'section_11_7',
              type: 'input',
              name: 'section_11_7',
              value: 'No sé',
              label:
                'Escribe tu eststura en pies y pulgadas o No sé si desconoces tu estatura.',
              placeholder: 'Ejemplo: 5\'4"',
              alwaysShow: true,
            },
          ],
        },
      ],
    },
    {
      id: 10,
      section: 'PAGINA 10',
      questions: [
        {
          id: 'section_11_8',
          type: 'table',
          header:
            '8. En los últimos 12 meses, ¿con cuanta frecuencia has ido al…',
          subheader: null,
          valid: [
            'section_11_8_1',
            'section_11_8_2',
            'section_11_8_3',
            'section_11_8_4',
            'section_11_8_5',
            'section_11_8_6',
            'section_11_8_7',
          ],
          errors: [
            'section_11_8_1',
            'section_11_8_2',
            'section_11_8_3',
            'section_11_8_4',
            'section_11_8_5',
            'section_11_8_6',
            'section_11_8_7',
          ],
          columns: 1,
          tblHeader: [
            { id: 0, title: '' },
            { id: 1, title: 'Nunca' },
            { id: 2, title: 'Sólo 1 o 2 veces' },
            { id: 3, title: 'Más de 2 veces' },
          ],
          tblRows: [
            {
              id: 0,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'a. médico?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_11_8_1',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_11_8_1',
                  value: 'Sólo 1 o 2 veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_11_8_1',
                  value: 'Más de 2 veces',
                },
              ],
            },
            {
              id: 1,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'b. dentista?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_11_8_2',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_11_8_2',
                  value: 'Sólo 1 o 2 veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_11_8_2',
                  value: 'Más de 2 veces',
                },
              ],
            },
            {
              id: 2,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'c. psicólogo?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_11_8_3',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_11_8_3',
                  value: 'Sólo 1 o 2 veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_11_8_3',
                  value: 'Más de 2 veces',
                },
              ],
            },
            {
              id: 3,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'd. psiquiatra?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_11_8_4',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_11_8_4',
                  value: 'Sólo 1 o 2 veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_11_8_4',
                  value: 'Más de 2 veces',
                },
              ],
            },
            {
              id: 4,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'e. consejero o trabajador social?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_11_8_5',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_11_8_5',
                  value: 'Sólo 1 o 2 veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_11_8_5',
                  value: 'Más de 2 veces',
                },
              ],
            },
            {
              id: 5,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'f. sala de emergencia?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_11_8_6',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_11_8_6',
                  value: 'Sólo 1 o 2 veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_11_8_6',
                  value: 'Más de 2 veces',
                },
              ],
            },
            {
              id: 6,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'g. sido hospitalizado?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_11_8_7',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_11_8_7',
                  value: 'Sólo 1 o 2 veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_11_8_7',
                  value: 'Más de 2 veces',
                },
              ],
            },
          ],
        },
        {
          id: 'section_11_9',
          type: 'table',
          header:
            '9. En los últimos 12 meses, ¿con cuanta frecuencia has recibido atención por…',
          subheader: null,
          valid: [
            'section_11_9_1',
            'section_11_9_2',
            'section_11_9_3',
            'section_11_9_4',
            'section_11_9_5',
            'section_11_9_6',
            'section_11_9_7',
          ],
          errors: [
            'section_11_9_1',
            'section_11_9_2',
            'section_11_9_3',
            'section_11_9_4',
            'section_11_9_5',
            'section_11_9_6',
            'section_11_9_7',
          ],
          columns: 1,
          tblHeader: [
            { id: 0, title: '' },
            { id: 1, title: 'Nunca' },
            { id: 2, title: 'Sólo 1 o 2 veces' },
            { id: 3, title: 'Más de 2 veces' },
          ],
          tblRows: [
            {
              id: 0,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'a. usar drogas?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_11_9_1',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_11_9_1',
                  value: 'Sólo 1 o 2 veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_11_9_1',
                  value: 'Más de 2 veces',
                },
              ],
            },
            {
              id: 1,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'b. intentar suicidarte?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_11_9_2',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_11_9_2',
                  value: 'Sólo 1 o 2 veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_11_9_2',
                  value: 'Más de 2 veces',
                },
              ],
            },
            {
              id: 2,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'c. problemas mentales?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_11_9_3',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_11_9_3',
                  value: 'Sólo 1 o 2 veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_11_9_3',
                  value: 'Más de 2 veces',
                },
              ],
            },
            {
              id: 3,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'd. sentirte triste o deprimido?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_11_9_4',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_11_9_4',
                  value: 'Sólo 1 o 2 veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_11_9_4',
                  value: 'Más de 2 veces',
                },
              ],
            },
            {
              id: 4,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'e. tener problemas de comportamiento?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_11_9_5',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_11_9_5',
                  value: 'Sólo 1 o 2 veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_11_9_5',
                  value: 'Más de 2 veces',
                },
              ],
            },
            {
              id: 5,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'f. problemas de alimentación?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_11_9_6',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_11_9_6',
                  value: 'Sólo 1 o 2 veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_11_9_6',
                  value: 'Más de 2 veces',
                },
              ],
            },
            {
              id: 6,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'g. problemas físicos o enfermedades?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_11_9_7',
                  value: 'Nunca',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_11_9_7',
                  value: 'Sólo 1 o 2 veces',
                },
                {
                  id: 3,
                  type: 'radio',
                  name: 'section_11_9_7',
                  value: 'Más de 2 veces',
                },
              ],
            },
          ],
        },
        {
          id: 'section_12_1',
          type: 'checkbox',
          sectionTitle: 'TUS EXPERIENCIAS',
          header:
            '1. En los últimos 12 meses, ¿cuáles de las siguientes situaciones has vivido?',
          subheader: 'Puedes marcar más de una respuesta',
          valid: [
            'section_12_1_1',
            'section_12_1_2',
            'section_12_1_3',
            'section_12_1_4',
            'section_12_1_5',
            'section_12_1_6',
            'section_12_1_7',
            'section_12_1_8',
            'section_12_1_9',
            'section_12_1_10',
            'section_12_1_11',
            'section_12_1_12',
            'section_12_1_13',
            'section_12_1_14',
            'section_12_1_15',
            'section_12_1_16',
            'section_12_1_17',
            'section_12_1_18',
          ],
          errors: [
            'section_12_1_1',
            'section_12_1_2',
            'section_12_1_3',
            'section_12_1_4',
            'section_12_1_5',
            'section_12_1_6',
            'section_12_1_7',
            'section_12_1_8',
            'section_12_1_9',
            'section_12_1_10',
            'section_12_1_11',
            'section_12_1_12',
            'section_12_1_13',
            'section_12_1_14',
            'section_12_1_15',
            'section_12_1_16',
            'section_12_1_17',
            'section_12_1_18',
          ],
          columns: 1,
          items: [
            {
              id: 'section_12_1_1',
              type: 'checkbox',
              label: 'Te cambiaste de escuela',
              name: 'section_12_1_1',
              value: 'Te cambiaste de escuela',
            },
            {
              id: 'section_12_1_2',
              type: 'checkbox',
              label: 'Tuviste una enfermedad o accidente serio',
              name: 'section_12_1_2',
              value: 'Tuviste una enfermedad o accidente serio',
            },
            {
              id: 'section_12_1_3',
              type: 'checkbox',
              label: 'Tus papás se separaron o divorciaron',
              name: 'section_12_1_3',
              value: 'Tus papás se separaron o divorciaron',
            },
            {
              id: 'section_12_1_4',
              type: 'checkbox',
              label: 'Alguien nuevo llegó a vivir a tu casa',
              name: 'section_12_1_4',
              value: 'Alguien nuevo llegó a vivir a tu casa',
            },
            {
              id: 'section_12_1_5',
              type: 'checkbox',
              label: 'Alguien se mudó o se fue de tu casa',
              name: 'section_12_1_5',
              value: 'Alguien se mudó o se fue de tu casa',
            },
            {
              id: 'section_12_1_6',
              type: 'checkbox',
              label: 'Murió algún familiar, amigo íntimo o persona cercana',
              name: 'section_12_1_6',
              value: 'Murió algún familiar, amigo íntimo o persona cercana',
            },
            {
              id: 'section_12_1_7',
              type: 'checkbox',
              label: 'Hubo una enfermedad o accidente serio en la familia',
              name: 'section_12_1_7',
              value: 'Hubo una enfermedad o accidente serio en la familia',
            },
            {
              id: 'section_12_1_8',
              type: 'checkbox',
              label: 'Dejaste a tu novio(a), jevo(a) o compañero(a)',
              name: 'section_12_1_8',
              value: 'Dejaste a tu novio(a), jevo(a) o compañero(a)',
            },
            {
              id: 'section_12_1_9',
              type: 'checkbox',
              label:
                'Tuviste problemas con la ley, policía o fuiste a la corte',
              name: 'section_12_1_9',
              value:
                'Tuviste problemas con la ley, policía o fuiste a la corte',
            },
            {
              id: 'section_12_1_10',
              type: 'checkbox',
              label:
                'Algún familiar cercano tuvo que mudarse fuera de Puerto Rico',
              name: 'section_12_1_10',
              value:
                'Algún familiar cercano tuvo que mudarse fuera de Puerto Rico',
            },
            {
              id: 'section_12_1_11',
              type: 'checkbox',
              label: 'Saliste embarazada o embarazaste a alguien',
              name: 'section_12_1_11',
              value: 'Saliste embarazada o embarazaste a alguien',
            },
            {
              id: 'section_12_1_12',
              type: 'checkbox',
              label: 'Alguien que vive en tu familia perdió el trabajo',
              name: 'section_12_1_12',
              value: 'Alguien que vive en tu familia perdió el trabajo',
            },
            {
              id: 'section_12_1_13',
              type: 'checkbox',
              label:
                'Tuviste que mudarte temporera o permanentemente de tu casa',
              name: 'section_12_1_13',
              value:
                'Tuviste que mudarte temporera o permanentemente de tu casa',
            },
            {
              id: 'section_12_1_14',
              type: 'checkbox',
              label: 'Tu casa sufrió daños por los terremotos del 2020',
              name: 'section_12_1_14',
              value: 'Tu casa sufrió daños por los terremotos del 2020',
            },
            {
              id: 'section_12_1_15',
              type: 'checkbox',
              label:
                'Tu vida o la de tu familia estuvo en peligro por los terremotos',
              name: 'section_12_1_15',
              value:
                'Tu vida o la de tu familia estuvo en peligro por los terremotos',
            },
            {
              id: 'section_12_1_16',
              type: 'checkbox',
              label: 'Pasaste hambre',
              name: 'section_12_1_16',
              value: 'Pasaste hambre',
            },
            {
              id: 'section_12_1_17',
              type: 'checkbox',
              label: 'No había suficientes cosas para comer en tu casa',
              name: 'section_12_1_17',
              value: 'No había suficientes cosas para comer en tu casa',
            },
            {
              id: 'section_12_1_18',
              type: 'checkbox',
              label: 'Tuviste cualquier otro problema serio',
              name: 'section_12_1_18',
              value: 'Tuviste cualquier otro problema serio',
            },
          ],
        },
        {
          id: 'section_13_1',
          type: 'table',
          sectionTitle: 'PREVENCION',
          header:
            '1. Durante los últimos 12 meses, ¿has participado en tu escuela en alguna actividad en la que se exhorta a los jóvenes a no usar…',
          subheader: null,
          valid: [
            'section_13_1_1',
            'section_13_1_2',
            'section_13_1_3',
            'section_13_1_4',
            'section_13_1_5',
            'section_13_1_6',
            'section_13_1_7',
          ],
          errors: [
            'section_13_1_1',
            'section_13_1_2',
            'section_13_1_3',
            'section_13_1_4',
            'section_13_1_5',
            'section_13_1_6',
            'section_13_1_7',
          ],
          columns: 1,
          tblHeader: [
            { id: 0, title: '' },
            { id: 1, title: 'No' },
            { id: 2, title: 'Sí' },
          ],
          tblRows: [
            {
              id: 0,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'a. cigarrillos o productos de tabaco?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_13_1_1',
                  value: 'No',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_13_1_1',
                  value: 'Sí',
                },
              ],
            },
            {
              id: 1,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'b. cigarrillos electrónicos o vaporizadores?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_13_1_2',
                  value: 'No',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_13_1_2',
                  value: 'Sí',
                },
              ],
            },
            {
              id: 2,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'c. bebidas alcohólicas?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_13_1_3',
                  value: 'No',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_13_1_3',
                  value: 'Sí',
                },
              ],
            },
            {
              id: 3,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text:
                    'd. bebidas alcohólicas mientras guían un vehículo de motor?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_13_1_4',
                  value: 'No',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_13_1_4',
                  value: 'Sí',
                },
              ],
            },
            {
              id: 4,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'e. pastillas no recetadas para coger una nota?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_13_1_5',
                  value: 'No',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_13_1_5',
                  value: 'Sí',
                },
              ],
            },
            {
              id: 5,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'f. marihuana?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_13_1_6',
                  value: 'No',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_13_1_6',
                  value: 'Sí',
                },
              ],
            },
            {
              id: 6,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'g. drogas como cocaína, heroína o crack?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_13_1_7',
                  value: 'No',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_13_1_7',
                  value: 'Sí',
                },
              ],
            },
          ],
        },
        {
          id: 'section_13_2',
          type: 'table',
          header:
            '2. Durante los últimos 12 meses, ¿has participado fuera de la escuela en alguna actividad en la que se exhorta a los jóvenes a no usar…',
          subheader: null,
          valid: [
            'section_13_2_1',
            'section_13_2_2',
            'section_13_2_3',
            'section_13_2_4',
            'section_13_2_5',
            'section_13_2_6',
            'section_13_2_7',
          ],
          errors: [
            'section_13_2_1',
            'section_13_2_2',
            'section_13_2_3',
            'section_13_2_4',
            'section_13_2_5',
            'section_13_2_6',
            'section_13_2_7',
          ],
          columns: 1,
          tblHeader: [
            { id: 0, title: '' },
            { id: 1, title: 'No' },
            { id: 2, title: 'Sí' },
          ],
          tblRows: [
            {
              id: 0,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'a. cigarrillos o productos de tabaco?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_13_2_1',
                  value: 'No',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_13_2_1',
                  value: 'Sí',
                },
              ],
            },
            {
              id: 1,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'b. cigarrillos electrónicos o vaporizadores?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_13_2_2',
                  value: 'No',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_13_2_2',
                  value: 'Sí',
                },
              ],
            },
            {
              id: 2,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'c. bebidas alcohólicas?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_13_2_3',
                  value: 'No',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_13_2_3',
                  value: 'Sí',
                },
              ],
            },
            {
              id: 3,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text:
                    'd. bebidas alcohólicas mientras guían un vehículo de motor?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_13_2_4',
                  value: 'No',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_13_2_4',
                  value: 'Sí',
                },
              ],
            },
            {
              id: 4,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'e. pastillas no recetadas para coger una nota?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_13_2_5',
                  value: 'No',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_13_2_5',
                  value: 'Sí',
                },
              ],
            },
            {
              id: 5,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'f. marihuana?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_13_2_6',
                  value: 'No',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_13_2_6',
                  value: 'Sí',
                },
              ],
            },
            {
              id: 6,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'g. drogas como cocaína, heroína o crack?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_13_2_7',
                  value: 'No',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_13_2_7',
                  value: 'Sí',
                },
              ],
            },
          ],
        },
        {
          id: 'section_13_3',
          type: 'table',
          header:
            '3. Durante los últimos 12 meses, ¿has visto o escuchado por televisión, internet, radio o revistas mensajes que promuevan el no usar…',
          subheader: null,
          valid: [
            'section_13_3_1',
            'section_13_3_2',
            'section_13_3_3',
            'section_13_3_4',
            'section_13_3_5',
            'section_13_3_6',
            'section_13_3_7',
          ],
          errors: [
            'section_13_3_1',
            'section_13_3_2',
            'section_13_3_3',
            'section_13_3_4',
            'section_13_3_5',
            'section_13_3_6',
            'section_13_3_7',
          ],
          columns: 1,
          tblHeader: [
            { id: 0, title: '' },
            { id: 1, title: 'No' },
            { id: 2, title: 'Sí' },
          ],
          tblRows: [
            {
              id: 0,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'a. cigarrillos o productos de tabaco?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_13_3_1',
                  value: 'No',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_13_3_1',
                  value: 'Sí',
                },
              ],
            },
            {
              id: 1,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'b. cigarrillos electrónicos o vaporizadores?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_13_3_2',
                  value: 'No',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_13_3_2',
                  value: 'Sí',
                },
              ],
            },
            {
              id: 2,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'c. bebidas alcohólicas?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_13_3_3',
                  value: 'No',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_13_3_3',
                  value: 'Sí',
                },
              ],
            },
            {
              id: 3,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text:
                    'd. bebidas alcohólicas mientras guían un vehículo de motor?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_13_3_4',
                  value: 'No',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_13_3_4',
                  value: 'Sí',
                },
              ],
            },
            {
              id: 4,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'e. pastillas no recetadas para coger una nota?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_13_3_5',
                  value: 'No',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_13_3_5',
                  value: 'Sí',
                },
              ],
            },
            {
              id: 5,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'f. marihuana?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_13_3_6',
                  value: 'No',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_13_3_6',
                  value: 'Sí',
                },
              ],
            },
            {
              id: 6,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'g. drogas como cocaína, heroína o crack?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_13_3_7',
                  value: 'No',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_13_3_7',
                  value: 'Sí',
                },
              ],
            },
          ],
        },
        {
          id: 'section_13_4',
          type: 'table',
          header:
            '4. Durante los últimos 12 meses, ¿tus padres o tutores te han hablado sobre los peligros de usar…',
          subheader: null,
          valid: [
            'section_13_4_1',
            'section_13_4_2',
            'section_13_4_3',
            'section_13_4_4',
            'section_13_4_5',
            'section_13_4_6',
            'section_13_4_7',
          ],
          errors: [
            'section_13_4_1',
            'section_13_4_2',
            'section_13_4_3',
            'section_13_4_4',
            'section_13_4_5',
            'section_13_4_6',
            'section_13_4_7',
          ],
          columns: 1,
          tblHeader: [
            { id: 0, title: '' },
            { id: 1, title: 'No' },
            { id: 2, title: 'Sí' },
          ],
          tblRows: [
            {
              id: 0,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'a. cigarrillos o productos de tabaco?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_13_4_1',
                  value: 'No',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_13_4_1',
                  value: 'Sí',
                },
              ],
            },
            {
              id: 1,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'b. cigarrillos electrónicos o vaporizadores?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_13_4_2',
                  value: 'No',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_13_4_2',
                  value: 'Sí',
                },
              ],
            },
            {
              id: 2,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'c. bebidas alcohólicas?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_13_4_3',
                  value: 'No',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_13_4_3',
                  value: 'Sí',
                },
              ],
            },
            {
              id: 3,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text:
                    'd. bebidas alcohólicas mientras guían un vehículo de motor?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_13_4_4',
                  value: 'No',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_13_4_4',
                  value: 'Sí',
                },
              ],
            },
            {
              id: 4,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'e. pastillas no recetadas para coger una nota?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_13_4_5',
                  value: 'No',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_13_4_5',
                  value: 'Sí',
                },
              ],
            },
            {
              id: 5,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'f. marihuana?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_13_4_6',
                  value: 'No',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_13_4_6',
                  value: 'Sí',
                },
              ],
            },
            {
              id: 6,
              columns: [
                {
                  id: 0,
                  type: 'question',
                  text: 'g. drogas como cocaína, heroína o crack?',
                },
                {
                  id: 1,
                  type: 'radio',
                  name: 'section_13_4_7',
                  value: 'No',
                },
                {
                  id: 2,
                  type: 'radio',
                  name: 'section_13_4_7',
                  value: 'Sí',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 11,
      section: 'PAGINA 11',
      questions: [
        {
          id: 'section_14_1',
          type: 'radio',
          sectionTitle: 'COVID-19',
          header: '1. ¿Cuál de las pruebas de COVID-19 te has hecho?',
          subheader: null,
          valid: 'section_14_1',
          errors: 'section_14_1',
          columns: 2,
          items: [
            {
              id: 'section_14_1',
              type: 'radio',
              label: 'No me he hecho ninguna',
              name: 'section_14_1',
              value: 'No me he hecho ninguna',
            },
            {
              id: 'section_14_1',
              type: 'radio',
              label: 'Serológica (sangre)',
              name: 'section_14_1',
              value: 'Serológica (sangre)',
            },
            {
              id: 'section_14_1',
              type: 'radio',
              label: 'Molecular (la nariz o boca)',
              name: 'section_14_1',
              value: 'Molecular (la nariz o boca)',
            },
            {
              id: 'section_14_1',
              type: 'radio',
              label: 'Me hice ambas',
              name: 'section_14_1',
              value: 'Me hice ambas',
            },
          ],
        },
        {
          id: 'section_14_2',
          type: 'checkbox',
          header:
            '2. ¿Quiénes en tu casa han tenido un resultado positivo de COVID-19?',
          subheader: 'Puedes marcar más de una respuesta',
          valid: [
            'section_14_2_1',
            'section_14_2_2',
            'section_14_2_3',
            'section_14_2_4',
            'section_14_2_5',
          ],
          errors: [
            'section_14_2_1',
            'section_14_2_2',
            'section_14_2_3',
            'section_14_2_4',
            'section_14_2_5',
          ],
          columns: 3,
          items: [
            {
              id: 'section_14_2_1',
              type: 'checkbox',
              label: 'Nadie ha salido positivo',
              name: 'section_14_2_1',
              value: 'Nadie ha salido positivo',
            },
            {
              id: 'section_14_2_2',
              type: 'checkbox',
              label: 'Mi mamá o papá',
              name: 'section_14_2_2',
              value: 'Mi mamá o papá',
            },
            {
              id: 'section_14_2_3',
              type: 'checkbox',
              label: 'Otro persona que vive en mi casa',
              name: 'section_14_2_3',
              value: 'Otro persona que vive en mi casa',
            },
            {
              id: 'section_14_2_4',
              type: 'checkbox',
              label: 'Yo',
              name: 'section_14_2_4',
              value: 'Yo',
            },
            {
              id: 'section_14_2_5',
              type: 'checkbox',
              label: 'Alguno de mis hermanos',
              name: 'section_14_2_5',
              value: 'Alguno de mis hermanos',
            },
          ],
        },
        {
          id: 'section_14_3',
          type: 'checkbox',
          header:
            '3. ¿Algún familiar, amigo íntimo o persona cercana a ti ha sido hospitalizado o ha muerto por COVID-19?',
          subheader: 'Puedes marcar más de una respuesta',
          valid: ['section_14_3_1', 'section_14_3_2', 'section_14_3_3'],
          errors: ['section_14_3_1', 'section_14_3_2', 'section_14_3_3'],
          columns: 3,
          items: [
            {
              id: 'section_14_3_1',
              type: 'checkbox',
              label: 'No, nadie',
              name: 'section_14_3_1',
              value: 'No, nadie',
            },
            {
              id: 'section_14_3_2',
              type: 'checkbox',
              label: 'Sí, hospitalizado',
              name: 'section_14_3_2',
              value: 'Sí, hospitalizado',
            },
            {
              id: 'section_14_3_3',
              type: 'checkbox',
              label: 'Sí, muerto',
              name: 'section_14_3_3',
              value: 'Sí, muerto',
            },
          ],
        },
        {
          id: 'section_14_4',
          type: 'checkbox',
          header:
            '4. Marca todos los sentimientos que has tenido durante la pandemia de COVID-19.',
          subheader: 'Puedes marcar más de una respuesta',
          valid: [
            'section_14_4_1',
            'section_14_4_2',
            'section_14_4_3',
            'section_14_4_4',
            'section_14_4_5',
            'section_14_4_6',
            'section_14_4_7',
            'section_14_4_8',
            'section_14_4_9',
            'section_14_4_10',
            'section_14_4_11',
          ],
          errors: [
            'section_14_4_1',
            'section_14_4_2',
            'section_14_4_3',
            'section_14_4_4',
            'section_14_4_5',
            'section_14_4_6',
            'section_14_4_7',
            'section_14_4_8',
            'section_14_4_9',
            'section_14_4_10',
            'section_14_4_11',
          ],
          columns: 3,
          items: [
            {
              id: 'section_14_4_1',
              type: 'checkbox',
              label: 'Deprimido',
              name: 'section_14_4_1',
              value: 'Deprimido',
            },
            {
              id: 'section_14_4_2',
              type: 'checkbox',
              label: 'Nervioso',
              name: 'section_14_4_2',
              value: 'Nervioso',
            },
            {
              id: 'section_14_4_3',
              type: 'checkbox',
              label: 'Feliz',
              name: 'section_14_4_3',
              value: 'Feliz',
            },
            {
              id: 'section_14_4_4',
              type: 'checkbox',
              label: 'Con coraje',
              name: 'section_14_4_4',
              value: 'Con coraje',
            },
            {
              id: 'section_14_4_5',
              type: 'checkbox',
              label: 'Con miedo',
              name: 'section_14_4_5',
              value: 'Con miedo',
            },
            {
              id: 'section_14_4_6',
              type: 'checkbox',
              label: 'Asustado',
              name: 'section_14_4_6',
              value: 'Asustado',
            },
            {
              id: 'section_14_4_7',
              type: 'checkbox',
              label: 'Solitario',
              name: 'section_14_4_7',
              value: 'Solitario',
            },
            {
              id: 'section_14_4_8',
              type: 'checkbox',
              label: 'Aburrido',
              name: 'section_14_4_8',
              value: 'Aburrido',
            },
            {
              id: 'section_14_4_9',
              type: 'checkbox',
              label: 'Otro',
              name: 'section_14_4_9',
              value: 'Otro',
            },
            {
              id: 'section_14_4_10',
              type: 'checkbox',
              label: 'Normal',
              name: 'section_14_4_10',
              value: 'Normal',
            },
            {
              id: 'section_14_4_11',
              type: 'checkbox',
              label: 'Muy triste',
              name: 'section_14_4_11',
              value: 'Muy triste',
            },
          ],
        },
        {
          id: 'section_14_5',
          type: 'checkbox',
          header:
            '5. Marca todas las maneras en que el COVID-19 ha cambiado tu vida.',
          subheader: 'Puedes marcar más de una respuesta',
          valid: [
            'section_14_5_1',
            'section_14_5_2',
            'section_14_5_3',
            'section_14_5_4',
            'section_14_5_5',
            'section_14_5_6',
            'section_14_5_7',
            'section_14_5_8',
            'section_14_5_9',
            'section_14_5_10',
            'section_14_5_11',
            'section_14_5_12',
            'section_14_5_13',
            'section_14_5_14',
            'section_14_5_15',
          ],
          errors: [
            'section_14_5_1',
            'section_14_5_2',
            'section_14_5_3',
            'section_14_5_4',
            'section_14_5_5',
            'section_14_5_6',
            'section_14_5_7',
            'section_14_5_8',
            'section_14_5_9',
            'section_14_5_10',
            'section_14_5_11',
            'section_14_5_12',
            'section_14_5_13',
            'section_14_5_14',
            'section_14_5_15',
          ],
          columns: 1,
          items: [
            {
              id: 'section_14_5_1',
              type: 'checkbox',
              label: 'Dejé de ver a mis amigos',
              name: 'section_14_5_1',
              value: 'Dejé de ver a mis amigos',
            },
            {
              id: 'section_14_5_2',
              type: 'checkbox',
              label: 'Dejé de ver a mis familiares',
              name: 'section_14_5_2',
              value: 'Dejé de ver a mis familiares',
            },
            {
              id: 'section_14_5_3',
              type: 'checkbox',
              label: 'Es más fácil estudiar',
              name: 'section_14_5_3',
              value: 'Es más fácil estudiar',
            },
            {
              id: 'section_14_5_4',
              type: 'checkbox',
              label: 'Subí las notas',
              name: 'section_14_5_4',
              value: 'Subí las notas',
            },
            {
              id: 'section_14_5_5',
              type: 'checkbox',
              label: 'He bajado las notas',
              name: 'section_14_5_5',
              value: 'He bajado las notas',
            },
            {
              id: 'section_14_5_6',
              type: 'checkbox',
              label:
                'Tengo problemas para coger clases por falta de internet o luz',
              name: 'section_14_5_6',
              value:
                'Tengo problemas para coger clases por falta de internet o luz',
            },
            {
              id: 'section_14_5_7',
              type: 'checkbox',
              label:
                'He tenido otras dificultades para continuar mis estudios a distancia',
              name: 'section_14_5_7',
              value:
                'He tenido otras dificultades para continuar mis estudios a distancia',
            },
            {
              id: 'section_14_5_8',
              type: 'checkbox',
              label:
                'He perdido interés en las actividades que antes disfrutaba',
              name: 'section_14_5_8',
              value:
                'He perdido interés en las actividades que antes disfrutaba',
            },
            {
              id: 'section_14_5_9',
              type: 'checkbox',
              label: 'No pude participar de un evento importante de la escuela',
              name: 'section_14_5_9',
              value: 'No pude participar de un evento importante de la escuela',
            },
            {
              id: 'section_14_5_10',
              type: 'checkbox',
              label: 'No pude participar de un evento importante en mi familia',
              name: 'section_14_5_10',
              value: 'No pude participar de un evento importante en mi familia',
            },
            {
              id: 'section_14_5_11',
              type: 'checkbox',
              label: 'No puedo participar de actividades que me gustan',
              name: 'section_14_5_11',
              value: 'No puedo participar de actividades que me gustan',
            },
            {
              id: 'section_14_5_12',
              type: 'checkbox',
              label: 'He comenzado a hacer actividades nuevas',
              name: 'section_14_5_12',
              value: 'He comenzado a hacer actividades nuevas',
            },
            {
              id: 'section_14_5_13',
              type: 'checkbox',
              label: 'Tuve que dejar de hacer deportes',
              name: 'section_14_5_13',
              value: 'Tuve que dejar de hacer deportes',
            },
            {
              id: 'section_14_5_14',
              type: 'checkbox',
              label: 'No puedo dormir /duermo mal',
              name: 'section_14_5_14',
              value: 'No puedo dormir /duermo mal',
            },
            {
              id: 'section_14_5_15',
              type: 'checkbox',
              label: 'Mi vida no ha cambiado',
              name: 'section_14_5_15',
              value: 'Mi vida no ha cambiado',
            },
          ],
        },
        {
          id: 'section_15_1',
          type: 'radio',
          sectionTitle: 'FINAL',
          header:
            '1. ¿Te has sentido en libertad de contestar honestamente las preguntas relacionadas con el uso de drogas?',
          subheader: null,
          valid: 'section_15_1',
          errors: 'section_15_1',
          columns: 2,
          items: [
            {
              id: 'section_15_1',
              type: 'radio',
              label: 'No, en ninguna de ellas',
              name: 'section_15_1',
              value: 'No, en ninguna de ellas',
            },
            {
              id: 'section_15_1',
              type: 'radio',
              label: 'Sí, en la mayor parte de ellas',
              name: 'section_15_1',
              value: 'Sí, en la mayor parte de ellas',
            },
            {
              id: 'section_15_1',
              type: 'radio',
              label: 'Sí, en algunas de ellas',
              name: 'section_15_1',
              value: 'Sí, en algunas de ellas',
            },
            {
              id: 'section_15_1',
              type: 'radio',
              label: 'Sí, en todas ellas',
              name: 'section_15_1',
              value: 'Sí, en todas ellas',
            },
          ],
        },
        {
          id: 'section_15_2',
          type: 'radio',
          header: '2. Mientras contestabas este cuestionario…',
          subheader: null,
          valid: 'section_15_2',
          errors: 'section_15_2',
          columns: 1,
          items: [
            {
              id: 'section_15_2',
              type: 'radio',
              label: 'Había alguien mirando lo que contestaba',
              name: 'section_15_2',
              value: 'Había alguien mirando lo que contestaba',
            },
            {
              id: 'section_15_2',
              type: 'radio',
              label: 'Había alguien cerca de mi mientras contestaba',
              name: 'section_15_2',
              value: 'Había alguien cerca de mi mientras contestaba',
            },
            {
              id: 'section_15_2',
              type: 'radio',
              label: 'Nadie vio lo que contestaba',
              name: 'section_15_2',
              value: 'Nadie vio lo que contestaba',
            },
          ],
        },
        {
          id: 'section_15_3',
          type: 'checkbox',
          header: '3. Marca todo lo que pienses de este cuestionario:',
          subheader: 'Puedes marcar más de una respuesta',
          valid: [
            'section_15_3_1',
            'section_15_3_2',
            'section_15_3_3',
            'section_15_3_4',
            'section_15_3_5',
            'section_15_3_6',
            'section_15_3_7',
            'section_15_3_8',
            'section_15_3_9',
          ],
          errors: [
            'section_15_3_1',
            'section_15_3_2',
            'section_15_3_3',
            'section_15_3_4',
            'section_15_3_5',
            'section_15_3_6',
            'section_15_3_7',
            'section_15_3_8',
            'section_15_3_9',
          ],
          columns: 3,
          items: [
            {
              id: 'section_15_3_1',
              type: 'checkbox',
              label: 'Interesante',
              name: 'section_15_3_1',
              value: 'Interesante',
            },
            {
              id: 'section_15_3_2',
              type: 'checkbox',
              label: 'Aburrido',
              name: 'section_15_3_2',
              value: 'Aburrido',
            },
            {
              id: 'section_15_3_3',
              type: 'checkbox',
              label: 'Largo',
              name: 'section_15_3_3',
              value: 'Largo',
            },
            {
              id: 'section_15_3_4',
              type: 'checkbox',
              label: 'Corto',
              name: 'section_15_3_4',
              value: 'Corto',
            },
            {
              id: 'section_15_3_5',
              type: 'checkbox',
              label: 'Repetitivo',
              name: 'section_15_3_5',
              value: 'Repetitivo',
            },
            {
              id: 'section_15_3_6',
              type: 'checkbox',
              label: 'Complicado',
              name: 'section_15_3_6',
              value: 'Complicado',
            },
            {
              id: 'section_15_3_7',
              type: 'checkbox',
              label: 'Preguntas fáciles',
              name: 'section_15_3_7',
              value: 'Preguntas fáciles',
            },
            {
              id: 'section_15_3_8',
              type: 'checkbox',
              label: 'Preguntas difíciles',
              name: 'section_15_3_8',
              value: 'Preguntas difíciles',
            },
            {
              id: 'section_15_3_9',
              type: 'checkbox',
              label: 'Otro',
              name: 'section_15_3_9',
              value: 'Otro',
            },
          ],
        },
      ],
    },
  ],
});

export const formState = atom({
  key: 'form',
  default: {
    ip: '',
    slug: '',
    group: '',
    date: new Date(),
    section_1_1: '',
    section_1_2: '',
    section_1_3: '',
    section_1_4_1: false,
    section_1_4_2: false,
    section_1_4_3: false,
    section_1_4_4: false,
    section_1_4_5: false,
    section_1_4_6: false,
    section_1_4_7: false,
    section_1_4_8: false,
    section_1_4_9: false,
    section_1_5: '',
    section_2_1: '',
    section_2_2: '',
    section_2_3: '',
    section_2_4: '',
    section_2_5: '',
    section_2_6_1: '',
    section_2_6_2: '',
    section_2_6_3: '',
    section_2_7: '',
    section_2_8: '',
    section_2_9_1: false,
    section_2_9_2: false,
    section_2_9_3: false,
    section_2_9_4: false,
    section_2_9_5: false,
    section_2_9_6: false,
    section_2_9_7: false,
    section_2_9_8: false,
    section_2_9_9: false,
    section_2_10: '',
    section_2_11_1: false,
    section_2_11_2: false,
    section_2_11_3: false,
    section_2_11_4: false,
    section_2_12: '',
    section_3_1: '',
    section_3_2: '',
    section_3_3_1: '',
    section_3_3_2: '',
    section_3_3_3: '',
    section_3_3_4: '',
    section_3_4_1: '',
    section_3_4_2: '',
    section_3_4_3: '',
    section_3_4_4: '',
    section_3_4_5: '',
    section_3_5_1: '',
    section_3_5_2: '',
    section_3_5_3: '',
    section_3_5_4: '',
    section_3_5_5: '',
    section_3_6_1: '',
    section_3_6_2: '',
    section_3_6_3: '',
    section_3_7_1: '',
    section_3_7_2: '',
    section_3_7_3: '',
    section_3_7_4: '',
    section_3_8: '',
    section_4_1_1: '',
    section_4_1_2: '',
    section_4_1_3: '',
    section_4_1_4: '',
    section_4_1_5: '',
    section_4_1_6: '',
    section_4_1_7: '',
    section_4_1_8: '',
    section_4_1_9: '',
    section_4_1_10: '',
    section_4_1_11: '',
    section_4_1_12: '',
    section_4_1_13: '',
    section_4_2_1: '',
    section_4_2_2: '',
    section_4_2_3: '',
    section_4_2_4: '',
    section_4_2_5: '',
    section_4_2_6: '',
    section_4_2_7: '',
    section_4_2_8: '',
    section_4_2_9: '',
    section_4_2_10: '',
    section_4_2_11: '',
    section_4_2_12: '',
    section_4_2_13: '',
    section_4_3_1: '',
    section_4_3_2: '',
    section_4_3_3: '',
    section_4_3_4: '',
    section_4_3_5: '',
    section_4_3_6: '',
    section_4_3_7: '',
    section_4_3_8: '',
    section_4_3_9: '',
    section_4_3_10: '',
    section_4_3_11: '',
    section_4_3_12: '',
    section_4_3_13: '',
    section_4_4_1: '',
    section_4_4_2: '',
    section_4_4_3: '',
    section_4_4_4: '',
    section_4_4_5: '',
    section_4_4_6: '',
    section_4_4_7: '',
    section_4_4_8: '',
    section_4_4_9: '',
    section_4_4_10: '',
    section_4_4_11: '',
    section_4_5_1: false,
    section_4_5_2: false,
    section_4_5_3: false,
    section_4_5_4: false,
    section_4_5_5: false,
    section_4_5_6: false,
    section_4_6: '',
    section_4_7: '',
    section_4_8: '',
    section_4_9: '',
    section_4_10: '',
    section_4_11: '',
    section_4_12: '',
    section_4_13: '',
    section_4_14: '',
    section_4_15_1: false,
    section_4_15_2: false,
    section_4_15_3: false,
    section_4_15_4: false,
    section_4_15_5: false,
    section_4_15_6: false,
    section_4_15_7: false,
    section_4_15_8: '',
    section_4_16_1: false,
    section_4_16_2: false,
    section_4_16_3: false,
    section_4_16_4: false,
    section_4_16_5: false,
    section_4_16_6: false,
    section_4_16_7: false,
    section_4_16_8: false,
    section_4_16_9: false,
    section_4_16_10: false,
    section_4_16_11: false,
    section_4_16_12: false,
    section_4_16_13: false,
    section_4_16_14: false,
    section_4_16_15: false,
    section_4_16_16: '',
    section_4_17_1: false,
    section_4_17_2: false,
    section_4_17_3: false,
    section_4_17_4: false,
    section_4_17_5: false,
    section_4_17_6: false,
    section_4_17_7: false,
    section_4_18_1: false,
    section_4_18_2: false,
    section_4_18_3: false,
    section_4_18_4: false,
    section_4_18_5: false,
    section_4_18_6: false,
    section_4_18_7: false,
    section_4_18_8: false,
    section_4_18_9: false,
    section_4_18_10: false,
    section_4_18_11: '',
    section_4_19: '',
    section_5_1_1: '',
    section_5_1_2: '',
    section_5_1_3: '',
    section_5_1_4: '',
    section_5_1_5: '',
    section_5_1_6: '',
    section_5_1_7: '',
    section_5_2_1: '',
    section_5_2_2: '',
    section_5_2_3: '',
    section_5_2_4: '',
    section_5_2_5: '',
    section_5_2_6: '',
    section_5_2_7: '',
    section_5_2_8: '',
    section_5_2_9: '',
    section_5_2_10: '',
    section_5_2_11: '',
    section_5_2_12: '',
    section_5_2_13: '',
    section_5_3_1: '',
    section_5_3_2: '',
    section_5_3_3: '',
    section_5_3_4: '',
    section_5_3_5: '',
    section_5_4_1: '',
    section_5_4_2: '',
    section_5_4_3: '',
    section_5_4_4: '',
    section_5_4_5: '',
    section_5_5_1: '',
    section_5_5_2: '',
    section_5_5_3: '',
    section_5_5_4: '',
    section_5_5_5: '',
    section_5_5_6: '',
    section_5_6_1: '',
    section_5_6_2: '',
    section_5_6_3: '',
    section_5_6_4: '',
    section_5_6_5: '',
    section_5_6_6: '',
    section_5_6_7: '',
    section_5_6_8: '',
    section_5_6_9: '',
    section_5_6_10: '',
    section_6_1_1: '',
    section_6_1_2: '',
    section_6_1_3: '',
    section_6_1_4: '',
    section_7_1_1: '',
    section_7_1_2: '',
    section_7_1_3: '',
    section_7_1_4: '',
    section_7_2_1: '',
    section_7_2_2: '',
    section_7_2_3: '',
    section_7_2_4: '',
    section_7_2_5: '',
    section_7_2_6: '',
    section_7_2_7: '',
    section_7_3_1: '',
    section_7_3_2: '',
    section_7_3_3: '',
    section_7_3_4: '',
    section_8_1_1: '',
    section_8_1_2: '',
    section_8_1_3: '',
    section_8_1_4: '',
    section_8_1_5: '',
    section_8_1_6: '',
    section_8_1_7: '',
    section_8_1_8: '',
    section_8_1_9: '',
    section_8_2_1: '',
    section_8_2_2: '',
    section_8_2_3: '',
    section_8_2_4: '',
    section_8_2_5: '',
    section_8_2_6: '',
    section_8_2_7: '',
    section_8_2_8: '',
    section_8_2_9: '',
    section_9_1_1: '',
    section_9_1_2: '',
    section_9_1_3: '',
    section_10_1: '',
    section_10_2: '',
    section_10_3_1: false,
    section_10_3_2: false,
    section_10_3_3: false,
    section_10_3_4: false,
    section_10_3_5: false,
    section_10_3_6: false,
    section_10_3_7: false,
    section_10_3_8: false,
    section_10_3_9: false,
    section_10_3_10: false,
    section_10_3_11: false,
    section_10_3_12: false,
    section_10_4: '',
    section_10_5_1: false,
    section_10_5_2: false,
    section_10_5_3: false,
    section_10_5_4: false,
    section_10_5_5: false,
    section_10_6_1: false,
    section_10_6_2: false,
    section_10_6_3: false,
    section_10_6_4: false,
    section_10_6_5: false,
    section_10_6_6: false,
    section_10_7: '',
    section_10_8: '',
    section_11_1: '',
    section_11_2: '',
    section_11_3: '',
    section_11_4: '',
    section_11_5: '',
    section_11_6: '',
    section_11_7: '',
    section_11_8_1: '',
    section_11_8_2: '',
    section_11_8_3: '',
    section_11_8_4: '',
    section_11_8_5: '',
    section_11_8_6: '',
    section_11_8_7: '',
    section_11_9_1: '',
    section_11_9_2: '',
    section_11_9_3: '',
    section_11_9_4: '',
    section_11_9_5: '',
    section_11_9_6: '',
    section_11_9_7: '',
    section_12_1_1: false,
    section_12_1_2: false,
    section_12_1_3: false,
    section_12_1_4: false,
    section_12_1_5: false,
    section_12_1_6: false,
    section_12_1_7: false,
    section_12_1_8: false,
    section_12_1_9: false,
    section_12_1_10: false,
    section_12_1_11: false,
    section_12_1_12: false,
    section_12_1_13: false,
    section_12_1_14: false,
    section_12_1_15: false,
    section_12_1_16: false,
    section_12_1_17: false,
    section_12_1_18: false,
    section_13_1_1: '',
    section_13_1_2: '',
    section_13_1_3: '',
    section_13_1_4: '',
    section_13_1_5: '',
    section_13_1_6: '',
    section_13_1_7: '',
    section_13_2_1: '',
    section_13_2_2: '',
    section_13_2_3: '',
    section_13_2_4: '',
    section_13_2_5: '',
    section_13_2_6: '',
    section_13_2_7: '',
    section_13_3_1: '',
    section_13_3_2: '',
    section_13_3_3: '',
    section_13_3_4: '',
    section_13_3_5: '',
    section_13_3_6: '',
    section_13_3_7: '',
    section_13_4_1: '',
    section_13_4_2: '',
    section_13_4_3: '',
    section_13_4_4: '',
    section_13_4_5: '',
    section_13_4_6: '',
    section_13_4_7: '',
    section_14_1: '',
    section_14_2_1: false,
    section_14_2_2: false,
    section_14_2_3: false,
    section_14_2_4: false,
    section_14_2_5: false,
    section_14_3_1: false,
    section_14_3_2: false,
    section_14_3_3: false,
    section_14_4_1: false,
    section_14_4_2: false,
    section_14_4_3: false,
    section_14_4_4: false,
    section_14_4_5: false,
    section_14_4_6: false,
    section_14_4_7: false,
    section_14_4_8: false,
    section_14_4_9: false,
    section_14_4_10: false,
    section_14_4_11: false,
    section_14_5_1: false,
    section_14_5_2: false,
    section_14_5_3: false,
    section_14_5_4: false,
    section_14_5_5: false,
    section_14_5_6: false,
    section_14_5_7: false,
    section_14_5_8: false,
    section_14_5_9: false,
    section_14_5_10: false,
    section_14_5_11: false,
    section_14_5_12: false,
    section_14_5_13: false,
    section_14_5_14: false,
    section_14_5_15: false,
    section_15_1: '',
    section_15_2: '',
    section_15_3_1: false,
    section_15_3_2: false,
    section_15_3_3: false,
    section_15_3_4: false,
    section_15_3_5: false,
    section_15_3_6: false,
    section_15_3_7: false,
    section_15_3_8: false,
    section_15_3_9: false,
  },
});

export const confirmState = atom({
  key: 'confirm',
  default: false,
});

export const loadingState = atom({
  key: 'loading',
  default: false,
});

export const timeState = atom({
  key: 'time',
  default: 0,
});

export const errorsState = atom({
  key: 'errors',
  default: {},
});
