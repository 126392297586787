import React from 'react';
import _ from 'lodash';
import Select from 'react-select';
import { useRecoilState } from 'recoil';
import TableRadio from './TableRadio';
import TableHeader from './TableHeader';
import { formState } from '../../../atoms/Atoms';

function Table(props) {
  const { header, rows } = props;
  const [form, setForm] = useRecoilState(formState);

  return (
    <div className="w-full border rounded-lg">
      <table className="table-auto w-full">
        <TableHeader header={header} />
        <tbody className="divide-y">
          {_.map(rows, (r) => (
            <tr key={r.id}>
              {_.map(r.columns, (c) => {
                if (c.type === 'question') {
                  return (
                    <td key={c.id} className="font-medium text-gray-900 p-3">
                      {c.text}
                    </td>
                  );
                }

                if (c.type === 'dropdown') {
                  return (
                    <td key={c.id} className="w-1/2">
                      <Select
                        className="m-3"
                        name={c.name}
                        options={c.options}
                        placeholder="Seleccionar edad..."
                        isSearchable
                        onChange={(e) => {
                          setForm({
                            ...form,
                            [c.name]: e.value,
                          });
                        }}
                      />
                    </td>
                  );
                }

                return (
                  <td key={c.id} className="text-center">
                    <TableRadio
                      name={c.name}
                      value={c.value}
                      checked={form[c.name] === c.value}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          [c.name]: e.target.value,
                        });
                      }}
                    />
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Table;
